import Axios  from 'axios';
import {
    COMPANY_ID_REQUEST,
    COMPANY_ID_SUCCESS,
    COMPANY_ID_FAIL
} from '../constants/constants';

const { REACT_APP_BASE_URL} = process.env


export const getCompanyId = (subdomain) => async (dispatch) => {
    dispatch({ type: COMPANY_ID_REQUEST, payload: { subdomain } });
    try {
        const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/company/findYourCompany/${subdomain}`);
        dispatch({ type: COMPANY_ID_SUCCESS, payload: data });
        console.log("data...........",data)
        localStorage.setItem('rekrootyCompanyId', JSON.stringify(data.companyId));
    } catch (error) {
        dispatch({
            type: COMPANY_ID_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};