import React, { useState } from 'react';
import './LoginCss.css';
import axios from 'axios';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyId } from '../action.js/companyAction';
import { signin } from '../action.js/employeeAction';

// {setSubDomainExists}
const Login = () => {
  const [formData, setFormdata] = useState({
    email: '',
    password: '',
    companyId: '',
  });

  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length >= 2) {
      const subdomain = parts[0];
      const mainDomain = parts[1];
      if (mainDomain === process.env.REACT_APP_FRONTEND_URL) {
        return subdomain;
      }
    }
  
    return null;
  };


  const [subDomainExists, setSubDomainExists] = useState(false);
  // const navigate = useNavigate();
  
  const companyIdDispatch = useDispatch();

  const getCompanyIdSelector = useSelector((state) => state.getCompanyId);
  const { companyId, loading: companyIdLoading,  error:companyIdError } = getCompanyIdSelector;

  useEffect(() => {
    const fetchData = async () => {
      const subdomain = await getSubdomain();
      if (subdomain !== null) {
        setSubDomainExists(true)
       await companyIdDispatch(getCompanyId(subdomain))
        const storedCompanyData = await JSON.parse(localStorage.getItem('rekrootyCompanyId'));
        console.log("storedCompanyData---------------------------",storedCompanyData)
        setFormdata((prevData) => ({
          ...prevData,
          companyId: storedCompanyData || "",
        }));
      }

      else if(companyIdError){
            navigate('/support')
          } 
    }
    localStorage.removeItem('rekrootyLogInToken');
    localStorage.removeItem('rekrootyCompanyId');
    fetchData();
},[])

  const employeeSigninSelector = useSelector((state) => state.employeeSignin);
  const { employeeInfoData,error , loading} = employeeSigninSelector;

  const employeeLogInDispatch = useDispatch();

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const storedCompanyData = await JSON.parse(localStorage.getItem('rekrootyCompanyId'));
    // console.log("storedCompanyData",storedCompanyData)
    //   setFormdata((prevData) => ({
    //     ...prevData,
    //     companyId: storedCompanyData || "",
    //   }));
    employeeLogInDispatch(signin(formData))
    if(companyIdError){
      navigate("/support")
    }
    // if(employeeInfoData){
    //   setSubDomainExists(true)
    //   navigate("/DashBoard")
    // }
    
  };



  const sendEmail = async () => {
    try {
      console.log("formData.email",formData.email)
      const currentUrl = window.location.href; //
      // Make an API call to trigger the password reset process
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/password/resetRequest`, {
        email: formData.email, // Pass the user's email to initiate the reset process
        url: currentUrl,
        companyId:formData.companyId
      });

      if (response.status === 200) {
        alert('Check your email to reset your password.');
        // navigate('/resetPassword')
      } else {
        alert('Please enter your correct email .');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Please enter your correct email .');
    }
  };

   // Use useEffect to listen for changes in employeeInfoData
   useEffect(() => {
    // Check if employeeInfoData has been updated and contains data
    if (employeeInfoData) {
      // setSubDomainExists(true);
      navigate('/dashboard'); // Redirect to the dashboard
    }
    
  }, [employeeInfoData]);
  
  return (


    <div className="form-body">
     {companyIdLoading ?(
        <p style={{fontSize:"30px", color:"#fff",background:" #000"}}>Loading...</p>
      ): (
    <div className="form-container">
      {/* ... Your other JSX */}
     
      <form className="fr" onSubmit={handleSubmit}>
      <p className='error'> <b> { error ? error :null } </b> </p><br/>
        {/* ... Other form elements */}
        <div className="email-container">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            className="elem"
            value={formData.email}
            required
            onChange={handleInputChange}
          />
        </div>
        <div className="lname-container">
          <label htmlFor="lname">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            className="elem"
            value={formData.password}
            onChange={handleInputChange}
          />
        </div>
        <input type="submit" value="Log In" className="submit-btn elem" style={{maxWidth:"300px"}}/>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div onClick={sendEmail}>Forgot Password</div>
      </form>
      <img src="https://img.freepik.com/free-vector/fingerprint-concept-illustration_114360-3630.jpg?w=740&t=st=1690655121~exp=1690655721~hmac=a5de1b1e50d0513d9af30d378c665483c904dd89a6ca2eaae62986b10e3b5c85" />
    </div>
      ) }
    </div>
    
  );
};

export default Login;
