import Axios from 'axios';
import {

  EMPLOYEE_SIGNIN_FAIL,
  EMPLOYEE_SIGNIN_REQUEST,
  EMPLOYEE_SIGNIN_SUCCESS,
  EMPLOYEE_SIGNOUT,

  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,

  EMPLOYEE_DELETE_FAIL,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,

  EMPLOYEE_GET_ALL_REQUEST,
  EMPLOYEE_GET_ALL_SUCCESS,
  EMPLOYEE_GET_ALL_FAIL,

  EMPLOYEE_GET_BY_ID_REQUEST,
  EMPLOYEE_GET_BY_ID_SUCCESS,
  EMPLOYEE_GET_BY_ID_FAIL,

  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAIL
} from '../constants/constants';
import { useNavigate } from 'react-router-dom';
const { REACT_APP_BASE_URL } = process.env

export const signin = (formData) => async (dispatch) => {
  const { email, password, companyId } = formData;

  console.log("formData++++++++++++", formData)
  dispatch({ type: EMPLOYEE_SIGNIN_REQUEST, payload: { email, password, companyId } });
  try {
    const { data } = await Axios.post(`${REACT_APP_BASE_URL}/api/employee/login`, { email, password, companyId });
    dispatch({ type: EMPLOYEE_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('rekrootyLogInToken', JSON.stringify(data));
    
  } catch (error) {
    dispatch({
      type: EMPLOYEE_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    
  }
};


export const createEmployee = (formData) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();
  dispatch({ type: EMPLOYEE_CREATE_REQUEST });
  try {
    const { data } = await Axios.post(`${REACT_APP_BASE_URL}/api/employee/create`, formData,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type: EMPLOYEE_CREATE_SUCCESS, payload: data });
    alert("Employee Created Successfully")
    window.location = '/employeePage'
    // useNavigate('/employeePage')
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    }
    
    );

    // alert("Employee Not Created ")
  }
};


export const getAllEmployee = () => async (dispatch, getState) => {
  console.log("in get all emmployee")
  const {
    employeeSignin: { employeeInfoData },
  } = getState();

  dispatch({ type: EMPLOYEE_GET_ALL_REQUEST });
  try {

    const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/employee/getAll`,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type: EMPLOYEE_GET_ALL_SUCCESS, payload: data });

  } catch (error) {
    dispatch({
      type: EMPLOYEE_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEmployeeById = (id) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();

  dispatch({ type: EMPLOYEE_GET_BY_ID_REQUEST });
  try {

    const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/employee/get/${id}`,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type: EMPLOYEE_GET_BY_ID_SUCCESS, payload: data });

  } catch (error) {
    dispatch({
      type: EMPLOYEE_GET_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const deleteEmployeeById = (id) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();

  dispatch({ type: EMPLOYEE_DELETE_REQUEST });
  try {

    const { data } = await Axios.delete(`${REACT_APP_BASE_URL}/api/employee/delete/${id}`,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type: EMPLOYEE_DELETE_SUCCESS, payload: data });
    dispatch(getAllEmployee())
     alert("Employee Deleted Succesfully")
     
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getCurrentEmployee = () => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();

  dispatch({ type: CURRENT_USER_REQUEST });
  try {

    const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/employee/currentUser`,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type: CURRENT_USER_SUCCESS, payload: data });

  } catch (error) {
    dispatch({
      type: CURRENT_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


