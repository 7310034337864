import React, { useEffect } from 'react';
import { useState } from 'react';
import './submission.scss';
import data from '../../data/data';
import { Country, State, City } from 'country-state-city';

import { useDispatch, useSelector } from 'react-redux';
import {
  createSubmission,
  getAllSubmissions,
} from '../../action.js/submissionAction';
import { getAllEmployee } from '../../action.js/employeeAction';
import { getAllCandidates } from '../../action.js/candidateAction';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
const CreateSubmission = () => {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState([]);
  const [submissionData, setSubmissionData] = useState([]);
  const submissionCreateSelector = useSelector(
    (state) => state.createSubmission
  );
  const [other, setOther] = useState(false);
  const { employeeInfo, loading, error } = submissionCreateSelector;
  const submissionEmployeeDispatch = useDispatch();
  useEffect(() => {
    if (candidateName.length === 0) {
      console.log('candidateData11111', data.submissionData);
      setCandidateName(data.submissionData);
      console.log('candidateData65666', candidateName);
    }
  }, []);

  const getAllCandidateDispatch = useDispatch();
  const getAllCandidateSelector = useSelector((state) => state.candidateList);
  const {
    candidateList,
    loading: employeeLoading,
    error: employeeError,
  } = getAllCandidateSelector;

  useEffect(() => {
    console.log('hit firsttime');
    getAllCandidateDispatch(getAllCandidates());
    console.log('employeesInfo................', candidateList);
  }, []);

  useEffect(() => {
    console.log('submissionData', submissionData);
  }, [submissionData]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubmissionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const renderCountryOptions = () => {
  //   const countryList = Country.getAllCountries();
  //   return countryList.map((country) => (
  //     <option key={country.isoCode} value={`${country.name},${country.isoCode}`}>
  //       {country.name}
  //     </option>
  //   ));
  // };

  // const renderStateOptions = () => {
  //   const countryCode = formData.selectedCountry?.split(',')[1];

  //   const states = countryCode ? State.getStatesOfCountry(countryCode) : [];

  //   return states.map((state) => (
  //     <option key={state.isoCode} value={`${state.name},${state.isoCode}`}>
  //       {state.name}
  //     </option>
  //   ));
  // };

  // const renderCityOptions = () => {
  //   const countryCode = formData.selectedCountry?.split(',')[1];
  //   const stateCode = formData.selectedStates?.split(',')[1];
  //   const cities = City.getCitiesOfState(countryCode, stateCode);
  //   return cities.map((city,index) => (
  //     <option key={index} value={city.name}>
  //       {city.name}
  //     </option>
  //   ));
  // };

  // Render country options
  const renderCountryOptions = () => {
    const countries = Country.getAllCountries();
    return countries.map((country) => (
      <option key={country.isoCode} value={country.isoCode}>
        {country.name}
      </option>
    ));
  };

  // Render state options based on selected country
  const renderStateOptions = () => {
    const countryCode = submissionData.citizenship?.split(',')[1];
    
    const states = countryCode ? State.getStatesOfCountry(countryCode) : [];
    
    return states.map((state) => (
      <option key={state.isoCode} value={`${state.name},${state.isoCode}`}>
        {state.name}
      </option>
    ));
  };

  // Render city options based on selected country and state
  const renderCityOptions = () => {
    const countryCode = submissionData.citizenship?.split(',')[1];
      const stateCode = submissionData.state?.split(',')[1];
      const cities = City.getCitiesOfState(countryCode, stateCode);
      return cities.map((city,index) => (
        <option key={index} value={city.name}>
          {city.name}
        </option>
      ));
  };

  const handleCitizenship = (value) => {
    const selectedCountry = Country.getCountryByCode(value);
    setSubmissionData((prevData) => ({
      ...prevData,
      citizenship: `${selectedCountry.name},${selectedCountry.isoCode}`,
    }));
  };

  const handleStateChange = (value) => {
    // const selectedState = State.getStateByCode(value);
    // console.log("selectedState-----------------------------+++++++++++++++++++", selectedState,value)
    setSubmissionData((prevData) => ({
      ...prevData,
      state: `${value}`,
    }));
  };

  const handleCityChange = (value) => {
    setSubmissionData((prevData) => ({
      ...prevData,
      city: value,
    }));
  };
  const getAllSubmissionDispatch = useDispatch();
  const handleSubmit = () => {
    console.log('submitted data', submissionData);
    submissionEmployeeDispatch(createSubmission(submissionData));
    getAllCandidateDispatch(getAllCandidates());
    getAllSubmissionDispatch(getAllSubmissions());
    // alert("Form Submitted Successfully")
  };
  //  const handleSubmit =()=>{
  // console.log("submitted data",submissionData)
  //   }
  return (
    <>
      {/* <div className='container' >
     <Sidebar />
     <div className='HeaderMainPage'>
       <Header />
       <div className='main'> */}
      {/* 
<div className='breadcrumb'>
                <div>
                    <h1>SUBMISSION</h1>
                    <p>candidates{candidates ? "" : " / Candidates' Distribution"}</p>
                    <p>Submission / Create Submission</p>
                </div>
                </div> */}
      {candidateName.length != 0 ? (
        <>
          {/* <div className='candidates' style={{ backgroundColor: "#ffffff" }}> */}
          <div className="flex-row-jAround form" style={{ gap: '30px' }}>
            <div className="flex-column " style={{ flex: 1 }}>
              <label>
                CandidateName
                <select
                  name="candidateId"
                  value={submissionData?.id}
                  required
                  onChange={handleInputChange}
                >
                  <option value="">Select Candidate</option>
                  {candidateList?.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.fullName || `${item.firstName} ${item.lastName}`}
                    </option>
                  ))}
                </select>
              </label>

              {/* <label>
            Submitted By
            <input type="text" value={candidateData?.submittedBy} required name="submittedBy" onChange={handleInputChange}/>
        </label> */}

              <label>
                Job Source Name
                <input
                  type="text"
                  name="jobSourceName"
                  value={submissionData?.jobSourceName}
                  required
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Position
                <input
                  type="text"
                  name="position"
                  value={submissionData?.position}
                  required
                  onChange={handleInputChange}
                />
              </label>

              <label>
                End Client
                <input
                  type="text"
                  name="endClient"
                  value={submissionData?.endClient}
                  required
                  onChange={handleInputChange}
                />
              </label>
            </div>

            <div className="flex-column" style={{ flex: 1 }}>
              <label className="flex-column">
                Submission Date
                <input
                  type="date"
                  name="dateOfSubbmision"
                  value={submissionData?.dateOfSubbmision}
                  required
                  onChange={handleInputChange}
                />
              </label>

              <label>
                Job Source Type
                <input
                  type="text"
                  name="jobSourceType"
                  value={submissionData?.jobSourceType}
                  required
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Vendor Name
                <select
                  name="vendor"
                  value={other ? 'Other' : submissionData?.vendor}
                  required
                  //  onChange={handleInputChange}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue !== 'Other') {
                      setOther(false);
                      handleInputChange(e); // Call handleInputChange for non-'Other' selections
                    } else {
                      setOther(true);
                      // Update state directly for 'Other' selection
                      handleInputChange(e);
                    }
                  }}
                >
                  <option value="">Select Vendor</option>
                  <option value="Direct Client">Direct Client</option>
                  <option value="Online Job Portal">Online Job Portal</option>
                  <option value="Vendor">Vendor</option>
                  <option value="Sub Vendor">Sub Vendor</option>
                  <option value="Prime Vendor">Prime Vendor</option>
                  <option value="Other">Other</option>
                </select>
                {other ? (
                  <input
                    type="text"
                    name="vendor"
                    value={submissionData?.vendor}
                    required
                    onChange={handleInputChange}
                  />
                ) : (
                  ''
                )}
              </label>
            </div>

            <div className="flex-column" style={{ flex: 2 }}>
              <label>
                Job Description
                <textarea
                   style={{minHeight:"100px"}}
                  type="textarea"
                  name="jobDescription"
                  value={submissionData?.jobDescription}
                  required
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Comments
                <textarea
                  type="textarea"
                  name="comments"
                  style={{minHeight:"100px", padding:"10px"}}
                  value={submissionData?.comments}
                  // style={{ width: "100%" }}
                  onChange={handleInputChange}
                />
              </label>

              <div style={{ display: 'flex', justifyContent: 'space-between', gap:"40px" }}>
                <label>
                  Submission Status
                  <select
                    name="status"
                    value={submissionData?.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Submission Status</option>
                    <option value="Submitted to Prime Vendor">
                      Submitted to Prime Vendor
                    </option>
                    <option value="Submitted to end Client">
                      Submitted to end Client
                    </option>
                    <option value="Interview Requested">
                      Interview Requested
                    </option>
                    <option value="Interview Scheduled">
                      Interview Scheduled
                    </option>
                    <option value="Interview Completed">
                      Interview Completed
                    </option>
                    <option value="Rejected">Rejected</option>
                    <option value="No Updates">No Updates</option>
                    <option value="Offer Expanded">Offer Expanded</option>
                  </select>
                </label>

                {/* <label>
                  City
                  <input
                    type="text"
                    name="city"
                    value={submissionData?.city}
                    onChange={handleInputChange}
                  />
                </label> */}

                <label>
                  Country:
                  <select
                    name="citizenship"
                    value={submissionData?.country}
                    required
                    // onChange={handleChange}
                    defaultValue={''}
                    onChange={(e) => handleCitizenship(e.target.value)}
                  >
                    <option value={''}>Select Country</option>

                    {renderCountryOptions()}
                  </select>
                </label>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' , gap:"40px"}}>
                {/* <label>
                State
                <input
                  type="text"
                  name="state"
                  value={submissionData?.state}
                  onChange={handleInputChange}
                />
              </label> */}
                <label>
                  {' '}
                  State:
                  <select
                    value={submissionData?.state}
                    defaultValue={''}
                    onChange={(e) => handleStateChange(e.target.value)}
                  >
                    <option value={''}>Select State</option>
                    {renderStateOptions()}
                  </select>
                </label>
                {/* <label>
                Country
                <input
                  type="text"
                  name="country"
                  value={submissionData?.country}
                  onChange={handleInputChange}
                />
              </label> */}
                <label>
                  {' '}
                  City:
                  <select
                    value={submissionData?.city}
                    defaultValue={''}
                    onChange={(e) => handleCityChange(e.target.value)}
                  >
                    <option value={''}>Select City</option>
                    {renderCityOptions()}
                  </select>
                </label>
              </div>
              <div
                className="save-button"
                style={{
                  float: ' right',
                  marginTop: 'auto',
                  width: 'fit-content',
                  marginLeft: 'auto',
                }}
                onClick={handleSubmit}
              >
                Submit
              </div>
            </div>
          </div>

          {/* </div> */}
        </>
      ) : (
        ''
      )}
      {/* </div>
    </div>
     </div> */}
    </>
  );
};

export default CreateSubmission;
