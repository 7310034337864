import React, { useEffect, useState } from 'react'
import CandidatesHeader from './CandidatesHeader';
import Candidates from './Candidates';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidates } from '../../action.js/candidateAction';




const DistributeCandidates = () => {
    const [searchValues, setSearchValues] = useState({});
  const [candidateData, setCandidateData] = useState()
  const candidateListState = useSelector((state) => state.candidateList);
  const { candidateList, loading, error } = candidateListState
  // const candidateListDispatch = useDispatch()


  // useEffect(() => {
  //   candidateListDispatch(getAllCandidates())
  // }, [])

  useEffect(() => {
    console.log("candidateList---------------",candidateList)
    // setCandidateData(candidateList)
  }, [])

  // const groupedCandidates = candidateList?.reduce((groups, candidate) => {
  //   const { assignTo, ...rest } = candidate;

  //   if (assignTo && assignTo.fullName) {
  //     const groupName = assignTo[0].fullName;
  //     if (!groups[groupName]) {
  //       groups[groupName] = [];
  //     }
  //     groups[groupName].push(rest);
  //   } else {
  //     const groupName = 'Unassigned';
  //     if (!groups[groupName]) {
  //       groups[groupName] = [];
  //     }
  //     groups[groupName].push(rest);
  //   }

  //   return groups;
  // }, {});

  const groupedCandidates = candidateList?.reduce((groups, candidate) => {
    const { assignTo, ...rest } = candidate;
  
    // Extracting the first object from the assignTo array and getting the fullName
    const fullName = assignTo?.length > 0 ? assignTo[0]?.fullName || `${assignTo[0]?.firstName} ${assignTo[0]?.lastName}` : 'Unassigned';
  
    if (!groups[fullName]) {
      groups[fullName] = [];
    }
    groups[fullName].push(rest);
  
    return groups;
  }, {})?? {};
  
  console.log(groupedCandidates);
 // Function to handle search for a specific column
 const handleSearch = (column, searchValue) => {
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [column]: searchValue,
    }));
  };

  // Filter candidates based on search values
  const filteredGroupedCandidates = Object.entries(groupedCandidates)?.reduce(
    (filteredGroups, [assignTo, candidates]) => {
      const searchValue = searchValues[assignTo] || '';
      const filteredCandidates = candidates.filter((candidate) =>
        candidate?.fullName || `${candidate.firstName} ${candidate?.lastName}` ?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );

      if (filteredCandidates.length > 0) {
        filteredGroups[assignTo] = filteredCandidates;
      }

      return filteredGroups;
    },
    {}
  ) ;

  // Render the grouped candidates
  return (
    <div className="candidatesDiv">
    { Object.entries(filteredGroupedCandidates)?.map(([assignTo, candidates]) => (
      <div key={assignTo} className="column">
        <CandidatesHeader
          count={candidates.length}
          name={assignTo}
          color={"#FD841F"}
        />
        {/* <input
        style={{width: "-webkit-fill-available"}}
          type="text"
          placeholder="Search..."
          value={searchValues[assignTo] || ''}
          onChange={(e) => handleSearch(assignTo, e.target.value)}
        /> */}
        <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#EAEAEA',
                      padding: '10px',
                      marginBottom: '20px',
                      borderRadius: '40px',
                      alignItems:"centre"
                    }}
                  >
                    <i class="fa fa-search"></i>
                    &nbsp;
                    <input
                      style={{
                        width: '-webkit-fill-available',
                        border: 'none',
                        backgroundColor: '#EAEAEA',
                        outline: "none"
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchValues[assignTo] || ''}
          onChange={(e) => handleSearch(assignTo, e.target.value)}
                    />
                  </div>
        {candidates?.map((candidate) => (
          <Candidates
            key={candidate?.fullName || `${candidate.firstName} ${candidate?.lastName}`}
            name={candidate?.fullName || `${candidate.firstName} ${candidate?.lastName}`}
            img={candidate?.img || "/image/blank-profile.png"}
            onClick={() => {}}
          />
        ))}
      </div>
    ))}
  </div>
  )
}



export default DistributeCandidates