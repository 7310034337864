import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateToken } from './action.js/validateTokenAction';
import { getCompanyId } from './action.js/companyAction';

const  PublicRoute = (props) => {

  const { Component } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();



  // const getSubdomain = () => {
  //   const hostname = window.location.hostname;
  //   const parts = hostname.split('.');
  //  console.log("hostNam e",hostname, parts.length)
  //   if (parts.length >= 2) {
  //     const subdomain = parts[0];
  //     const mainDomain = parts[1];
  //     console.log("subdomain, mainDomain, process.env.REACT_APP_FRONTEND_URL",subdomain, mainDomain,process.env.REACT_APP_FRONTEND_URL)
  //     if (mainDomain === process.env.REACT_APP_FRONTEND_URL) {
  //       return subdomain;
  //     }
  //   }
  
  //   return null;
  // };


  const [subDomainExists, setSubDomainExists] = useState(false);
  // const navigate = useNavigate();
  
  const companyIdDispatch = useDispatch();

  const getCompanyIdSelector = useSelector((state) => state.getCompanyId);
  const { getcompanyId, loading: companyIdLoading,  error:companyIdError } = getCompanyIdSelector;

//   useEffect(() => {
//     const subdomain =  getSubdomain();
//     const fetchData = async () => {
   
//       console.log("subdomain", subdomain)
//       if (subdomain !== null) {
//         companyIdDispatch(getCompanyId(subdomain))
//         setSubDomainExists(true)
        
       
//       }
//       else{
//         navigate('/support')
//       }
     
//     }

//     fetchData();
// },[])

  useEffect(()=>{
    if(companyIdError){
      navigate('/support')
    }
  },[])

//   const validateTokenSelector = useSelector((state) => state.validateToken);
//   const { validate, error, loading } = validateTokenSelector;

//   const [hasRun, setHasRun] = useState(false);

//   useEffect(() => {
//     const items = JSON.parse(localStorage.getItem('rekrootyLogInToken'));

//     if (!hasRun) {
//       setHasRun(true);

//       if (!items) {
//         navigate('/');
//       } else {
//         dispatch(validateToken());
//       }
//     }
//   }, [hasRun]);

//   useEffect(() => {
//     if (error) {
//       navigate('/');
//     }
//   }, [error]);





  return (
    // <div>{subDomainExists ? <Component /> : null}</div>
    <div>{<Component /> }</div>

  );
};

export default PublicRoute;
