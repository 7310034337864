import React from 'react'
import SubmissionDetails from './SubmissionDetails'
import './candidatePopUP.scss'
import AllSubmissions from './AllSubmissions'
import ButtonWithoutBG from '../../../components/Buttons/ButtonWithoutBG'
import { useState } from 'react'
import { useEffect } from 'react'
import data from '../../../data/data'
const SubmissionPopUp = ({ submissionIndex, id, onClose }) => {
  const [show, setShow] = useState(true)
  const [candidate, setCandidate] = useState()
  useEffect(() => {
    console.log("data.submissionData", data.submissionData)
    const findData = data.submissionData?.find((item) => item.id == id)
    console.log("findData", findData)
    setCandidate(findData)
    // console.log("submissions",submissions)
  })
  return (
    <>
      <div className='candidate-popup'>
        <div className='candidate-popup-content'>
          <div className='mainPopUPHead'>
            <img src={candidate?.photo} alt="" />
            <div style={{ padding: "20px" }}>
              <p>{candidate?.name}</p>
              {/* <p>{candidateProfile?.jobStatus}</p> */}

            </div>
            <button onClick={onClose} style={{ marginLeft: "auto" }}><i class="fa fa-close"></i>
            </button>
          </div>
          {/* <ButtonWithoutBG
            buttonName={"Submission Details"}
            boxBottomColor={"#FBBC05"}
            active={show ? "btnWoutBgActive" : ""}
            onClick={() => setShow(true)}
          /> */}
          {/* <ButtonWithoutBG
            buttonName={"All Submission"}
            boxBottomColor={"#FBBC05"}
            active={show ? "" : "btnWoutBgActive"}
            onClick={() => setShow(false)}
          /> */}
          <div className="flex-column" style={{ padding: "10px", gap: "30px" }}>


            {show ? <SubmissionDetails submissionIndex={submissionIndex} id={id} onClose={onClose} />
              : <AllSubmissions id={id} />}
          </div>

        </div>
      </div>



    </>
  )
}

export default SubmissionPopUp