import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './bulkMail.css'
import { useDispatch, useSelector } from 'react-redux';
import { sendBulkEmails } from '../../action.js/bulkEmailAction';
import { useLocation } from 'react-router-dom';
import { updateInterview } from '../../action.js/interviewAction';
import { getAllSubmissions } from '../../action.js/submissionAction';
function BulkEmailSender() {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [recipientEmails, setRecipientEmails] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
// Existing state variables remain the same
const [ccEmail, setCCEmail] = useState('');
const [bccEmail, setBCCEmail] = useState('');
const location = useLocation();
// const { mailData } = location?.state || {};
const { formData ,subIndex, subId } = location?.state || {};
const { loading, error, SuccessMessage } = useSelector((state) => state.bulkEmail);

const handleCCChange = (e) => {
  setCCEmail(e.target.value);
};

console.log("mailProsp",formData)
const handleBCCChange = (e) => {
  setBCCEmail(e.target.value);
};
  const templates = {
    jobApplication: `
      <h2>Dear Hiring Manager,</h2>
      <p>I am writing to apply for the [Position] position at [Company].</p>
      <p>[Customize your message here]</p>
      <br/>
      <p>Sincerely,</p>
      <p>[Your Name]</p>
    `,

    requirement: `
    <h2>Dear Recruiter Manager,</h2>
    <p> I want to apply for requiter position .</p>
    <p>[Customize your message here]</p>
    <br/>
    <p>Sincerely,</p>
    <p>[Your Name]</p>
  `,

  Interview_Schedule:`
  ${formData? `<h2>Dear ${formData?.candidateId?.firstName} ${formData?.candidateId?.lastName},</h2>
  <p> Your interview has been scheduled for ${formData?.submission[subIndex]?.interview?.timeStart} to  ${formData?.submission[subIndex]?.interview?.timeEnd}.</p><br/>
  <p>Best regards,</p>
  <p>[Your Company Name]</p>` :
  `<h2>Dear [NAME],</h2>
  <p> Your interview has been scheduled for [TIME START] to [TIME END].</p><br/>
  <p>Best regards,</p>
  <p>[Your Company Name]</p>`} 
 `
    // Add more templates as needed
  };

  
  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
    setCustomMessage(templates[e.target.value]);
  };

  const handleEditorChange = (content) => {
    setCustomMessage(content);
  };


  const dispatch = useDispatch();
  const  getAllSubmissionDispatch = useDispatch()
  const updateInterviewDispatch = useDispatch()
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Dispatch the action to send bulk emails
    await  dispatch(sendBulkEmails(recipientEmails, customMessage, ccEmail, bccEmail)).then(()=>{
      if(formData){
        let formDataUpdate =  formData;
        formDataUpdate.submission[subIndex].interview.sendMail = true; 
        updateInterviewDispatch(updateInterview(formDataUpdate, subId))
        // getAllSubmissionDispatch(getAllSubmissions())
    
      }
    })
      // Optionally, you can set a response message in the Redux store
      // if needed, instead of using local state (setResponseMessage)
      // Dispatch another action or update the state in your reducer
    } catch (error) {
      console.error('Sending bulk emails failed:', error);
      // Handle errors if needed
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     // Make an API request to send bulk emails with selected content and recipientEmails
  //     const response = await fetch('http://localhost:2023/api/send-bulk-email/send-bulk-emails', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         emails: recipientEmails.split(',').map(email => email.trim()), // Convert recipientEmails to an array
  //         message: customMessage,
  //         ccEmail: ccEmail.split(',').map(email => email.trim()), // Include CC email
  //         bccEmail: bccEmail.split(',').map(email => email.trim()), // Include BCC email
  //       }),
  //     });

  //     if (response.ok) {
  //       setResponseMessage('Emails sent successfully!');
  //     } else {
  //       setResponseMessage('Error sending emails.');
  //     }
  //   } catch (error) {
  //     console.error('API request failed:', error);
  //     setResponseMessage('API request failed.');
  //   }
  // };

  return (
    <div>
      {/* <form onSubmit={handleSubmit}>
        <div>
          <label>Select Email Template:</label>
          <select value={selectedTemplate} onChange={handleTemplateChange}>
            <option value="">Select Template</option>
            <option value="jobApplication">Job Application</option>
          </select>
        </div>
        <div>
          <label>Recipient Email Addresses (comma-separated):</label>
          <input
            type="text"
            value={recipientEmails}
            onChange={(e) => setRecipientEmails(e.target.value)}
          />
        </div>
        <div>
          <label>Custom Message:</label>
          <ReactQuill value={customMessage} onChange={handleEditorChange} />
        </div>
        <button type="submit">Send Emails</button>
      </form>
      {responseMessage && <div>{responseMessage}</div>} */}

<div className="container-bulkEmail">
  <h1>Bulk Mail</h1>
  <form onSubmit={handleSubmit}>
    <div className='flex-row'>
    <div>
    <div>
      <label>Select Email Template:</label>
      <select className="email-template-select" value={selectedTemplate} onChange={handleTemplateChange}>
      <option value="">Select Template</option>
            <option value="jobApplication">Job Application</option>
            <option value="requirement">Requirement</option>
            <option value="Interview_Schedule">Interview Schedule</option>
      </select>
    </div>
    <div>
      <label>Recipient Email Addresses (comma-separated):</label>
      <input
        type="text"
        className="recipient-email-input"
        value={ formData?.candidateId?.email ||  recipientEmails}
        onChange={(e) => setRecipientEmails(e.target.value)}
      />
    </div>
     {/* CC Email */}
     <div>
            <label>CC Email Addresses (comma-separated):</label>
            <input
              type="text"
              className="cc-email-input"
              value={ccEmail}
              onChange={handleCCChange}
            />
          </div>
          {/* BCC Email */}
          <div>
            <label>BCC Email Addresses (comma-separated):</label>
            <input
              type="text"
              className="bcc-email-input"
              value={bccEmail}
              onChange={handleBCCChange}
            />
          </div>
    </div>
    <div style={{flex:"2"}}>
      <label>Custom Message:</label>
      <ReactQuill className="custom-message-editor" value={customMessage} onChange={handleEditorChange} />
    </div>
    </div>
    <button type="submit" className="save-button">Send Emails</button>
  </form>
  {loading && <div className="response-message">Sending.....</div>}
  {/* {SuccessMessage && <div className="response-message">{SuccessMessage}</div>} */}
  {error && <div className="response-message">{error}</div>}
</div>

    </div>
  );
}

export default BulkEmailSender;
