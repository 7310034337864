
import {

    VALIDATE_TOKEN_REQUEST,
    VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAIL,

 

} from '../constants/constants';

export const validateTokenReducer = (state = {}, action) => {
    switch (action.type) {
      case VALIDATE_TOKEN_REQUEST:
        return { loading: true };
      case VALIDATE_TOKEN_SUCCESS:
        return { loading: false, validate: action.payload };
      case VALIDATE_TOKEN_FAIL:
        return { loading: false, error: action.payload };
    //   case EMPLOYEE_SIGNOUT:
    //     return {};
      default:
        return state;
    }
  };