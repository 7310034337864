import {
  SUBIMISSION_GET_ALL_FAIL,
  SUBIMISSION_GET_ALL_REQUEST,
  SUBIMISSION_GET_ALL_SUCCESS,
  SUBIMISSION_GET_ID_FAIL,
  SUBIMISSION_GET_ID_REQUEST,
  SUBIMISSION_GET_ID_SUCCESS,
  SUBMISSION_CREATE_FAIL,
  SUBMISSION_CREATE_REQUEST,
  SUBMISSION_CREATE_SUCCESS,
  SUBMISSION_UPDATE_REQUEST,
  SUBMISSION_UPDATE_SUCCESS,
  SUBMISSION_UPDATE_FAIL,
  SUBMISSION_DELETE_REQUEST,
  SUBMISSION_DELETE_SUCCESS,
  SUBMISSION_DELETE_FAIL,
} from '../constants/constants';

export const createSubmissionReducers = (state = {}, action) => {
  switch (action.type) {
    case SUBMISSION_CREATE_REQUEST:
      return { loading: true };
    case SUBMISSION_CREATE_SUCCESS:
      return { loading: false, employeeInfo: action.payload };
    case SUBMISSION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateSubmissionReducers = (state = {}, action) => {
  switch (action.type) {
    case SUBMISSION_UPDATE_REQUEST:
      return { loading: true };
    case SUBMISSION_UPDATE_SUCCESS:
      return { loading: false, employeeInfo: action.payload };
    case SUBMISSION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllSubmissionReducers = (state = {}, action) => {
  switch (action.type) {
    case SUBIMISSION_GET_ALL_REQUEST:
      return { loading: true };
    case SUBIMISSION_GET_ALL_SUCCESS:
      return { loading: false, submissionList: action.payload };
    case SUBIMISSION_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSubmissionByIdReducers = (state = {}, action) => {
  switch (action.type) {
    case SUBIMISSION_GET_ID_REQUEST:
      return { loading: true };
    case SUBIMISSION_GET_ID_SUCCESS:
      return { loading: false, submissionById: action.payload };
    case SUBIMISSION_GET_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const deleteSubmissionReducers = (state = {}, action) => {
  switch (action.type) {
    case SUBMISSION_DELETE_REQUEST:
      return { loading: true };
    case SUBMISSION_DELETE_SUCCESS:
      return { loading: false, deletedSubmissionData: action.payload };
    case SUBMISSION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};