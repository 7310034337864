import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployee } from '../../../../action.js/employeeAction';
// import data from '../../data/data';

const BasicDetails = (props) => {
    const { data, onClick } = props
    const [formData, setFormData] = useState(data);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        setFormData(data)
    }, [])
    console.log("dataBasic111", data)

    const handleInputChange = (e) => {
        const { name, value , checked} = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        if (name === 'remoteJob') {
            const updatedData = {
              ...formData,
              remoteJob: checked,
        
            };
            setFormData(updatedData);
            
          } 
    };

    const handleInputCurrentAddressChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            currentAddress: {
                ...prevFormData.currentAddress,
                [name]: value,
            },
        }));
    };


    const handleInputPermanentAddressChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            permanentAddress: {
                ...prevFormData.currentAddress,
                [name]: value,
            },
        }));
    };

    const handleEdit = () => {
        setIsEditMode(true);
    };


    const handleSave = () => {
        setIsEditMode(false);

        // Save the form data or perform any other necessary actions
        onClick(formData)
        getAllEmployeeDispatch(getAllEmployee())
        console.log("ecedvererer", formData);
    };



    const getAllEmployeeDispatch = useDispatch()
    const getAllEmployeeSelector = useSelector((state) => state.getAllEmployee)
    const { employeesInfo, loading, error } = getAllEmployeeSelector
    useEffect(() => {
        console.log("hit firsttime")
        getAllEmployeeDispatch(getAllEmployee())
        console.log("employeesInfo................", employeesInfo)
    }, [])

    return (
        <div style={{ border: "1px solid black" }}>
            <div className='flex-row-jBetween-aCenter ' style={{ borderBottom: "1px solid black", padding: "20px" }}>
                <h2>Basic Details</h2>
                {isEditMode ? (
                    <button type="button" onClick={handleSave} className='edit-save-button'>
                        Save
                    </button>
                ) : (
                    <button type="button" onClick={handleEdit} className='edit-save-button'>
                        <span class="material-symbols-outlined">
                            edit
                        </span> Edit Information
                    </button>
                )}
            </div>
            <div className='form'>
                {/* <label>
          Photo:
          <input
            type="text"
            name="photo"
            value={formData.photo}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className={isEditMode ? "":"input-wout-border"}
          />
        </label>
         */}
                <div className='flex-row-between' style={{ gap: "40px" }}>
                    <div>
                        <label>
                           First Name:
                            <input
                                type="text"
                                name="firstName"
                                value={formData?.firstName || formData?.fullName }
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>
                        <label>
                           Last Name:
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>

                        <label>
                            Date of Birth:
                            <input
                                type="date"
                                name="dob"
                                value={formData.dob.slice(0, 10)}
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}
                            />
                        </label>


                        <label>
                            Assign To
                            <select
                                name="assignTo"
                                disabled={!isEditMode}
                                value={formData.assignTo}
                                className={isEditMode ? "" : "input-wout-border"}
                                onChange={handleInputChange}
                                required
                            >

                                {!isEditMode ? <option key={"jhjhi"} value={"hhjhj"}>{formData.assignTo[0]?.fullName || `${formData.assignTo[0]?.firstName} ${formData.assignTo[0]?.lastName}`} </option>: 
                               <>
                                <option key={"jhjhi"} value="">Select to assign</option>
                               {employeesInfo?.map((employee) => (
                                    <option key={employee._id} value={employee._id}>
                                        {employee.fullName || `${employee?.firstName} ${employee?.lastName}`}
                                    </option>
                                ))}
                                </>
                                }
                            </select>
                        </label>


                        <label>
                            Visa Status:
                            <input
                                type="text"
                                name="visa"
                                value={formData.visa}
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}
                            />
                        </label>



                        

                    </div>

                    <div>
                        <label>
                            Contact Number:
                            <input
                                type="text"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={ `hide-detail ${isEditMode ? "" : "input-wout-border"}`}
                            />
                        </label>


                        <label>
                                Email:
                            <input
                                type="email"
                                name="remoteJob"
                                value={formData.email }
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={`hide-detail ${isEditMode ? "" : "input-wout-border"}`}
                                // className={hide-password isEditMode ? "" : "input-wout-border"}
                            />
                        </label>

                        <label>
                            Skype ID:
                            <input
                                type="twxt"
                                name="skypeId"
                                value={formData.skypeId}
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={`hide-detail ${isEditMode ? "" : "input-wout-border"}`}
                            />
                        </label>
                        <label>
                            Job Status

                            <select
                                name="jobStatus"
                                disabled={!isEditMode}
                                value={formData.jobStatus}
                                className={isEditMode ? "" : "input-wout-border"}
                                onChange={handleInputChange}
                                required
                            >

                                <option value="New Candidates">New Candidates</option>
                                <option value="On Bench">On Bench</option>
                                <option value="Completing Projects">Completing Projects</option>
                                <option value="Placed Candidates">Placed Candidates</option>
                            </select>
                        </label>

                        {/* <label>
                    Current Address Line 1:
                    <input
                        type="text"
                        name="currentCityState"
                        value={formData.currentCityState}
                        onChange={handleInputChange}
                        disabled={!isEditMode}
                        className={isEditMode ? "" : "input-wout-border"}
                    />
                </label> */}
                
                        <label>
                            Visa Validity:
                            <input
                                type="date"
                                name="visaValidity"
                                value={formData?.visaValidity?.slice(0, 10)}
                                onChange={handleInputChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}
                            />
                        </label>
                    </div>
                </div>


                <div className='flex-row-between' style={{ gap: "40px" }}>
                    <div>
                        <label>
                            Current Address Line 1:
                            <input
                                type="text"
                                name="addressLine1"
                                value={formData.currentAddress.addressLine1}
                                onChange={handleInputCurrentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}
                            />
                        </label>

                        <label>
                            Current State:
                            <input
                                type="text"
                                name="state"
                                value={formData.currentAddress.state}
                                onChange={handleInputCurrentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>

                        <label>
                            Current City:
                            <input
                                type="text"
                                name="city"
                                value={formData.currentAddress.city}
                                onChange={handleInputCurrentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>

                        <label>
                            Current Pincode:
                            <input
                                type="text"
                                name="pincode"
                                value={formData.currentAddress.pincode}
                                onChange={handleInputCurrentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>
                    </div>

                    <div>
                        <label>
                            Permanent Address Line 1:
                            <input
                                type="text"
                                name="addressLine1"
                                value={formData.permanentAddress.addressLine1}
                                onChange={handleInputPermanentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>

                        <label>
                            Permanent Area/Town:
                            <input
                                type="text"
                                name="state"
                                value={formData.permanentAddress.state}
                                onChange={handleInputPermanentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>

                        <label>
                            Permanent City/State:
                            <input
                                type="text"
                                name="city"
                                value={formData.permanentAddress.city}
                                onChange={handleInputPermanentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>

                        <label>
                            Permanent Pincode:
                            <input
                                type="text"
                                name="pincode"
                                value={formData.permanentAddress.pincode}
                                onChange={handleInputPermanentAddressChange}
                                disabled={!isEditMode}
                                className={isEditMode ? "" : "input-wout-border"}

                            />
                        </label>
                    </div>
                </div>
                {/* Add more input fields for Section 1 */}
                {/* ... */}



            </div>
        </div>
    );
};

export default BasicDetails;
