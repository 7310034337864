import React, { useState,useEffect } from 'react';

function UploadDocuments(props) {
    const { onPrevious, data, onChange,onSubmit } = props;
    const [documents, setDocuments] = useState(data);
    const [tableDocuments, setTableDocuments] = useState([]);
   
    function convertToBase64(file){
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result)
        };
        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    }
  
    const handleChange = (event, index) => {
      const { name, value } = event.target;
      setDocuments((prevDocuments) => {
        const updatedDocuments = prevDocuments.map((document, docIndex) => {
          if (docIndex === index) {
            return {
              ...document,
              [name]: value,
            };
          }
          return document;
        });
        return updatedDocuments;
      });
    };
    
    
  
  
    useEffect(()=>{
      const isEmpty = documents.some(
        (documents) =>
          Object.values(documents).some((value) => value === '')
      );
      if(isEmpty)
      {
        console.log("Empty Documents")
      }
      else{
        setTableDocuments(documents)  
      }
      
     },[])
  
    const handleDocumentUpload = async (event, index) => {
      // const selectedFile = event.target.files[0];
      const base64 = event.target.files[0];
      const selectedFile = await convertToBase64(base64);
      setDocuments((prevDocuments) => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[index] = {
          ...updatedDocuments[index],
          selectedFile,
        };
        return updatedDocuments;
      });
    };
  
  
    const handleDocumentSave = () => {
      const isExperienceEmpty = documents.some(
        (exp) => Object.values(exp).some((value) => value === '')
      );  
      // Check if any field is empty
      if (isExperienceEmpty) {
        alert('Please fill all fields');
        return;
      }
    
  
       const updatedDocuments = [...documents]
       setDocuments(updatedDocuments)
       setTableDocuments(documents)
       onChange(documents)
     
    };
    
    const handleDownloadDocument = (index) => {
      const { documentName, selectedFile } = documents[index];
  
      if (selectedFile) {
        const url = URL.createObjectURL(selectedFile);
  
        const link = document.createElement('a');
        link.href = url;
        link.download = documentName || 'document';
        link.click();
  
        URL.revokeObjectURL(url);
      }
    };
  
    const handleAddDocument = () => {
      setDocuments((prevDocuments) => [
        ...prevDocuments,
        {
          documentName: '',
          selectedFile: null,
        },
      ]);
    };
  
    const handleRemoveDocument = (index) => {
      setDocuments((prevDocuments) => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments.splice(index, 1);
        return updatedDocuments;
      });
  
      onChange && onChange(documents);
    };
  
    return (
      <div>
        {/* <h3>Upload Documents</h3> */}
        <div style={{ margin: '30px' }}>
          {documents.map((document, index) => (
            <div key={index} className='box-shadow'>
              <div className="flex-row" style={{alignItems:"center"}}>
                <label>
                  Document
                  <input
                    type="text"
                    name="documentName"
                    value={document.documentName}
                    onChange={(event) => handleChange(event, index)}
                    placeholder="Document Name"
                    required
                  />
                </label>
                <label>&nbsp;</label>
                <input
                  type="file"
                  id={`input-${index}`}
                  onChange={(event) => handleDocumentUpload(event, index)}
                  required
                />
     <button onClick={() => handleRemoveDocument(index)} className='remove-button'>Remove</button>            </div>
              
            </div>
          ))}
           <button onClick={ handleAddDocument} className='add'>+ Add Documents</button><br/>
              <button onClick={ handleDocumentSave} className='save-button' style={{ margin: "30px auto" }} >Save</button>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Document Name</th>
                <th>File</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {tableDocuments.map((document, index) => (
                <tr key={index}>
                  <td>{document.documentName}</td>
                  <td>
                    <button onClick={() => handleDownloadDocument(index)} className='add td-center' >Download</button>
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
  
          <div className='flex-row-between'>
            <button onClick={onPrevious} className='next-button'><span className="material-symbols-outlined">
              keyboard_double_arrow_left
            </span>Previous</button>
            <button className='next-button' onClick={onSubmit}>Submit
              
            </button>
          </div>
        </div>
      </div>
    );
  }

export default UploadDocuments