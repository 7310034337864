import Axios from 'axios';
import {

    VALIDATE_TOKEN_REQUEST,
    VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAIL,

 

} from '../constants/constants';


const { REACT_APP_BASE_URL } = process.env


export const validateToken = () => async (dispatch, getState) => {
    const {
      employeeSignin: { employeeInfoData },
    } = getState();
    dispatch({ type: VALIDATE_TOKEN_REQUEST });
    try {
      const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/validate-token`,
        {
          headers: { Authorization: `Bearer ${employeeInfoData.token}` }
        }
      );
      dispatch({ type: VALIDATE_TOKEN_SUCCESS, payload: data });
  
    } catch (error) {
      dispatch({
        type: VALIDATE_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  