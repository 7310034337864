import React from 'react'
import { useState,useEffect } from 'react';

import img1 from './images/person1.jpg'

import img2 from './images/person2.jpg'
const UploadDocuments = (props) => {
    const {data, onClick} =props
    const [formData, setFormData] = useState(data);
  const [isEditMode, setIsEditMode] = useState(false);

  // const handleFileChange = (event, index) => {
  //   const file = event.target.files[0];
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     employmentHistory: prevData.employmentHistory.map((document, i) =>
  //       i === index ? { ...document, selectedFile: file } : document
  //     ),
  //   }));
  // };
  function convertToBase64(file){
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result)
      };
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }


  const handleFileChange = async (event, index) => {
    // const file = event.target.files[0];
    const base64 = event.target.files[0];
    const file = await convertToBase64(base64);
    console.log("base64......",base64)
    // const file = base64;
    const updatedSection4 = [...formData.documents];
    updatedSection4[index] = {
      ...updatedSection4[index],
      selectedFile: file,
      // fileName: file.name, // Store the file name
    };
    setFormData((prevData) => ({
      ...prevData,
      documents: updatedSection4,
    }));
  };
  
  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      documents: prevData.documents.map((docs, i) =>
        i === index ? { ...docs, [name]: value } : docs
      ),
    }));}
  const handleAddDocument = () => {
    setFormData((prevData) => ({
      ...prevData,
      documents: [...prevData.documents, { documentName: '', selectedFile: null }],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      documents: prevData.documents.filter((_, i) => i !== index),
    }));
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

//   function downloadImage(imageUrl, imageName) {
//     console.log("imageURL", imageUrl)
//     const link = document.createElement('a');
//     link.href = imageUrl;
//     link.setAttribute('download', imageName);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }
  
function downloadImage(imageUrl, imageName) {
    console.log("urlImage...............",imageUrl)
    const link = document.createElement('a');
    link.href = imageUrl;
    const fileExtensionExtract = imageUrl.split(';')[0].split(':')[1];
    const fileExtension = fileExtensionExtract.split('/')[1]
    console.log("fileExtension" ,fileExtension )
    link.setAttribute('download', `${imageName}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  

  const handleSave = () => {
    setIsEditMode(false);
    // Perform save logic or API call here
    // setFormData(formData)
    console.log("BeforeOnclick formdata",formData); 
    onClick(formData)
    console.log("ecedvererer",formData);  };
  return (
    <div style={{border: "1px solid black"}}>
        <div >
        <div className='flex-row-jBetween-aCenter ' style={{borderBottom: "1px solid black",padding:"20px"}}>
                <h2>Documents</h2>
                {isEditMode ? (
                    <button type="button" onClick={handleSave} className='edit-save-button'>
                        Save
                    </button>
                ) : (
                    <button type="button" onClick={handleEdit} className='edit-save-button'>
                        <span class="material-symbols-outlined">
                            edit
                        </span> Edit Information
                    </button>
                )}
            </div>

    <div className='form'>
            {formData.documents.map((document, index) => (
  <div key={index}>
    <h3>Document {index + 1}</h3>
    <div className='flex-row-between' style={{ gap: "40px" }}>
    <label>
      Document Name:
      <input
        type="text"
        // name={`employmentHistory[${index}].documentName`}
        name="documentName"

        value={document.documentName}
        onChange={(event) => handleInputChange(event, index)}
        disabled={!isEditMode}
        className={isEditMode ? "" : "input-wout-border"}

      />
    </label>
    <br />
    {isEditMode ? (
      <label>
        Select File:
        <input
          type="file"
          // name='sele'
          // name={`employmentHistory[${index}].selectedFile`}
          onChange={(event) => handleFileChange(event, index)}
          disabled={!isEditMode}
          className={isEditMode ? "" : "input-wout-border"}

        />
      </label>
    ) : (
        <div>
        {/* <img src={document.selectedFile} alt={`Document ${index + 1}`} /> */}
        
        <button onClick={() => downloadImage(document.selectedFile, document.documentName)} className='edit-save-button'>
        Download File
        </button>
      </div>
    )}
    </div>
    {/* <br />
    {isEditMode && (
      <button onClick={() => handleRemoveDocument(index)}>Remove Document</button>
    )}
    <br /> */}
  </div>
))}
</div>
      {/* {isEditMode && (
        <button onClick={handleAddDocument}>Add Document</button>
      )}
      <br /> */}
     
    </div>

    </div>
  )
}

export default UploadDocuments