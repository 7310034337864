import React from 'react'
import './candidatePopUP.scss'

import ButtonWithoutBG from '../../../components/Buttons/ButtonWithoutBG'
import { useState } from 'react'
import { useEffect } from 'react'
import data from '../../../data/data'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSubmissions, getSubmissionsById } from '../../../action.js/submissionAction'
import { useParams } from 'react-router-dom'
import { updateInterview } from '../../../action.js/interviewAction'

const InterviewPopUp = ({ submissionIndex, id, onClose }) => {
  // const { Id } = useParams(); 

  const [show, setShow] = useState(true)
  const [candidate, setCandidate] = useState()
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [isEditMode, setIsEditMode] = useState(true)
  const [submissionId, setSubmissionId]= useState()
  const getSubmissionsByIdDispatch = useDispatch()
  const getSubmissionsByIdSelector = useSelector((state) => state.getSubmissionById)
  const { submissionById, loading, error } = getSubmissionsByIdSelector

  useEffect(() => {
    getSubmissionsByIdDispatch(getSubmissionsById(id))
  }, [])


  useEffect(() => {
    console.log("candidate Data",candidate)

    const formdata = {...submissionById}
    setCandidate({...formdata})
   console.log("candidate Data",candidate)

  }, [submissionById])




  // const handleInputChange = (event, submissionIndex, field) => {
  //   const { value } = event.target;
  //   setCandidate((prevCandidate) =>{
  //     let submission = [...prevCandidate.submission];
  //     submission[submissionIndex].interview={
  //       ...submission[submissionIndex].interview,
  //       [field]:value
  //     }
  //     return {
  //       ...prevCandidate,
  //       submission: submission,
  //     }
  //   });
  // };


  const handleInputChange = (event, submissionIndex, field) => {
    const { value } = event.target;
    setCandidate((prevCandidate) => {
      const newCandidate = { ...prevCandidate };
      newCandidate.submission = [...prevCandidate.submission]; // Create a copy of the submission array
      newCandidate.submission[submissionIndex] = {
        ...newCandidate.submission[submissionIndex],
        interview: {
          ...newCandidate.submission[submissionIndex].interview,
          [field]: value,
        },
      };
      return newCandidate;
    });
  };
  
  

  const  getAllSubmissionDispatch = useDispatch()
  const updateInterviewDispatch = useDispatch()
  const handleSave = (subId) => {
    console.log("handlesave suBId", subId)
    const formData = candidate
    updateInterviewDispatch(updateInterview(formData, subId))
    getAllSubmissionDispatch(getAllSubmissions())
    onClose();
  }



  return (
    <>
      <div className='candidate-popup'>
        <div className='candidate-popup-content'>
          <div className='mainPopUPHead'>
            <img src={candidate?.photo} alt="" />
            <div style={{ padding: "20px" }}>
              <p>{candidate?.candidateId?.fullName || `${candidate?.candidateId?.firstName} ${candidate?.candidateId?.lastName}`}</p>
              {/* <p>{candidateProfile?.jobStatus}</p> */}

            </div>
            <button onClick={onClose} style={{ marginLeft: "auto" }}><i class="fa fa-close"></i>
            </button>
          </div>
          {/* <ButtonWithoutBG
            buttonName={"Submission Details"}
            boxBottomColor={"#FBBC05"}
            active={show ? "btnWoutBgActive" : ""}
            onClick={() => setShow(true)}
          /> */}
          {/* <ButtonWithoutBG
            buttonName={"All Submission"}
            boxBottomColor={"#FBBC05"}
            active={show ? "" : "btnWoutBgActive"}
            onClick={() => setShow(false)}
          /> */}
          {candidate?.submission?.map((item, index) => (
            (index == submissionIndex ?

              <div className="flex-column form" style={{ padding: "10px", gap: "10px" }}>

                <label>
                  InterView Title
                  <input 
                  value={item?.interview?.interviewTitle}
                 
                    onChange={(e) => handleInputChange(e, index, 'interviewTitle')}
                    disabled={!isEditMode}
                  />
                </label>

                
                <label>
                  Mode of Interview
                  <input value={item?.interview?.interviewType}
                    disabled={!isEditMode}
                    onChange={(e) => handleInputChange(e, index, 'interviewType')}
                  />
                </label>

                <label>
                  Schedule Interview For:<br/>
                  <input type="text" disabled value={candidate?.candidateId.fullName || candidate?.candidateId.firstName} onChange={(e) => handleInputChange(e, index, 'firstName')} />
                  <input type="text" disabled value={candidate?.candidateId.lastName} onChange={(e) => handleInputChange(e, index, 'lastName')} />
                </label>

                <label>
                  FeedBack of Interview
                  <textarea value={item?.interview?.comment}
                    disabled={!isEditMode}
                    style={{height:"100px", fontSize:"16px", padding:"10px"}}
                    onChange={(e) => handleInputChange(e, index, 'comment')}
                  />
                </label>

                <label>
                  Schedule By::<br/>
                  <input type="text" disabled value={item?.submittedBy?.fullName || item?.submittedBy?.firstName} onChange={(e) => handleInputChange(e, index, 'firstName')} />
                  <input type="text" disabled value={item?.submittedBy?.lastName} onChange={(e) => handleInputChange(e, index, 'lastName')} />
                </label>

                <label>
                  End Client Name
                  <input type="text" disabled value={item?.endClient} onChange={(e) => handleInputChange(e, index, 'endClient')} />
                </label>

                <label>
                  Job Source Type
                  <input type="text" disabled value={item?.jobSourceType} onChange={(e) => handleInputChange(e, index, 'jobSourceType')} />
                </label>

                <label>
                  Job Source Name
                  <input type="text" disabled value={item?.jobSourceName} onChange={(e) => handleInputChange(e, index, 'jobSourceName')} />
                </label>

                <label>
                  Interview Date
                  <input type="date" disabled={!isEditMode}
                    value={item?.interview?.dateOfSubmission?.slice(0, 10)}
                    onChange={(e) => handleInputChange(e, index, 'dateOfSubbmision')} />
                </label>

                <label>
                  Time Zone
                  <select
                    defaultValue={item?.interview?.timezone}
                    disabled={!isEditMode}
                    onChange={(e) => handleInputChange(e, index, 'timeZone')}
                  >


                    <option value="IST">IST (Indian Standard Time)</option>
                    <option value="UTC">UTC (Coordinated Universal Time)</option>
                    <option value="PST">PST (Pacific Standard Time)</option>
                    <option value="EST">EST (Eastern Standard Time)</option>
                    <option value="CST">CST (Central Standard Time)</option>
                    <option value="MST">MST (Mountain Standard Time)</option>
                    <option value="GMT">GMT (Greenwich Mean Time)</option>
                    <option value="CET">CET (Central European Time)</option>
                    <option value="AEDT">AEDT (Australian Eastern Daylight Time)</option>
                    <option value="JST">JST (Japan Standard Time)</option>

                  </select>

                </label>
                <div>
                  <label>
                    Start Time

                    <input type="time" disabled={!isEditMode} value={item?.interview?.timeStart} onChange={(e) => handleInputChange(e, index, 'timeStart')} />
                  </label>
                  <label>
                    End Time

                    <input type="time" disabled={!isEditMode} value={item?.interview?.timeEnd} onChange={(e) => handleInputChange(e, index, 'timeEnd')} />
                  </label>
                </div>

                <div className='save-button' onClick={()=>handleSave(item._id)}>Save</div>
              </div>

              : null

            )
          ))}


          {/* {show ? <SubmissionDetails submissionIndex={submissionIndex} id={id} onClose={onClose} />
              : <AllSubmissions id={id} />} */}
          {/* </div> */}

        </div>
      </div>



    </>
  )
}

export default InterviewPopUp