import React from 'react'
import { useEffect,useState } from 'react'

const ProfessionalExperience = (props) => {

    const { data, onClick } = props;
    const [formData, setFormData] = useState(data);
    const [isEditMode, setIsEditMode] = useState(false);
  
    const handleInputChange = (event, index) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        employmentHistory: prevData.employmentHistory.map((experience, i) =>
          i === index ? { ...experience, [name]: value } : experience
        ),
      }));}

   
  
    const handleEdit = () => {
      setIsEditMode(true);
    };
  
    useEffect(()=>{
      console.log("formdata",formData)
    })
    const handleSave = () => {
      setIsEditMode(false);
      // Perform save logic or API call here
      // setFormData(formData)
      onClick(formData)
      console.log("ecedvererer",formData);    };
  
  return (
    <div style={{border: "1px solid black"}}>
       
    
       <div className='flex-row-jBetween-aCenter ' style={{borderBottom: "1px solid black",padding:"20px"}}>
                <h2>Professional Experience</h2>
                {isEditMode ? (
                    <button type="button" onClick={handleSave} className='edit-save-button'>
                        Save
                    </button>
                ) : (
                    <button type="button" onClick={handleEdit} className='edit-save-button'>
                        <span class="material-symbols-outlined">
                            edit
                        </span> Edit Information
                    </button>
                )}
            </div>
    <div className='form'>
      {formData.employmentHistory.map((experience, index) => (
        <div key={index}>
        
          <h3>Experience {index + 1}</h3>
          <div className='flex-row-between' style={{ gap: "40px" }}>
        <div>
          <label>
            Company:
            <input
              type="text"
              name='company'
              // name={`employmentHistory[${index}].company`}
              value={experience.company}
              onChange={(event) => handleInputChange(event, index)}
              disabled={!isEditMode}
              className={isEditMode ? "" : "input-wout-border"}

            />
          </label>
          
          <label>
            Role:
            <input
              type="text"
              // name={`employmentHistory[${index}].role`}
              name='role'
              value={experience.role}
              onChange={(event) => handleInputChange(event, index)}
              disabled={!isEditMode}
              className={isEditMode ? "" : "input-wout-border"}

            />
          </label>
          </div>
        <div>
          <label>
            Start Date:
            <input
              type="date"
              // name={`employmentHistory[${index}].startDate`}
              name="startDate"
              value={experience.startDate?.slice(0, 10)} 
              onChange={(event) => handleInputChange(event, index)}
              disabled={!isEditMode}
              className={isEditMode ? "" : "input-wout-border"}

            />
          </label>
          
          <label>
            End Date:
            <input
              type="date"
              // name={`employmentHistory[${index}].endDate`}
              name="endDate"
              value={experience.endDate?.slice(0, 10)}   
              onChange={(event) => handleInputChange(event, index)}
              disabled={!isEditMode}
              className={isEditMode ? "" : "input-wout-border"}

            />
          </label>
          </div>
          </div>
          <label>
            Description:
            <textarea
             name="description"
              // name={`employmentHistory[${index}].description`}
              value={experience.description}
              onChange={(event) => handleInputChange(event, index)}
              disabled={!isEditMode}
              className={isEditMode ? "" : "input-wout-border"}
              style={{minHeight:"200px"}}

            />
          </label>
          
          {/* {isEditMode && (
            <button onClick={() => handleRemoveExperience(index)}>Remove Experience</button>
          )}
           */}
        </div>
      ))}
      {/* {isEditMode && (
        <button onClick={handleAddExperience}>Add Experience</button>
      )} */}
      
     
    </div>

    </div>
  )
}

export default ProfessionalExperience