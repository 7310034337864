import React from 'react'
import { useEffect , useState} from 'react'

const Candidates = (props) => {

    return (
      <div className='personInfoBox' onClick={props.onClick} style={{cursor:"pointer"}}>
            <div class="personNameImg">
                <img src={props.img} height="40px" width="40px"  style={{borderRadius:"50%"}} alt="" />
                <p>{props.firstName} {props.lastName} {props.name}</p>               
            </div>
            {/* <hr/> */}
            {/* <div className="star-rating">
      {[...Array(5)].map((star) => {        
        return (         
          <span className="star">&#9733;</span>        
        );
      })}
    </div> */}
      </div>
  )
  
}

export default Candidates