import  { 
    INTERVIEW_CREATE_FAIL, INTERVIEW_CREATE_REQUEST, INTERVIEW_CREATE_SUCCESS,
    INTERVIEW_UPDATE_SUCCESS,
    INTERVIEW_UPDATE_REQUEST,
    INTERVIEW_UPDATE_FAIL
} from '../constants/constants'



export const createInterviewReducers = (state = {}, action) => {
    switch (action.type) {
      case INTERVIEW_CREATE_REQUEST:
        return { loading: true };
      case INTERVIEW_CREATE_SUCCESS:
        return { loading: false, interviewSubmitted: action.payload };
      case INTERVIEW_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  
export const updateInterviewReducers = (state = {}, action) => {
  switch (action.type) {
    case INTERVIEW_UPDATE_REQUEST:
      return { loading: true };
    case INTERVIEW_UPDATE_SUCCESS:
      return { loading: false, interviewUpdated: action.payload };
    case INTERVIEW_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};