export const EMPLOYEE_SIGNIN_REQUEST = 'EMPLOYEE_SIGNIN_REQUEST';
export const EMPLOYEE_SIGNIN_SUCCESS = 'EMPLOYEE_SIGNIN_SUCCESS';
export const EMPLOYEE_SIGNIN_FAIL = 'EMPLOYEE_SIGNIN_FAIL';
export const EMPLOYEE_SIGNOUT = 'EMPLOYEE_SIGNOUT';

export const EMPLOYEE_CREATE_REQUEST = 'EMPLOYEE_CREATE_REQUEST';
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS';
export const EMPLOYEE_CREATE_FAIL = 'EMPLOYEE_CREATE_FAIL';

export const EMPLOYEE_DELETE_REQUEST = 'EMPLOYEE_DELETE_REQUEST';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
export const EMPLOYEE_DELETE_FAIL = 'EMPLOYEE_DELETE_FAIL';


export const EMPLOYEE_GET_ALL_REQUEST  = 'EMPLOYEE_GET_ALL_REQUEST ';
export const EMPLOYEE_GET_ALL_SUCCESS  = 'EMPLOYEE_GET_ALL_SUCCESS' ;
export const EMPLOYEE_GET_ALL_FAIL = 'EMPLOYEE_GET_ALL_FAIL';

export const CURRENT_USER_REQUEST  = 'CURRENT_USER_REQUEST ';
export const CURRENT_USER_SUCCESS  = 'CURRENT_USER_SUCCESS' ;
export const CURRENT_USER_FAIL = 'CURRENT_USER_FAIL';

export const EMPLOYEE_GET_BY_ID_REQUEST  = 'EMPLOYEE_GET_BY_ID_REQUEST ';
export const EMPLOYEE_GET_BY_ID_SUCCESS  = 'EMPLOYEE_GET_BY_ID_SUCCESS' ;
export const EMPLOYEE_GET_BY_ID_FAIL = 'EMPLOYEE_GET_BY_ID_FAIL';

export const EMPLOYEE_CREATE_PERMISSION_REQUEST = 'EMPLOYEE_CREATE_PERMISSION_REQUEST';
export const EMPLOYEE_CREATE_PERMISSION_SUCCESS = 'EMPLOYEE_CREATE_PERMISSION_SUCCESS';
export const EMPLOYEE_CREATE_PERMISSION_FAIL = 'EMPLOYEE_CREATE_PERMISSION_FAIL';

export const EMPLOYEE_GETONE_PERMISSION_REQUEST = 'EMPLOYEE_GETONE_PERMISSION_REQUEST';
export const EMPLOYEE_GETONE_PERMISSION_SUCCESS = 'EMPLOYEE_GETONE_PERMISSION_SUCCESS';
export const EMPLOYEE_GETONE_PERMISSION_FAIL = 'EMPLOYEE_GETONE_PERMISSION_FAIL';


export const COMPANY_ID_REQUEST = 'COMPANY_ID_REQUEST'; 
export const COMPANY_ID_SUCCESS = 'COMPANY_ID_SUCCESS';
export const COMPANY_ID_FAIL = 'COMPANY_ID_FAIL';

export const CANDIDATE_CREATE_REQUEST = "CANDIDATE_CREATE_REQUEST"
export const CANDIDATE_CREATE_SUCCESS = "CANDIDATE_CREATE_SUCCESS"
export const CANDIDATE_CREATE_FAIL = "CANDIDATE_CREATE_FAIL"


export const CANDIDATE_UPDATE_REQUEST = "CANDIDATE_UPDATE_REQUEST"
export const CANDIDATE_UPDATE_SUCCESS = "CANDIDATE_UPDATE_SUCCESS"
export const CANDIDATE_UPDATE_FAIL = "CANDIDATE_UPDATE_FAIL"

export const CANDIDATE_DELETE_REQUEST = "CANDIDATE_DELETE_REQUEST"
export const CANDIDATE_DELETE_SUCCESS = "CANDIDATE_DELETE_SUCCESS"
export const CANDIDATE_DELETE_FAIL = "CANDIDATE_DELETE_FAIL"

export const CANDIDATE_LIST_REQUEST = "CANDIDATE_LIST_REQUEST"
export const CANDIDATE_LIST_SUCCESS = "CANDIDATE_LIST_SUCCESS"
export const CANDIDATE_LIST_FAIL = "CANDIDATE_LIST_FAIL"

export const SUBMISSION_CREATE_REQUEST = "SUBMISSION_CREATE_REQUEST"
export const SUBMISSION_CREATE_SUCCESS = "SUBMISSION_CREATE_SUCCESS"
export const SUBMISSION_CREATE_FAIL = "SUBMISSION_CREATE_FAIL"


export const SUBMISSION_UPDATE_REQUEST = "SUBMISSION_UPDATE_REQUEST"
export const SUBMISSION_UPDATE_SUCCESS = "SUBMISSION_UPDATE_SUCCESS"
export const SUBMISSION_UPDATE_FAIL = "SUBMISSION_UPDATE_FAIL"


export const SUBMISSION_DELETE_REQUEST = "SUBMISSION_DELETE_REQUEST"
export const SUBMISSION_DELETE_SUCCESS = "SUBMISSION_DELETE_SUCCESS"
export const SUBMISSION_DELETE_FAIL = "SUBMISSION_DELETE_FAIL"

export const SUBIMISSION_GET_ALL_REQUEST  = 'SUBIMISSION_GET_ALL_REQUEST ';
export const SUBIMISSION_GET_ALL_SUCCESS  = 'SUBIMISSION_GET_ALL_SUCCESS' ;
export const SUBIMISSION_GET_ALL_FAIL = 'SUBIMISSION_GET_ALL_FAIL';

export const SUBIMISSION_GET_ID_REQUEST  = 'SUBIMISSION_GET_ID_REQUEST ';
export const SUBIMISSION_GET_ID_SUCCESS  = 'SUBIMISSION_GET_ID_SUCCESS' ;
export const SUBIMISSION_GET_ID_FAIL = 'SUBIMISSION_GET_ID_FAIL';

export const INTERVIEW_CREATE_REQUEST = "INTERVIEW_CREATE_REQUEST";
export const INTERVIEW_CREATE_SUCCESS = "INTERVIEW_CREATE_SUCCESS";
export const INTERVIEW_CREATE_FAIL = "INTERVIEW_CREATE_FAIL";

export const INTERVIEW_UPDATE_REQUEST = "INTERVIEW_UPDATE_REQUEST";
export const INTERVIEW_UPDATE_SUCCESS = "INTERVIEW_UPDATE_SUCCESS";
export const INTERVIEW_UPDATE_FAIL = "INTERVIEW_UPDATE_FAIL";


export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAIL = "VALIDATE_TOKEN_FAIL";

export const BULK_EMAIL_REQUEST = "BULK_EMAIL_REQUEST";
export const BULK_EMAIL_SUCCESS = "BULK_EMAIL_SUCCESS";
export const BULK_EMAIL_FAIL = "BULK_EMAIL_FAIL";


