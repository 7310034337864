import Axios from 'axios';
import {

  INTERVIEW_CREATE_FAIL,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_CREATE_REQUEST,
 
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_FAIL
} from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import { getAllSubmissions } from './submissionAction';

const { REACT_APP_BASE_URL } = process.env


export const createInterview = (formData) => async (dispatch, getState) => {
    const {
      employeeSignin: { employeeInfoData },
    } = getState();



    dispatch({ type: INTERVIEW_CREATE_REQUEST });
    try {

      const { data } = await Axios.post(`${REACT_APP_BASE_URL}/api/submissionInterview/saveInterview`, formData,
        {
          headers: { Authorization: `Bearer ${employeeInfoData.token}` }
        }
      );
      dispatch({ type:  INTERVIEW_CREATE_SUCCESS, payload: data });
      alert("Interview Created Successfully")
      dispatch(getAllSubmissions())
      useNavigate('/interviewPage')
    } catch (error) {
      dispatch({
        type:  INTERVIEW_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  
export const updateInterview = (formData,submissionId) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();
  console.log("hello1226778090iuijjkm")


  dispatch({ type: INTERVIEW_UPDATE_REQUEST });
  try {
  console.log("submissionId",submissionId)
    const { data } = await Axios.put(`${REACT_APP_BASE_URL}/api/submissionInterview/updateInterview/${submissionId}`, formData,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type:  INTERVIEW_UPDATE_SUCCESS, payload: data });
    alert("Interview Updated Successfully")
      dispatch(getAllSubmissions())
    // useNavigate('/interviewPage')
  } catch (error) {
    dispatch({
      type:  INTERVIEW_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};