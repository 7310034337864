import React, { useEffect, useState } from 'react';
import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentEmployee } from '../../action.js/employeeAction';
const Header = () => {

  // const [currentUser , setCurrentUser] = useState()
  const logout = () => {
    localStorage.removeItem('rekrootyLogInToken');
    localStorage.removeItem('rekrootyCompanyId');
    window.location.reload();
  };

  
  const getCurrentUserDispatch = useDispatch();
  const getCurrentUserSelector = useSelector((state) => state.getCurrentUser);
  const { currentUserInfo, loading, error } = getCurrentUserSelector;
  useEffect(() => {
    getCurrentUserDispatch(getCurrentEmployee())
  
      
  }, []);
  
  
  // useEffect(() => {
  //   // if (effect.current == true) {
  //   //     effect.current = false
  //   // console.log(data.employees)
  //   setCurrentUser(currentUserInfo);

  //   // }
  // }, [currentUserInfo]);

  // useEffect(() => {
  //   console.log('currentUser==============', JSON.stringify(currentUser));
  // }, [currentUser]);
  // // const [employeeData, setEmployee] = useState()
  // // useEffect(() => {
  // //   getCurrentUserDispatch(getCurrentEmployee())
     
  // // }, []);
 return (
    <div className="header">
      <div className="firstHalf">
        <div style={{ width: '50px' }}></div>
        <div className="left">
          {/* <div className='org'>
            Your Organization
          </div> */}
          <div className="leftBottom">
            <img src="/image/header_logo.png" alt="" />
            {/* <span>Networsys Technologies</span> */}
          </div>
        </div>

        {/* <div>
      <i className="fa fa-search" style={{fontSize:"48px",color:"#d1d1d1", marginLeft:"30px",display:"none"}}></i>
      </div> */}
      </div>

      <div className="second-half">
        <div className="wrapper">
          <div className="searchBar">
            <input
              id="searchQueryInput"
              type="text"
              name="searchQueryInput"
              placeholder="Search"
              value=""
            />
            <button
              id="searchQuerySubmit"
              type="submit"
              name="searchQuerySubmit"
              // onClick={handleSearch}
            >
              <svg
                style={{ width: '24px', height: '24px' }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#666666"
                  d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <button type="button" class="icon-button">
          {/* <span class="material-icons">notifications</span> */}
          <i className="fa fa-bell" style={{ fontSize: '24px' }}></i>
          <span class="icon-button__badge">2</span>
        </button>
        <div
          id="profile"
          style={{ whiteSpace: 'nowrap', display: 'flex' }}
          className="profile-container"
        >
          <div>
            <div className="profile-name">
              <b>{currentUserInfo?.employee.fullName}</b>
            </div>
            <div className="profile-desination">{currentUserInfo?.employee?.designation}</div>
          </div>
          <i
            className="fa fa-plus-circle"
            style={{
              fontSize: '48px',
              color: '#FBBC05',
              marginLeft: '30px',
              display: 'none',
            }}
          ></i>
          <i
            className="fa fa-bell"
            style={{
              fontSize: '48px',
              color: '#000000',
              marginLeft: '30px',
              display: 'none',
            }}
          ></i>
          <img
            src="/image/icon.png"
            style={{
              width: '48px',
              borderRadius: '50%',
              marginLeft: '30px',
              marginRight: '30px',
            }}
            alt=""
          />
          <button className="logout-button" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
