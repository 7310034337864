import React from 'react'
import data from '../../../data/data'
import { useEffect } from 'react'
import { useState } from 'react'
const AllSubmissions = ({id}) => {
    const [submissions, setSubmissions] = useState()
    useEffect(()=>{
        console.log("data.submissionData",data.submissionData)
        const findData = data.submissionData?.find((item)=>item.id == id)
        console.log("findData",findData)
        setSubmissions(findData)
        console.log("submissions",submissions)
    })
  return (
    <div>
        <table className="candidate-table">
  <thead>
    <tr>
      <th>Submission Date</th>
      <th>Status</th>
      <th>Job Source Type</th>
      <th>Job Source Name</th>
      <th>End Clients</th>
      
    </tr>
  </thead>
  <tbody>
    
        {submissions?.submission.map((candidate, subIndex) => (
          <tr key={subIndex} style={{ marginBottom: "300px" }}>
            
            <td>{candidate?.dateOfSubbmision}</td>
            <td>{candidate?.status}</td>
            <td>{candidate?.jobSourceType}</td>
            <td>{candidate.jobSourceName}</td>
            <td>{candidate?.endClient}</td>
           
          </tr>
        ))}
      
  </tbody>
</table>
    </div>
  )
}

export default AllSubmissions