import React, { useEffect, useState } from 'react';
import "./candidatePopUP.css"
import BasicDetails from './CandidatePopUPComponent/BasicDetails';
import AcademicDetails from './CandidatePopUPComponent/AcademicDetails';
import ProfessionalExperience from './CandidatePopUPComponent/ProfessionalExperience';
import UploadDocuments from './CandidatePopUPComponent/UploadDocuments';
import { useDispatch, useSelector } from 'react-redux';
import  {updateCandidate, deleteCandidate}  from '../../../action.js/candidateAction';
import { getAllEmployee } from '../../../action.js/employeeAction';

const CandidatePopUP = ({ initialData, onClose }) => {
    const [formData, setFormData] = useState(initialData);
    const [editMode1, setEditMode1] = useState(false);
    const [editMode2, setEditMode2] = useState(false);
    
    console.log("formData", formData)

    useEffect(() => {
        setFormData(initialData);
    }, [initialData]);


    useEffect(() => {
        console.log("formsTAFASXHCB", formData);
    }, [formData]);


    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditModeToggle1 = () => {
        setEditMode1(!editMode1);
    };

    const handleEditModeToggle2 = () => {
        setEditMode2(!editMode2);
    };



  //update candidate handle submit  
  const updatedCandidateSelector = useSelector((state)=>state.updateCandidate)
  const {updateCandidateInfo , loading , error} = updatedCandidateSelector
  const updateCandidateDispatch = useDispatch()
    const handleSubmit = (updatedData) => {
        // e.preventDefault();
        // Handle form submission, e.g., send data to backend
        // setFormData(updatedData)

         updateCandidateDispatch(updateCandidate(updatedData))
        //  window.location.reload();
        console.log('Form submitted:cccccccccccc', formData);
        // Reset edit mode
        // setEditMode1(false);
        // setEditMode2(false);
    };

   // delete candidate handle submit  
  const deleteCandidateSelector = useSelector((state)=>state.deleteCandidate)
  const {deleteCandidateInfo , deleteCandidateLoading= loading , deleteCandidateError= error} = deleteCandidateSelector
  const deleteCandidateDispatch = useDispatch()
   
const deleteCandidateSubmit = async (id) =>{
    if (window.confirm('Are you sure you want to delete this candidate?')) {
       await deleteCandidateDispatch(deleteCandidate(id))
        onClose();
        console.log('Candidate deleted with ID:', id);
      } else {
        // User canceled deletion
        console.log('Deletion canceled.');
      }
console.log("delete==id",id)
}
    return (
        <>
            <div className='candidate-popup'>

                <div className='candidate-popup-content'>
                    <div className='mainPopUPHeadCol'>
                        <div  className='mainPopUPHead'>
                        <img src={formData.photo} alt="" />
                        <div style={{ padding: "20px" }}>
                            <p>{formData.fullName ||  `${formData.firstName} ${formData.lastName}`}</p>
                            <p>{formData.jobStatus}</p>

                        </div>
                        <button onClick={onClose} style={{ marginLeft: "auto" }}><i class="fa fa-close"></i>
                        </button>
                        </div>
                       
                       <p className='error'> {error ? "Please check all fields" :""}</p>
                    </div>
                       
                    <div className="flex-column" style={{ padding: "10px", gap: "30px" }}>
                        <BasicDetails data={initialData} onClick={handleSubmit} />
                        <AcademicDetails data={initialData} onClick={handleSubmit} />
                        <ProfessionalExperience data={initialData} onClick={handleSubmit} />
                        <UploadDocuments data={initialData} onClick={handleSubmit} />

                        <button  onClick={()=>deleteCandidateSubmit(formData._id)}
                         style={{fontSize:"16px",width:"fit-content",padding:"5px 10px", color:"red", cursor: 'pointer', margin:"auto" }}><i class="fa fa-trash-o"></i> Delete</button>
                    </div>
                </div>

                
            </div>

        </>
    );
};

export default CandidatePopUP;
