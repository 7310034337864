import {

    COMPANY_ID_REQUEST,
    COMPANY_ID_SUCCESS,
    COMPANY_ID_FAIL
  
  } from '../constants/constants';
  



  export const getCompanyIdReducers = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_ID_REQUEST:
        return { loading: true };
      case COMPANY_ID_SUCCESS:
        return { loading: false, companyId: action.payload };
      case  COMPANY_ID_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };