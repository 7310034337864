import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateToken } from './action.js/validateTokenAction';
import { getCompanyId } from './action.js/companyAction';

const ProtectedRoute = (props) => {

  const { Component } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // const getSubdomain = () => {
  //   const hostname = window.location.hostname;
  //   const parts = hostname.split('.');
  //   if (parts.length >= 2) {
  //     const subdomain = parts[0];
  //     const mainDomain = parts[1];
  //     if (mainDomain === process.env.REACT_APP_FRONTEND_URL) {
  //       return subdomain;
  //     }
  //   }
  
  //   return null;
  // };


  // const [subDomainExists, setSubDomainExists] = useState(false);
  // const navigate = useNavigate();
  
  const companyIdDispatch = useDispatch();

  const getCompanyIdSelector = useSelector((state) => state.getCompanyId);
  const { getcompanyId, loading: companyIdLoading,  error:companyIdError } = getCompanyIdSelector;

//   useEffect(() => {
//     const fetchData = async () => {
//       const subdomain = await getSubdomain();
//       if (subdomain !== null) {
//         setSubDomainExists(true)
//         companyIdDispatch(getCompanyId(subdomain))
       
//       }
     
//     }

//     fetchData();
// },[])

  useEffect(()=>{
    if(companyIdError){
      navigate('/support')
    }
  },[])

  const validateTokenSelector = useSelector((state) => state.validateToken);
  const { validate, error, loading } = validateTokenSelector;

  const [hasRun, setHasRun] = useState(true);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('rekrootyLogInToken'));
    console.log()
    if (hasRun) {
      setHasRun(false);

      if (!items) {
        navigate('/');
      } else {
        dispatch(validateToken());
        console.log("dispatch")
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      localStorage.removeItem("rekrootyLogInToken");
      localStorage.removeItem("rekrootyCompanyId");

       navigate('/');
      // console.log("error",error)
    }
    // console.log
  }, [error]);





  return (
    <div>{validate ? <Component /> : null}</div>
  );
};

export default ProtectedRoute;
