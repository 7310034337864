
import {

    BULK_EMAIL_REQUEST,
    BULK_EMAIL_SUCCESS,
    BULK_EMAIL_FAIL,
  } from '../constants/constants';

  import Axios from 'axios';
  const { REACT_APP_BASE_URL } = process.env
  export const sendBulkEmails = (recipientEmails, customMessage, ccEmail, bccEmail) => async (dispatch) => {
    dispatch({ type: BULK_EMAIL_REQUEST });
  
    try {
      const response = await Axios.post(`${REACT_APP_BASE_URL}/api/send-bulk-email/send-bulk-emails`, {
        emails: recipientEmails.split(',').map((email) => email.trim()),
        message: customMessage,
        ccEmail: ccEmail.split(',').map((email) => email.trim()),
        bccEmail: bccEmail.split(',').map((email) => email.trim()),
      });
  
      if (response.status === 200) {
        dispatch({ type: BULK_EMAIL_SUCCESS, payload: 'Emails sent successfully!' });
        alert("Mail Send Succesfully")
      } else {
        dispatch({ type: BULK_EMAIL_FAIL, payload: 'Error sending emails.' });
        alert("Error Occured")
      }
    } catch (error) {
      dispatch({
        type: BULK_EMAIL_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };