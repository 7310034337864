import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import { useEffect } from 'react';
import './Candidate.css';
import BasicDetails from './CandidateFormComponent/BasicDetails';
import AcademicDetails from './CandidateFormComponent/AcademicDetails';
import ProfessionalExperience from './CandidateFormComponent/ProfessionalExperience';
import UploadDocuments from './CandidateFormComponent/UploadDocuments';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCandidate,
  getAllCandidates,
} from '../../../action.js/candidateAction';
import { useNavigate } from 'react-router-dom';
import ButtonWithoutBG from '../../../components/Buttons/ButtonWithoutBG';

function CandidateForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    section1: {
      photo: null,
      fullName: '',
      firstName:'',
      lastName:'',
      dob: '',
      currentAddressLine1: '',
      currentAreaTown: '',
      currentCityState: '',
      currentPincode: '',
      permanentAddressLine1: '',
      permanentAreaTown: '',
      permanentCityState: '',
      permanentPincode: '',
      email: '',
      sameAddress: false,
      contactNumber: '',
      skypeId: '',
      domain: '',
      visa: '',
      visaValidity: '',
      citizenship: '',
      relocateAnywhere: false,
      selectedLocations: [],
      selectedCountry: '',
      selectedStates: '',
      selectedCities: [],
      incrementValue: 3,
    },

    section2: {
      highestQualification: '',
      qualifications: [
        {
          qualification: '',
          institute: '',
          resultUnit: '',
          totalMarks: '',
          obtainedMarks: '',
        },
      ],
    },

    section3: [
      {
        company: '',
        role: '',
        startDate: '',
        endDate: '',
        description: '',
      },
    ],

    section4: [
      {
        documentName: '',
        selectedFile: null,
      },
    ],
  });

  const [step, setStep] = useState(1);
  const createCandidateDispatch = useDispatch();
  const createCandidateSelector = useSelector((state) => state.createCandidate);
  const { candidate, error, loading } = createCandidateSelector;
  const candidateListDispatch = useDispatch();
  useEffect(() => {
    console.log('formData.....................', formData);
  }, [formData]);
  // const handleNext = () => {
  //   setStep(step + 1);
  // };
  const submit = () => {
    const isFormValid = validateForm();
    console.log('isFormValid', isFormValid);
    if (isFormValid) {
      const spreadFormData = {
        ...formData.section1,
        section2: formData.section2,
        section3: formData.section3,
        section4: formData.section4,
      };

      createCandidateDispatch(createCandidate(spreadFormData)).then(() => {
        // Dispatch an action to refresh the candidate list
        candidateListDispatch(getAllCandidates());
      });
      //  candidateListDispatch(getAllCandidates());
      // console.log("Form submitted!", spreadFormData);
      alert('Form Successfully Submitted');
      navigate('/candidatePage');
    } else {
      // Perform any necessary actions upon form submission
      alert('Please Fill Every  Fields');
    }
  };

  useEffect(() => {
    console.log('formdata', formData);
  });

  const handleNext = () => {
    setStep(step + 1);
  };
  const handleNext1 = () => {
    // Check if all required fields in all sections are filled
    const isFormValid = validateSection1();

    if (isFormValid) {
      setStep(step + 1);
    } else {
      alert('Please fill all required fields in all sections.');
    }
  };

  const handleNext2 = () => {
    // Check if all required fields in all sections are filled
    const isFormValid = validateSection2();

    if (isFormValid) {
      setStep(step + 1);
    } else {
      alert('Please fill all required fields in all sections.');
    }
  };

  const handleNext3 = () => {
    // Check if all required fields in all sections are filled
    const isFormValid = validateSection3();

    if (isFormValid) {
      setStep(step + 1);
    } else {
      alert('Please fill all required fields in all sections.');
    }
  };

  const handleNext4 = () => {
    // Check if all required fields in all sections are filled
    const isFormValid = validateSection4();

    if (isFormValid) {
      setStep(step + 1);
    } else {
      alert('Please fill all required fields in all sections.');
    }
  };
  const validateForm = () => {
    // Check if all required fields in section1 are filled
    const isSection1Valid = validateSection1();

    // Check if all required fields in section2 are filled
    const isSection2Valid = validateSection2();

    // Check if all required fields in section3 are filled
    const isSection3Valid = validateSection3();

    // Check if all required fields in section4 are filled
    const isSection4Valid = validateSection4();

    // Return true only if all sections are valid
    return (
      isSection1Valid && isSection2Valid && isSection3Valid && isSection4Valid
    );
  };

  // Example validation functions for each section
  const validateSection1 = () => {
    const {
      fullName,
      firstName,
      lastName,
      dob,
      currentAddressLine1,
      currentAreaTown,
      currentCityState,
      currentPincode,
      permanentAddressLine1,
      permanentAreaTown,
      permanentCityState,
      permanentPincode,
      email,
      contactNumber,
      skypeId,
      domain,
      visa,
      visaValidity,
      citizenship,
    } = formData.section1;

    // Check if any of the required fields in section1 is empty
    if (
      firstName === '' ||
      lastName === '' ||
      dob === '' ||
      currentAddressLine1 === '' ||
      currentAreaTown === '' ||
      currentCityState === '' ||
      currentPincode === '' ||
      permanentAddressLine1 === '' ||
      permanentAreaTown === '' ||
      permanentCityState === '' ||
      permanentPincode === '' ||
      email === '' ||
      contactNumber === '' ||
      skypeId === '' ||
      domain === '' ||
      visa === '' ||
      // visaValidity === '' ||
      citizenship === ''
    ) {
      return false;
    }

    return true;
  };

  const validateSection2 = () => {
    const { highestQualification, qualifications } = formData.section2;

    // Check if the highest qualification is empty
    if (highestQualification === '') {
      return false;
    }

    // Check if any of the qualifications fields in the array are empty
    const isQualificationsValid = qualifications.every(
      (qualification) =>
        qualification.qualification !== '' && qualification.institute !== ''
      // &&
      // qualification.resultUnit !== '' &&
      // qualification.totalMarks !== '' &&
      // qualification.obtainedMarks !== ''
    );

    if (!isQualificationsValid) {
      return false;
    }

    return true;
  };

  const validateSection3 = () => {
    const { section3 } = formData;

    // Check if any of the fields in the section3 array are empty
    const isSection3Valid = section3.every(
      (item) => item.company !== '' && item.role !== '' && item.startDate !== ''
      //  &&
      // item.endDate !== ''
      //  &&
      // item.description !== ''
    );

    if (!isSection3Valid) {
      return false;
    }

    return true;
  };

  const validateSection4 = () => {
    const { section4 } = formData;

    // Check if any of the fields in the section4 array are empty
    const isSection4Valid = section4.every(
      (item) => item.documentName !== '' && item.selectedFile !== null
    );

    if (!isSection4Valid) {
      return false;
    }

    return true;
  };

  // Example validation functions for other sections (section2, section3, section4)
  // Implement similar logic to check if required fields are filled for each section

  const handlePrevious = () => {
    setStep(step - 1);
  };
  const handleSectionChange = (sectionNumber, data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`section${sectionNumber}`]: data,
    }));
  };
  return (
    <>
      {/* <div className='container' >
    <Sidebar />
    <div className='HeaderMainPage'>
      <Header />
      <div className='main'> */}

      <div className="flex-column">
        <div>
          <div className="breadcrumb">
            <div>
              <h1>CANDIDATES</h1>
              <p>Candidates / Candidates Form</p>
            </div>
          </div>
        </div>
        <br />

        <div className="candidate-form" style={{ background: '#ffffff' }}>
          {/* <div className='form-arrow-menu'>
          <button 
          className="arrowBox arrow-right">Basic Details</button>
          <button className="arrowBox arrow-right">Academic Details</button>
          <button className="arrowBox arrow-right">Professional Experience</button>
          <button className="arrowBox arrow-right">Upload Documents</button>
        </div> */}

          <div >
          <ButtonWithoutBG
            buttonName={'Basic Details'}
            boxBottomColor={'#FBBC05'}
            active={ step===1 ?'btnWoutBgActive': 'btnWoutBg'}
            onClick={() => setStep(1)}
          />
            <ButtonWithoutBG
            buttonName={'Education Details'}
            boxBottomColor={'#FBBC05'}
            active={ step===2 ?'btnWoutBgActive': 'btnWoutBg'}
            onClick={() => setStep(2)}
          />

        <ButtonWithoutBG
                    buttonName={'Professional Details'}
                    boxBottomColor={'#FBBC05'}
                    active={ step===3 ?'btnWoutBgActive': 'btnWoutBg'}
                    onClick={() => setStep(3)}
                    // onClick={() => setCandidates(true)}
        />
        <ButtonWithoutBG
                    buttonName={'Upload Documents'}
                    boxBottomColor={'#FBBC05'}
                    active={ step===4 ?'btnWoutBgActive': 'btnWoutBg'}
                    onClick={() => setStep(4)}
                    // onClick={() => setCandidates(true)}
        />
          
          </div>
          {error ? <p style={{ color: '#b31409' }}>{error}</p> : ''}

          {step === 1 && (
            <BasicDetails
              onNext1={handleNext1}
              data={formData.section1}
              onChange={(data) => handleSectionChange(1, data)}
            />
          )}
          {step === 2 && (
            <AcademicDetails
              onPrevious={handlePrevious}
              onNext={handleNext2}
              data={formData.section2}
              onChange={(data) => handleSectionChange(2, data)}
            />
          )}
          {step === 3 && (
            <ProfessionalExperience
              onPrevious={handlePrevious}
              onNext={handleNext3}
              data={formData.section3}
              onChange={(data) => handleSectionChange(3, data)}
            />
          )}
          {step === 4 && (
            <UploadDocuments
              onPrevious={handlePrevious}
              data={formData.section4}
              onChange={(data) => handleSectionChange(4, data)}
              onSubmit={submit}
            />
          )}
        </div>
      </div>

      {/* </div>
</div>
</div> */}
    </>
  );
}

export default CandidateForm;
