import Axios from 'axios';
import {

  CANDIDATE_CREATE_REQUEST,
  CANDIDATE_CREATE_SUCCESS,
  CANDIDATE_CREATE_FAIL,

  CANDIDATE_LIST_REQUEST,
  CANDIDATE_LIST_SUCCESS,
  CANDIDATE_LIST_FAIL,
  CANDIDATE_UPDATE_REQUEST,
  CANDIDATE_UPDATE_SUCCESS,
  CANDIDATE_UPDATE_FAIL,
  CANDIDATE_DELETE_REQUEST,
  CANDIDATE_DELETE_SUCCESS,
  CANDIDATE_DELETE_FAIL

} from '../constants/constants';
import { useNavigate } from 'react-router-dom';


const { REACT_APP_BASE_URL } = process.env


export const createCandidate = (formData) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState(); 
console.log("employeeInfoData",employeeInfoData)
  const transformedData = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    dob: formData.dob,
    currentAddress: {
      addressLine1: formData.currentAddressLine1,
      city: formData.currentAreaTown,
      state: formData.currentCityState,
      pincode: formData.currentPincode,
    },
    permanentAddress: {
      addressLine1: formData.permanentAddressLine1,
      city: formData.permanentAreaTown,
      state: formData.permanentCityState,
      pincode: formData.permanentPincode,
    },
    email: formData.email,
    contactNumber: formData.contactNumber,
    skypeId: formData.skypeId,
    domain: formData.domain,
    visa: formData.visa,
    visaValidity: formData.visaValidity,
    citizenship: formData.citizenship.split(',')[0], // Extracting only the country part
    relocateAnywhere: formData.relocateAnywhere === 'on' ? true : false,
    selectedCountry: formData.selectedCountry.split(',')[0], // Extracting only the country part
    selectedStates: formData.selectedStates.split(','),
    // jobStatus: ' Requested', // You can set this value as needed
    selectedCities: formData.selectedCities,
    incrementValue: formData.incrementValue,
    educationQualifications: {
      highestQualification: formData.section2.highestQualification,
      qualifications: formData.section2.qualifications.map((qualification) => ({
        qualification: qualification.qualification,
        institute: qualification.institute,
        resultUnit: qualification.resultUnit,
        totalMarks: qualification.totalMarks,
        obtainedMarks: qualification.obtainedMarks,
      })),
    },
    employmentHistory: formData.section3.map((employment) => ({
      company: employment.company,
      role: employment.role,
      startDate: employment.startDate,
      endDate: employment.endDate,
      description: employment.description,
    })),
    documents: formData.section4.map((document) => ({
      documentName: document.documentName,
      selectedFile: document.selectedFile, // Assuming you have access to the uploaded file's name
    })),
  };


  dispatch({ type: CANDIDATE_CREATE_REQUEST, payload: { ...transformedData } });
  console.log("transformed...............",{ ...transformedData })
  try {
    const { data } = await Axios.post(`${REACT_APP_BASE_URL}/api/candidate/create`, { ...transformedData },
      {
        headers :{ Authorization: `Bearer ${employeeInfoData.token}`}
      });
    dispatch({ type: CANDIDATE_CREATE_SUCCESS, payload: data });
    // alert("Candidate Created Successfully")
  } catch (error) {
    dispatch({
      type: CANDIDATE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};




export const getAllCandidates = () => async (dispatch,getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();
  dispatch({ type: CANDIDATE_LIST_REQUEST});
  try {
      const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/candidate/getAll`,
      {
        headers :{ Authorization: `Bearer ${employeeInfoData.token}`}
      }
      );
      dispatch({ type: CANDIDATE_LIST_SUCCESS, payload: data });
      // localStorage.setItem('rekrootyCompanyId', JSON.stringify(data.companyId));
  } catch (error) {
      dispatch({
          type: CANDIDATE_LIST_FAIL,
          payload:
              error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
      });
  }
};


export const updateCandidate = (formData) => async (dispatch,getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();
  dispatch({ type: CANDIDATE_UPDATE_REQUEST});
  try {
      const { data } = await Axios.put(`${REACT_APP_BASE_URL}/api/candidate/update/${formData._id}`,formData,
      {
        headers :{ Authorization: `Bearer ${employeeInfoData.token}`}
      }
      );
      dispatch({ type: CANDIDATE_UPDATE_SUCCESS, payload: data });
      dispatch(getAllCandidates())
      alert("Candidate Updated Successfully")

      // getAllCandidates()
      // localStorage.setItem('rekrootyCompanyId', JSON.stringify(data.companyId));
  } catch (error) {
      dispatch({
          type: CANDIDATE_UPDATE_FAIL,
          payload:
              error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
      });
  }
};

export const deleteCandidate = (id) => async (dispatch,getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();
  dispatch({ type: CANDIDATE_DELETE_REQUEST});
  try {
      const { data } = await Axios.delete(`${REACT_APP_BASE_URL}/api/candidate/delete/${id}`,
      {
        headers :{ Authorization: `Bearer ${employeeInfoData.token}`}
      }
      );
      dispatch({ type: CANDIDATE_DELETE_SUCCESS, payload: data });
      dispatch(getAllCandidates())
      alert("Candidate Deleted Successfully")

      // getAllCandidates()
      // localStorage.setItem('rekrootyCompanyId', JSON.stringify(data.companyId));
  } catch (error) {
      dispatch({
          type: CANDIDATE_DELETE_FAIL,
          payload:
              error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
      });
  }
};