import Axios from 'axios';
import {

  EMPLOYEE_CREATE_PERMISSION_FAIL,
  EMPLOYEE_CREATE_PERMISSION_REQUEST,
  EMPLOYEE_CREATE_PERMISSION_SUCCESS,

  EMPLOYEE_GETONE_PERMISSION_FAIL,
  EMPLOYEE_GETONE_PERMISSION_REQUEST,
  EMPLOYEE_GETONE_PERMISSION_SUCCESS,

} from '../constants/constants';

import { useNavigate } from 'react-router-dom';
const { REACT_APP_BASE_URL } = process.env


export const createPermission = (employeeId) => async (dispatch,getState) => {
    const {
        employeeSignin: { employeeInfoData },
      } = getState();
    dispatch({ type: EMPLOYEE_CREATE_PERMISSION_REQUEST, payload:  employeeId });
    console.log("sign2")
    try {
      console.log("sign3", REACT_APP_BASE_URL)
      const { data } = await Axios.put(`${REACT_APP_BASE_URL}/api/employeePermission/update`, employeeId,{
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      });
      console.log("sign4")
      dispatch({ type:   EMPLOYEE_CREATE_PERMISSION_SUCCESS,  payload: data });
      alert("Updated Successfully")
      useNavigate('/employeePage')
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CREATE_PERMISSION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  // export const getOneEmployee = (accessData) => async (dispatch,getState) => {
  //   const {
  //       employeeSignin: { employeeInfoData },
  //     } = getState();
  //   dispatch({ type: EMPLOYEE_GETONE_PERMISSION_REQUEST, payload:  accessData });
  //   try {
  //     const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/employeePermission/getOne`, accessData,{
  //       headers: { Authorization: `Bearer ${employeeInfoData.token}` }
  //     });
  //     dispatch({ type:   EMPLOYEE_GETONE_PERMISSION_SUCCESS,  payload: data });
  //     alert("Updated Successfully")
  //   } catch (error) {
  //     dispatch({
  //       type: EMPLOYEE_GETONE_PERMISSION_FAIL,
  //       payload:
  //         error.response && error.response.data.message
  //           ? error.response.data.message
  //           : error.message,
  //     });
  //   }
  // };


  export const getOneEmployee = (employeeId) => async (dispatch, getState) => {
    const {
      employeeSignin: { employeeInfoData },
    } = getState();
  
    dispatch({ type: EMPLOYEE_GETONE_PERMISSION_REQUEST, payload: employeeId });
  
    try {
      const { data } = await Axios.get(
        `${REACT_APP_BASE_URL}/api/employeePermission/getOne`,
        {
          params:{ employeeId}, // Pass accessData as query parameters
          headers: { Authorization: `Bearer ${employeeInfoData.token}` },
        }
      );
  
      dispatch({ type: EMPLOYEE_GETONE_PERMISSION_SUCCESS, payload: data });
    
    } catch (error) {
      dispatch({
        type: EMPLOYEE_GETONE_PERMISSION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  