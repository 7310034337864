import React, { useEffect, useState } from 'react';
import data from '../../data/data';
import Candidates from './Candidates';
import { useRef } from 'react';
import CandidatesHeader from './CandidatesHeader';
import './candidate.css';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG';
import ButtonWithBG from '../../components/Buttons/ButtonWithBG';
import CandidatePopUP from './CandidatesPopUP/CandidatePopUP';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import DistributeCandidates from './DistributeCandidates';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidates } from '../../action.js/candidateAction';

const MainPage = () => {
  const [candidates, setCandidates] = useState(true);
  const [newCandidates, setNewCandidates] = useState();
  const [onBench, setOnBench] = useState();
  const [completingProjects, setCompletingProjects] = useState();
  const [placedCandidates, setPlacedCandidates] = useState();
  const effect = useRef(true);
  const [groupByarr, setGroupByArr] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showPopUP, setShowPopUP] = useState();

  // For Searching Functionality
  const [searchNewCandidates, setSearchNewCandidates] = useState('');
  const [searchOnBench, setSearchOnBench] = useState('');
  const [searchCompletingProjects, setSearchCompletingProjects] = useState('');
  const [searchPlacedCandidates, setSearchPlacedCandidates] = useState('');

  // Search function for each category
  const filterCandidates = (category, searchValue) => {
    return category.filter((person) =>
      person.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  // This handlePopupToggle for  New Candidates Pop up for Distribution candidates
  const [showPopupNewCandidates, setShowPopupNewCandidates] = useState(false);
  const handlePopupToggle = () => {
    // setShowPopupNewCandidates((prevShowPopup) => !prevShowPopup); // Toggle the value of showPopup

    //  setShowPopupNewCandidates(false);
    setCandidates(false);
    setShowPopupNewCandidates(true);
  };

  useEffect(() => {
    console.log('tytuututt', showPopupNewCandidates);
  }, [showPopupNewCandidates]);

  let personGroupedByAssign;
  let groupArray;
  const [group, setGroup] = useState();
  let groupNames;

  const [selectedCandidate, setSelectedCandidate] = useState();
  const [showCandidatePopUp, setShowCandidatePopUp] = useState(false);

  const handleCandidateClick = (candidate) => {
    setShowCandidatePopUp(false);
    setSelectedCandidate('');
    setSelectedCandidate(candidate);
    console.log('hello', selectedCandidate);
    setShowCandidatePopUp(true);
  };

  const closeCandidatePopUp = () => {
    setShowCandidatePopUp(false);
  };

  const candidateListSate = useSelector((state) => state.candidateList);
  const { candidateList, loading, error } = candidateListSate;
  const candidateListDispatch = useDispatch();

  useEffect(() => {
    candidateListDispatch(getAllCandidates());
  }, []);

  useEffect(() => {
    // if (effect.current === true) {
    effect.current = false;
    console.log('daytatayay', candidateList);
    console.log('candidateList');
    let newCandidatesList = candidateList
      ?.filter(function (candidates) {
        return candidates.jobStatus === 'New Candidates';
      })
      .map(function (candidate) {
        return {
          name: candidate.fullName,
          firstName:candidate?.firstName,
          lastName:candidate?.lastName,
          // img: candidate.photo,
          info: candidate,
        };
      });

    setNewCandidates(newCandidatesList);
    console.log('newCandidatesList', newCandidatesList);

    let onBeachList = candidateList
      ?.filter(function (candidates) {
        return candidates.jobStatus === 'On Bench';
      })
      .map(function (candidate) {
        return {
          firstName:candidate?.firstName,
          lastName:candidate?.lastName,
          name: candidate?.fullName,
          // img: candidate.photo,
          info: candidate,
        };
      });

    setOnBench(onBeachList);
    console.log('onBeachList', onBeachList);

    let completingProjectsList = candidateList
      ?.filter(function (candidates) {
        return candidates.jobStatus === 'Completing Projects';
      })
      .map(function (candidate) {
        return {
          name: candidate?.fullName,
          firstName:candidate?.firstName,
          lastName:candidate?.lastName,
          // img: candidate.img,
          info: candidate,
        };
      });

    setCompletingProjects(completingProjectsList);
    console.log('completingProjectsList', completingProjectsList);

    let placedCandidatesList = candidateList
      ?.filter(function (candidates) {
        return candidates.jobStatus === 'Placed Candidates';
      })
      .map(function (candidate) {
        return {
          name: candidate?.fullName,
          firstName:candidate?.firstName,
          lastName:candidate?.lastName,
          // img: candidate.img,
          info: candidate,
        };
      });

    setPlacedCandidates(placedCandidatesList);
    console.log('placedCandidatesList', placedCandidatesList);

    let ghji = data.candidates.reduce((r, a) => {
      console.log('a', a);
      console.log('r', r);
      r[a.assignto] = [...(r[a.assignto] || []), a];
      return r;
    }, {});

    setGroup(ghji);
    console.log('groupwert', group, ghji);

    // effect.current = false
    setIsLoading(false);
    // s console.log("isloading....",isLoading,effect)
    // }
  }, [candidateList]);

  useEffect(() => {
    console.log('candidateList', candidateList);
    console.log('newCandidates', newCandidates);
  }, [candidateList, newCandidates]);

  //Candidate Details PopUp Closed When click on Candidate set to false

  useEffect(() => {
    if (candidates == false) {
      closeCandidatePopUp();
    }
  }, [candidates]);
  const navigate = useNavigate();
  const navigatePage = (link) => {
    navigate(link);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div>
          <div className="breadcrumb">
            <div>
              <h1>CANDIDATES</h1>
              {/* <p>Candidates{candidates ? "" : " / Candidates' Distribution"}</p> */}
            </div>
            <div></div>
            <div>
              

              {/* <ButtonWithBG
                buttonName={"Distribute New Candidates"}
                boxBottomColor={"#FBBC05"}
                active={""}
                onClick={handlePopupToggle}
              /> */}
            </div>
          </div>

          <div className='flex-row-jBetween-aCenter'>
            <div>
          <ButtonWithoutBG
            buttonName={'Candidates'}
            boxBottomColor={'#FBBC05'}
            active={candidates ? '' : 'btnWoutBgActive'}
            onClick={() => setCandidates(true)}
          />
          <ButtonWithoutBG
            buttonName={"Candidates' Distribution"}
            boxBottomColor={'#FBBC05'}
            active={candidates ? 'btnWoutBgActive' : ''}
            onClick={() => setCandidates(false)}
          />
          </div>



          <ButtonWithBG
                buttonName={'Create Candidate  +'}
                active={' '}
                onClick={() => navigatePage('/candidateForm')}
          />
          </div>
          {/* <hr style={{ marginLeft: "-40px", width: "calc(100% + 87px)" }}></hr> */}
          <h2 className="totalPerson">
            Total Candidates : {candidateList?.length}
            {/* <span>{candidateList?.length}</span> */}
          </h2>

          {
            candidates ? (
              <div className="candidatesDiv">
                <div style={{ maxWidth: '240px' }}>
                  <CandidatesHeader
                    count={newCandidates?.length}
                    name={'New Candidates'}
                    color={'#FD841F'}
                  />
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#EAEAEA',
                      padding: '10px',
                      marginBottom: '20px',
                      borderRadius: '40px',
                      alignItems:"centre"
                    }}
                  >
                    <i class="fa fa-search"></i>
                    &nbsp;
                    <input
                      style={{
                        width: '-webkit-fill-available',
                        border: 'none',
                        backgroundColor: '#EAEAEA',
                        outline: "none"
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchNewCandidates}
                      onChange={(e) => setSearchNewCandidates(e.target.value)}
                    />
                  </div>
                  {newCandidates &&
                    newCandidates
                      .filter((person) =>
                        (person.name || person.firstName || person.lastName)
                          .toLowerCase()
                          .includes(searchNewCandidates.toLowerCase())
                      )
                      .map((person, index) => (
                        <Candidates
                          key={index}
                          name={person.name}
                          firstName={person.firstName}
                          lastName={person.lastName}
                          img={person.img || '/image/blank-profile.png'}
                          onClick={() => handleCandidateClick(person.info)}
                        />
                      ))}
                  {/* {newCandidates?.map((person, index) => (
                <Candidates
                  key={index}
                  name={person.name}
                  img={person.img ||  "/image/blank-profile.png"}
                  onClick={() => handleCandidateClick(person.info)}
                 
                />
              ))
              } */}
                </div>

                <div>
                  <CandidatesHeader
                    count={onBench?.length}
                    name={'On Bench'}
                    color={'#FD841F'}
                  />
                  {/* <input
                    style={{ width: '-webkit-fill-available' }}
                    type="text"
                    placeholder="Search"
                    value={searchOnBench}
                    onChange={(e) => setSearchOnBench(e.target.value)}
                  /> */}
                   <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#EAEAEA',
                      padding: '10px',
                      marginBottom: '20px',
                      borderRadius: '40px',
                      alignItems:"centre"
                    }}
                  >
                    <i class="fa fa-search"></i>
                    &nbsp;
                    <input
                      style={{
                        width: '-webkit-fill-available',
                        border: 'none',
                        backgroundColor: '#EAEAEA',
                        outline: "none"
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchOnBench}
                    onChange={(e) => setSearchOnBench(e.target.value)}
                    />
                  </div>
                  {onBench &&
                    onBench
                      .filter((person) =>
                      (person.name || person.firstName || person.lastName)
                          .toLowerCase()
                          .includes(searchOnBench.toLowerCase())
                      )
                      .map((person, index) => (
                        <Candidates
                          key={index}
                          name={person.name}
                          firstName={person.firstName}
                          lastName={person.lastName}
                          img={person.img || '/image/blank-profile.png'}
                          onClick={() => handleCandidateClick(person.info)}
                        />
                      ))}
                  {/* {onBench?.map((person, index) => (
                <Candidates
                  key={index}
                  name={person.name}
                  img={person.img ||  "/image/blank-profile.png"}
                  onClick={() => handleCandidateClick(person.info)}
                />
              ))
              } */}
                </div>

                <div>
                  <CandidatesHeader
                    count={completingProjects?.length}
                    name={'Completing Projects'}
                    color={'#FD841F'}
                  />

                  {/* <input
                    style={{width: "-webkit-fill-available"}}
                    type="text"
                    placeholder="Search"
                    value={searchCompletingProjects}
                    onChange={(e) => setSearchCompletingProjects(e.target.value)}
                  /> */}

<div
                    style={{
                      display: 'flex',
                      backgroundColor: '#EAEAEA',
                      padding: '10px',
                      marginBottom: '20px',
                      borderRadius: '40px',
                      alignItems:"centre"
                    }}
                  >
                    <i class="fa fa-search"></i>
                    &nbsp;
                    <input
                      style={{
                        width: '-webkit-fill-available',
                        border: 'none',
                        backgroundColor: '#EAEAEA',
                        outline: "none"
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchCompletingProjects}
                      onChange={(e) => setSearchCompletingProjects(e.target.value)}
                    />
                  </div>

                  {completingProjects &&
                    completingProjects
                      .filter((person) =>
                      (person.name || person.firstName || person.lastName)
                          .toLowerCase()
                          .includes(searchCompletingProjects.toLowerCase())
                      )
                      .map((person, index) => (
                        <Candidates
                          key={index}
                          name={person.name}
                          firstName={person.firstName}
                          lastName={person.lastName}
                          img={person.img || '/image/blank-profile.png'}
                          onClick={() => handleCandidateClick(person.info)}
                        />
                      ))}
                  {/* {completingProjects?.map((person, index) => (
                <Candidates
                  key={index}
                  name={person.name}
                  img={person.img ||  "/image/blank-profile.png"}
                  onClick={() => handleCandidateClick(person.info)}
                />
              ))
              } */}
                </div>

                <div>
                  <CandidatesHeader
                    count={placedCandidates?.length}
                    name={'Placed Candidates'}
                    color={'#FD841F'}
                  />

                  {/* <input
                    style={{ width: '-webkit-fill-available' }}
                    type="text"
                    placeholder="Search"
                    value={searchPlacedCandidates}
                    onChange={(e) => setSearchPlacedCandidates(e.target.value)}
                  /> */}
                  
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#EAEAEA',
                      padding: '10px',
                      marginBottom: '20px',
                      borderRadius: '40px',
                      alignItems:"centre"
                    }}
                  >
                    <i class="fa fa-search"></i>
                    &nbsp;
                    <input
                      style={{
                        width: '-webkit-fill-available',
                        border: 'none',
                        backgroundColor: '#EAEAEA',
                        outline: "none"
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchPlacedCandidates}
                    onChange={(e) => setSearchPlacedCandidates(e.target.value)}
                    />
                  </div>
                  {placedCandidates &&
                    placedCandidates
                      .filter((person) =>
                      (person.name || person.firstName || person.lastName)
                          .toLowerCase()
                          .includes(searchPlacedCandidates.toLowerCase())
                      )
                      .map((person, index) => (
                        <Candidates
                          key={index}
                          name={person.name}
                          firstName={person.firstName}
                          lastName={person.lastName}
                          img={person.img || '/image/blank-profile.png'}
                          onClick={() => handleCandidateClick(person.info)}
                        />
                      ))}
                  {/* {placedCandidates?.map((person, index) => (
                <Candidates
                  key={index}
                  name={person.name}
                  img={person.img ||  "/image/blank-profile.png"}
                  onClick={() => handleCandidateClick(person.info)}

                />
              ))
              } */}
                </div>
              </div>
            ) : (
              <DistributeCandidates />
            )

            // <DistributeCandidates candidates={data.candidates} showPopup={showPopupNewCandidates} setShowPopup={setShowPopupNewCandidates} />
          }
        </div>
        {showCandidatePopUp && (
          <CandidatePopUP
            initialData={selectedCandidate}
            onClose={closeCandidatePopUp}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default MainPage;
