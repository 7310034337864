import {

    CANDIDATE_CREATE_REQUEST,
    CANDIDATE_CREATE_SUCCESS,
    CANDIDATE_CREATE_FAIL,

    CANDIDATE_LIST_REQUEST,
    CANDIDATE_LIST_SUCCESS,
    CANDIDATE_LIST_FAIL,
    CANDIDATE_UPDATE_REQUEST,
    CANDIDATE_UPDATE_SUCCESS,
    CANDIDATE_UPDATE_FAIL,
    CANDIDATE_DELETE_REQUEST,
    CANDIDATE_DELETE_SUCCESS,
    CANDIDATE_DELETE_FAIL
  } from '../constants/constants';
  



  export const createCandidateReducers = (state = {}, action) => {
    switch (action.type) {
      case CANDIDATE_CREATE_REQUEST:
        return { loading: true };
      case CANDIDATE_CREATE_SUCCESS:
        return { loading: false, candidate: action.payload };
      case  CANDIDATE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  

  export const getAllCandidatesReducers = (state = {}, action) => {
    switch (action.type) {
      case CANDIDATE_LIST_REQUEST:
        return { loading: true };
      case CANDIDATE_LIST_SUCCESS:
        return { loading: false, candidateList: action.payload };
      case  CANDIDATE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const updateCandidateReducers = (state = {}, action) => {
    switch (action.type) {
      case CANDIDATE_UPDATE_REQUEST:
        return { loading: true };
      case CANDIDATE_UPDATE_SUCCESS:
        return { loading: false, updateCandidateInfo: action.payload };
      case  CANDIDATE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  export const deleteCandidateReducers = (state = {}, action) => {
    switch (action.type) {
      case CANDIDATE_DELETE_REQUEST:
        return { loading: true };
      case CANDIDATE_DELETE_SUCCESS:
        return { loading: false, deleteCandidateInfo: action.payload };
      case  CANDIDATE_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };