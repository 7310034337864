import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const AcademicDetails = (props) => {

  const { data, onClick } = props
  const [formData, setFormData] = useState(data);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    console.log("dataacedemic", data)
    setFormData(data)
  }, [])

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };


  useEffect(() => {
    console.log("fpormdata uuuuu", formData)

  }, [formData])
  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleSave = () => {
    setIsEditMode(false);
    // Save the form data or perform any other necessary actions
    onClick(formData)
    console.log("ecedvererer", formData);
  };

  const handleHighestQualificationChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      educationQualifications: {
        ...prevData.educationQualifications,
        highestQualification: value,
      },
    }));
  };
  const handleQualificationChange = (value, index) => {
    const updatedQualifications = [...formData.educationQualifications.qualifications];
    updatedQualifications[index].qualification = value;
    setFormData({
      ...formData,
      qualifications: updatedQualifications
    });
  };

  const handleResultUnitChange = (value, index) => {
    const updatedQualifications = [...formData.educationQualifications.qualifications];
    updatedQualifications[index].resultUnit = value;
    setFormData({
      ...formData,
      qualifications: updatedQualifications
    });
  };

  const handleInstituteChange = (value, index) => {
    const updatedQualifications = [...formData.educationQualifications.qualifications];
    updatedQualifications[index].institute = value;
    setFormData({
      ...formData,
      qualifications: updatedQualifications
    });
  };


  const handleTotalMarksChange = (value, index) => {
    const updatedQualifications = [...formData.educationQualifications.qualifications];
    updatedQualifications[index].totalMarks = value;
    setFormData({
      ...formData,
      qualifications: updatedQualifications
    });
  };
  const handleObtainedMarksChange = (value, index) => {
    const updatedQualifications = [...formData.educationQualifications.qualifications];
    updatedQualifications[index].obtainedMarks = value;
    setFormData({
      ...formData,
      qualifications: updatedQualifications
    });
  };

  return (
    <div style={{ border: "1px solid black" }}>
      <div className='flex-row-jBetween-aCenter ' style={{ borderBottom: "1px solid black", padding: "20px" }}>
        <h2>Academic Details</h2>
        {isEditMode ? (
          <button type="button" onClick={handleSave} className='edit-save-button'>
            Save
          </button>
        ) : (
          <button type="button" onClick={handleEdit} className='edit-save-button'>
            <span class="material-symbols-outlined">
              edit
            </span> Edit Information
          </button>
        )}
      </div>
      <div className='form'>

        <label>
          Highest Qualification:
          {/* <input
            type="text"
            name="highestQualification"
            value={formData.educationQualifications.highestQualification}
            onChange={handleInputChange}
            disabled={!isEditMode}
            className={isEditMode ? "" : "input-wout-border"}

          /> */}
          <select
            disabled={!isEditMode}
            value={formData.educationQualifications.highestQualification}
            className={isEditMode ? "" : "input-wout-border"}
            onChange={handleHighestQualificationChange}
            required
          >
            { !isEditMode ? <option value={formData.educationQualifications.highestQualification}>{formData.educationQualifications.highestQualification}</option> :(
              <>
            <option value="">Select Highest Qualification</option>
            <option value="PhD">PhD</option>
            <option value="Master's Degree">Master's Degree</option>
            <option value="Bachelor's Degree">Bachelor's Degree</option>
            <option value="Diploma">Diploma</option>
            <option value="High School">High School</option>
            <option value="other">Other</option>
            </>
            )}
          </select>
        </label>


        {/* Render input fields for each qualification */}
        {formData.educationQualifications.qualifications.map((qualification, index) => (
          <div key={index}>
            <h3>Qualification {index + 1}</h3>



            <label>
              Qualification:
              <select
                disabled={!isEditMode}
                value={qualification.qualification}
                onChange={(e) => handleQualificationChange(e.target.value, index)}
                className={isEditMode ? "" : "input-wout-border"}
                required
              >

                
                <option value="">Select Qualification</option>
                <option value="PhD">PhD</option>
                <option value="Master's Degree">Master's Degree</option>
                <option value="Bachelor's Degree">Bachelor's Degree</option>
                <option value="Diploma">Diploma</option>
                <option value="High School">High School</option>
                <option value="other">Other</option>
              </select>
            </label>
            <div className='flex-row-between' style={{ gap: "40px" }}>
              <div>
                <label>
                  Institute:
                  <input
                    type="text"
                    name={`qualifications[${index}].institute`}
                    value={qualification.institute}
                    onChange={(e) => handleInstituteChange(e.target.value, index)}
                    disabled={!isEditMode}
                    className={isEditMode ? "" : "input-wout-border"}

                  />
                </label>

                <label>
                  Result Unit:
                  {/* <input
                    type="text"
                    name={`qualifications[${index}].resultUnit`}
                    value={qualification.resultUnit}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                    className={isEditMode ? "" : "input-wout-border"}

                  /> */}
                  <select
                    value={qualification.resultUnit}
                    onChange={(e) => handleResultUnitChange(e.target.value, index)}
                    disabled={!isEditMode}
                    className={isEditMode ? "" : "input-wout-border"}
                    required
                  >
                    <option value="">Select Unit</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Grade">Grade</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  Total Marks:
                  <input
                    type="text"
                    name={`qualifications[${index}].totalMarks`}
                    value={qualification.totalMarks}
                    onChange={(e) => handleTotalMarksChange(e.target.value, index)}
                    disabled={!isEditMode}
                    className={isEditMode ? "" : "input-wout-border"}

                  />
                </label>

                <label>
                  Obtained Marks:
                  <input
                    type="text"
                    name={`qualifications[${index}].obtainedMarks`}
                    value={qualification.obtainedMarks}
                    onChange={(e) => handleObtainedMarksChange(e.target.value, index)}
                    // onChange={handleInputChange}
                    disabled={!isEditMode}
                    className={isEditMode ? "" : "input-wout-border"}

                  />
                </label>
              </div>
            </div>
          </div>
        ))}



      </div>
    </div>
  )
}

export default AcademicDetails