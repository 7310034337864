import React from 'react';

const SearchInput = ({
  style = {},
  placeholder = 'Search',
  value,
  onChange,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#EAEAEA',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '40px',
        alignItems: 'center',
        ...style,
      }}
    >
      <i className="fa fa-search"></i>
      &nbsp;
      <input
        style={{
          width: '-webkit-fill-available',
          border: 'none',
          backgroundColor: '#EAEAEA',
          outline: 'none',
          ...style.input, // Additional input styles
        }}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
