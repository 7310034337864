import React, { useState } from 'react';
import ButtonWithBG from '../../components/Buttons/ButtonWithBG';
import { useNavigate, useParams } from 'react-router-dom';
import data from '../../data/data';
import { useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeById } from '../../action.js/employeeAction';

const EditEmployee = () => {
    const navigatePage = useNavigate()
    const { employeeId } = useParams(); // Assuming you have the employeeId as a route parameter
    
    const [formData, setFormData] = useState({
        fullName: '',
        firstName:'',
        lastName:'',
        // userId: '',
        dob: '',
        reportingTo: '',
        designation: '',
        emailAddress: '',
        contactNumber: '',
        // password: '',
        // confirmPassword: '',
        profilePicture: null,
      });
      const [uploadedImage, setUploadedImage] = useState(null);
    
      const getEmployeeByIdSelector = useSelector((state)=>state.getEmployeeById);
      const {employeeInfo , loading , error} = getEmployeeByIdSelector
      const getEmployeeByIdDispatch = useDispatch()

      useEffect(() => {
        // Fetch employee data based on employeeId when editing an existing employee
        if (employeeId) {
          // Perform API call or data retrieval logic here
          getEmployeeByIdDispatch(getEmployeeById(employeeId));
        }
      }, [employeeId, getEmployeeByIdDispatch]);
    
      useEffect(() => {
        // Update the formData state when employeeInfo changes
        if (employeeInfo) {
          setFormData({
            firstName: employeeInfo.firstName || '',
            dob: employeeInfo.dob || '',
            designation: employeeInfo.designation || '',
            emailAddress: employeeInfo.email || '',
            contactNumber: employeeInfo.contactNumber || '',
            profilePicture: employeeInfo.profilePicture || null,
          });
        }
      }, [employeeInfo]);


      // useEffect(() => {
      //   // Fetch employee data based on employeeId when editing an existing employee
      //   if (employeeId) {
      //     // Perform API call or data retrieval logic here
      //     // Set the fetched employee data to the formData state
      //     getEmployeeByIdDispatch(getEmployeeById(employeeId))
      //       // const fetchedEmployeeData = data.employees.find(employee => employee.id === employeeId);
      //    // Fetch the employee data based on employeeId
      //     // setFormData(fetchedEmployeeData);
      //     // setUploadedImage(fetchedEmployeeData.profilePicture);
      //   }
      // }, [employeeId]);

      // useEffect(()=>{
      //   setFormData(employeeInfo)
      // },[employeeInfo])

      // useEffect(()=>{
      //       console.log("formdata000000000000000000000000",employeeInfo)
      // },[employeeInfo])
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
          ...prevData,
          profilePicture: file,
        }));
        setUploadedImage(URL.createObjectURL(file));
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        // Perform form submission logic or API call here
        console.log(formData);
        alert("Form Submitted")
        navigatePage("/employeePage")
      };
    
      // const passwordMatch = formData.password === formData.confirmPassword;
      const isFormValid =
        formData.firstName &&
        formData.userId &&
        formData.dob &&
        formData.reportingTo &&
        formData.designation &&
        formData.emailAddress &&
        formData.contactNumber 
        // formData.password &&
        // formData.confirmPassword &&
        // passwordMatch;
    


  return (


    <div className="employees" >
    <div className='breadcrumb'>
        <div>
            <h1>EMPLOYEES</h1>
              {/* <p>Employees{employees ? "" : " / Candidates' Distribution"}</p>  */}
            <p>Employees / Edit Employee</p>
        </div>
        <div>


        </div>
         {/*
        <div>
            <ButtonWithBG
                buttonName={"Edit Employee"}
                active={"buttonWithBgActive"}
             onClick={()=>navigatePage('/addNewEmployee')}
            />

            <ButtonWithBG
                buttonName={"Schedule New Interview"}
                boxBottomColor={"#FBBC05"}
                active={""}
            />
        </div>*/}
    </div> 
      
      <form onSubmit={handleSubmit} className='flex-column' autoComplete="off"  >
        <div className='flex-column' style={{gap:"40px"}}>
        <div className='flex-row'>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName || formData.fullName }
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Last Name
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Date of Birth:
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        {/* <label>
          Reporting To:
          <input
            type="text"
            name="reportingTo"
            value={formData.reportingTo}
            onChange={handleInputChange}
            required
          />
        </label> */}
        <br />
        </div>
        <div className='flex-row'>
        <label>
          Designation:
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Email Address (Official):
          <input
            type="email"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Contact Number:
          <input
            type="tel"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        {/* <label>
          Create Password:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
          />
        </label>
        {!passwordMatch && (
          <p style={{ color: 'red' }}>Passwords do not match.</p>
        )} */}
        <br />
        </div>

        <div className='flex-column'>
        {uploadedImage ? (
          <img src={uploadedImage} alt="Uploaded Profile" width="118px"
          height="125px" />
        ): <img src="/image/blank-profile.png" alt="Uploaded Profile" width="118px"
        height="125px" />}
        <input type="file" accept="image/*" onChange={handleFileUpload} style={{borderStyle:"none"}}/>
        
        </div>
        <br />
        <button type="submit" disabled={!isFormValid} className='save-button' style={{marginTop:"none"}}>
          Submit
        </button>
        </div>

        
      </form>
    </div>
   
  );
};

export default EditEmployee;
