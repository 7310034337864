 import React from 'react'
import ButtonWithBG from '../../components/Buttons/ButtonWithBG'
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG'
import data from '../../data/data';
import { useState } from 'react'
import './submission.css'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SubmissionDetails from './SubmissionPopUp/SubmissionDetails';
import SubmissionPopUp from './SubmissionPopUp/SubmissionPopUp';
import CreateSubmission from './CreateSubmission'
import { useDispatch, useSelector } from 'react-redux';
import { deleteSpecificSubmissionFromSubmissionArray, getAllSubmissions, getSubmissionsById } from '../../action.js/submissionAction';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Pagination from '../../components/Pagination/Pagination';
import { useMemo } from 'react';
import SearchInput from '../../components/SearchInput/SearchInput';

const SubmissionPage = () => {

  const [candidate, setCandidate] = useState(true)
  const navigatePage = useNavigate()
  const [showCandidatePopUp, setShowCandidatePopUp] = useState(false)
  const [selectedSubmissionIndex, setSelectedSubmissionIndex] = useState(null);
  const [id, setId] = useState()
  const [totalRow, setTotalRow] = useState(0);  // const [candidateData, setCandidateData] = useState([])
  const [selectedOption, setSelectedOption] = useState()




  const [candidateData, setCandidateData] = useState([]);

  useEffect(() => {
    if (candidateData.length === 0) {
      console.log(data.submissionData);
      const only = data.submissionData;
      setCandidateData(only);
      console.log("given data", candidateData);
    


    }
  }, [candidateData, data.submissionData]);

  useEffect(
    () => {
      console.log("candidateData", candidateData)
    }, [candidateData]
  )
  // const [candidateData, setcandidate] = useState()
  const closeCandidatePopUp = () => {
    setShowCandidatePopUp(false);
  };



  const  getSubmissionByIdDispatch = useDispatch()
    const getSubmissionByIdSelector = useSelector((state)=>state.getSubmissionById)
    const {submissionById , loading: submissionByIdLoading , error: submissionByIdError} = getSubmissionByIdSelector

  const deleteArraySubmission = useDispatch()
  const getSubmissionById=async(id)=>{
    getSubmissionByIdDispatch(getSubmissionsById(id));
}

useEffect(()=>{
  setSelectedOption(submissionById);
  console.log("selecsubmissionByIdtedOption==========", submissionById)

},[submissionById])
  const handleDelete = async(submissionId, candidateId) => {
    
    console.log("submissionId",submissionId,"candidateId",candidateId)
    await getSubmissionById(candidateId)
    deleteArraySubmission(deleteSpecificSubmissionFromSubmissionArray(selectedOption,submissionId))

  }

  

  const handleEdit = (index, id) => {
    console.log("idhnnjjjk", id)
    setSelectedSubmissionIndex(index)
    setId(id)
    setShowCandidatePopUp(true);
    // navigatePage(`/editCandidate/${candidateId}`);
  };


  const getAllSubmissionDispatch = useDispatch()
  const getAllSubmissionSelector = useSelector((state) => state.getAllSubmission)
  const { submissionList, loading: employeeLoading, error: employeeError } = getAllSubmissionSelector
  useEffect(() => {
    console.log("hit firsttime")
    getAllSubmissionDispatch(getAllSubmissions())
    console.log("employeesInfo................", submissionList)
    let count = 0;

    submissionList?.forEach((candidates) => {
      count += candidates?.submission.length;
    });

    setTotalRow(count);
   
  }, [])

    // For pagination
    const [currentPage, setCurrentPage] = useState(1);
    let PageSize = 2;
    const currentTableData = useMemo(() => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      
      // Slice the data within the nested map over submissionList
      return submissionList?.map((candidates, index) => ({
        ...candidates,
        submission: candidates.submission.slice(firstPageIndex, lastPageIndex),
      }));
    }, [currentPage, submissionList]);
  
    

 // Step 1: Add filter state for each column
 const [nameFilter, setNameFilter] = useState('');
 const [submittedByFilter, setSubmittedByFilter] = useState('');
 const [statusFilter, setStatusFilter] = useState('');
 const [jobSourceTypeFilter, setJobSourceTypeFilter] = useState('');
 const [jobSourceNameFilter, setJobSourceNameFilter] = useState('');
 const [endClientFilter, setEndClientFilter] = useState('');
 // Add filters for other columns as needed

 // Step 3: Apply the filters when rendering the table rows
//  const filteredSubmissionList = submissionList?.filter((candidates) => {
//    return candidates?.submission?.some((candidate) =>
//       candidates?.candidateId?.fullName.toLowerCase().includes(nameFilter?.toLowerCase())&&
//      candidate?.submittedBy?.fullName.toLowerCase().includes(submittedByFilter?.toLowerCase()) &&
//      candidate?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
//      candidate?.jobSourceType?.toLowerCase().includes(jobSourceTypeFilter?.toLowerCase()) &&
//      candidate?.jobSourceName?.toLowerCase().includes(jobSourceNameFilter?.toLowerCase()) &&
//      candidate?.endClient?.toLowerCase().includes(endClientFilter?.toLowerCase())
//    );
//  });
const filteredSubmissionList = submissionList?.filter((candidates) => {
  const matchingSubmissions = candidates?.submission?.filter((candidate) => {
    return (
      (!statusFilter || candidate?.status?.toLowerCase().includes(statusFilter.toLowerCase())) &&
      (!jobSourceTypeFilter || candidate?.jobSourceType?.toLowerCase().includes(jobSourceTypeFilter.toLowerCase())) &&
      (!jobSourceNameFilter || candidate?.jobSourceName?.toLowerCase().includes(jobSourceNameFilter.toLowerCase())) &&
      (!endClientFilter || candidate?.endClient?.toLowerCase().includes(endClientFilter.toLowerCase())) &&
      (!nameFilter || (candidates?.candidateId?.fullName || candidates?.candidateId?.firstName).toLowerCase().includes(nameFilter.toLowerCase())) &&
      (!submittedByFilter || (candidate?.submittedBy?.fullName || candidate?.submittedBy?.firstName).toLowerCase().includes(submittedByFilter.toLowerCase()))
    );
  });

  // If there are matching submissions, add them to the candidate
  if (matchingSubmissions && matchingSubmissions.length > 0) {
    return {
      ...candidates,
      submission: matchingSubmissions,
    };
  } else {
    // If no matching submissions for this candidate, don't include the candidate
    return null;
  }
});

//  // If no filter criteria are applied, return the entire submissionList
//  const displayedSubmissionList = nameFilter || submittedByFilter || statusFilter || jobSourceTypeFilter || jobSourceNameFilter || endClientFilter
//    ? filteredSubmissionList
//    : submissionList;

   useEffect(() => {
      console.log("statusFilter",statusFilter)
      // console.log("displayedSubmissionList",displayedSubmissionList)
   }, [statusFilter])
   
  return (
    // <div className='container' >
    // <Sidebar />
    // <div className='HeaderMainPage'>
    //   <Header />
    //   <div className='main'>

    <div className="candidates" >
      <div className='breadcrumb'>
        <div>
          <h1>SUBMISSION</h1>
          {/* <p>candidates{candidates ? "" : " / Candidates' Distribution"}</p> */}
          <p>Submission</p>
        </div>
        <div>


        </div>
        <div>
          {/* <ButtonWithBG
                        buttonName={"Create Candidate"}
                        active={" "}
                        onClick={() => navigatePage('/addNewCandidate')}
                    /> */}

          {/* <ButtonWithBG
            buttonName={"Create New Submission"}
            boxBottomColor={"#FBBC05"}
            active={""}
            onClick={() => navigatePage('/createSubmission')}
          /> */}
        </div>
      </div>



      <ButtonWithoutBG
        buttonName={"Submission"}
        boxBottomColor={"#FBBC05"}
        active={candidate ? "" : "btnWoutBgActive"}
        
        onClick={() => setCandidate(true)}
      />
      <ButtonWithoutBG
                buttonName={"Schedule New Submission"}
                boxBottomColor={"#FBBC05"}
                active={candidate ? "btnWoutBgActive" : ""}
                onClick={() => setCandidate(false)}
             />
      {/* <hr style={{ marginLeft: "-40px", width: "calc(100% + 87px)" }}></hr> */}
      {/* <h2 className='totalPerson'>Total Candidate : <span>{totalRow}</span></h2> */}
      {/* <table className="candidate-table">
                <thead>
                    <tr>
                        <th>Candidates Name</th>
                        <th>Submitted By:</th>
                        <th>Status</th>
                        <th>Job Source Type</th>
                        <th>Job Source Name</th>
                        <th>End Clients</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {candidateData?.map((candidates, index) => (
                        <>
                        {candidates?.submissions.map((candidate, index)=>(
                        <tr key={index} style={{ marginBottom: "300px" }}>
                            <td >
                                <div className='flex-row' style={{ gap: "10px", alignItems: "center" }}>
                                    <div>
                                        <img
                                            src={candidate.photo}
                                            height="40px"
                                            width="40px"
                                            style={{ borderRadius: '50%' }}
                                            alt=""
                                        />
                                    </div>
                                    <div>{candidate.name}</div>
                                </div>
                            </td>
                            <td>{candidate.submittedBy}</td>
                            <td>{candidate.status}</td>
                            <td>{candidate.jobSourceType}</td>
                            <td>{candidate.jobSourceName}</td>
                            <td>{candidate.endClient}</td>
                            <td className='flex-column' style={{ alignItems: "center", gap: "10px" }}>
                                <span class="material-symbols-outlined" style={{ cursor: "pointer" }} onClick={() => handleEdit(candidate.id)}
                                >
                                    edit_note
                                </span>
                                   <div  >
                                    <span class="material-symbols-outlined" onClick={() => handleDelete(index)} style={{ cursor: "pointer" }}>
                                        delete
                                    </span>
                                </div>
                            </td>
                        </tr>
                        ))}
                        </>
                    ))}
                </tbody>
            </table> */}

            {candidate ? <>
              <h2 className='totalPerson'>Total Candidate : <span>{totalRow}</span></h2>
      <table className="candidate-table">
        <thead>
          <tr>
            <th>Candidate's Name
            <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
      />
            {/* <input
            type="text"
            placeholder="Filter by Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          /> */}
            </th>
            <th>Submitted By
            <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Submitted By"
        value={submittedByFilter}
        onChange={(e) => setSubmittedByFilter(e.target.value)}
      />
              
            {/* <input
            type="text"
            placeholder="Filter by Submitted By"
            value={submittedByFilter}
            onChange={(e) => setSubmittedByFilter(e.target.value)}
          /> */}
            </th>
            <th>Status

            <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Status"
        value={statusFilter}
        onChange={(e) => setStatusFilter(e.target.value)}
      />
            {/* <input
            type="text"
            placeholder="Filter by Status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          /> */}
            </th>
            <th>Job Source Type
            <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Job Source Type"
        value={jobSourceTypeFilter}
        onChange={(e) => setJobSourceTypeFilter(e.target.value)}
      />
            {/* <input
            type="text"
            placeholder="Filter by Job Source Type"
            value={jobSourceTypeFilter}
            onChange={(e) => setJobSourceTypeFilter(e.target.value)}
          /> */}
            </th>
            <th>Job Source Name
            <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Job Source Name"
        value={jobSourceNameFilter}
        onChange={(e) => setJobSourceNameFilter(e.target.value)}
      />
            {/* <input
            type="text"
            placeholder="Filter by Job Source Name"
            value={jobSourceNameFilter}
            onChange={(e) => setJobSourceNameFilter(e.target.value)}
          /> */}
            </th>
            <th>End Clients
            <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by End Clients"
            value={endClientFilter}
            onChange={(e) => setEndClientFilter(e.target.value)}
      />
            {/* <input
            type="text"
            placeholder="Filter by End Clients"
            value={endClientFilter}
            onChange={(e) => setEndClientFilter(e.target.value)}
          /> */}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubmissionList ?.map((candidates, index) => (
            <React.Fragment key={index}>

              {candidates?.submission.map((candidate, subIndex) => (
                <tr key={subIndex} style={{ marginBottom: "300px" }}>
                  <td>
                    <div className="flex-row" style={{ gap: "10px", alignItems: "center" }}>
                      <div>
                        <img
                          src={candidates?.photo || "/image/blank-profile.png"}
                          height="40px"
                          width="40px"
                          style={{ borderRadius: '50%' }}
                          alt=""
                        />
                      </div>
                      <div>{candidates?.candidateId?.fullName || `${candidates?.candidateId?.firstName} ${candidates?.candidateId?.lastName}`} </div>
                    </div>
                  </td>
                  <td>{candidate?.submittedBy?.fullName || `${candidate?.submittedBy?.firstName} ${candidate?.submittedBy?.lastName}`}</td>
                  <td>{candidate?.status}</td>
                  <td>{candidate?.jobSourceType}</td>
                  <td>{candidate?.jobSourceName}</td>
                  <td>{candidate?.endClient}</td>
                  <td className="flex-row" style={{ alignItems: "center", gap: "10px" }}>
                    <span
                      className="material-symbols-outlined"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEdit(subIndex, candidates?.candidateId?._id)}
                    // onClick={() => setSelectedCandidateIndex(index)}

                    >
                      edit_note
                    </span>
                    <div >
                      <span
                        className="material-symbols-outlined"
                        onClick={() => handleDelete(candidate._id,candidates.candidateId._id)}
                        style={{ cursor: "pointer" }}
                      >
                        delete
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      </> : <CreateSubmission/> }
      {/* <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalRow}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      /> */}
      {showCandidatePopUp && <SubmissionPopUp submissionIndex={selectedSubmissionIndex} id={id} onClose={closeCandidatePopUp} />}


    </div>
    // </div>
    // </div>
    // </div>
  )
}

export default SubmissionPage