import { applyMiddleware, combineReducers } from 'redux';
import { configureStore, createImmutableStateInvariantMiddleware } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import { createEmployeeReducers, employeeSigninReducer, getAllEmployeeReducers, getEmployeeByIdReducers , getCurrentUserReducers, deleteEmployeeByIdReducers} from './reducer.js/employeeReducers';
import { getCompanyIdReducers } from './reducer.js/companyReducers';
import { createCandidateReducers, deleteCandidateReducers, getAllCandidatesReducers, updateCandidateReducers } from './reducer.js/candidateReducer';
import { createEmployeePermisionReducers, getOneEmployeePermisionReducers } from './reducer.js/employeePermissionReducer';
import { createSubmissionReducers, deleteSubmissionReducers, getAllSubmissionReducers, getSubmissionByIdReducers, updateSubmissionReducers } from './reducer.js/submissionReducer';
import { createInterviewReducers, updateInterviewReducers } from './reducer.js/interviewReducer';
import { EMPLOYEE_SIGNIN_SUCCESS } from './constants/constants';
import { bulkEmailReducers } from './reducer.js/bulkEmailReducers';
import jwtDecode from 'jwt-decode';
import { validateTokenReducer } from './reducer.js/validateTokenReducer';

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
})

const middleware = [thunk,immutableInvariantMiddleware];

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  updateInterview: updateInterviewReducers,
  updateCandidate: updateCandidateReducers,
  getEmployeeById:getEmployeeByIdReducers,
  validateToken: validateTokenReducer,
  employeeSignin: employeeSigninReducer,
  getCompanyId: getCompanyIdReducers,
  createCandidate: createCandidateReducers,
  candidateList: getAllCandidatesReducers,
  createEmployee: createEmployeeReducers,
  getAllEmployee: getAllEmployeeReducers,
  createEmployeePermision: createEmployeePermisionReducers,
  createSubmission : createSubmissionReducers,
  updateSubmission : updateSubmissionReducers,
  getAllSubmission : getAllSubmissionReducers,
  getSubmissionById : getSubmissionByIdReducers,
  createInterview : createInterviewReducers,
  getOneEmployeePermision : getOneEmployeePermisionReducers,
  getCurrentUser:getCurrentUserReducers,
  bulkEmail:bulkEmailReducers,
  deleteCandidate:deleteCandidateReducers,
  deleteSubmission:deleteSubmissionReducers,
  deleteEmployeeById:deleteEmployeeByIdReducers,
})


const initialState = {

  employeeSignin:{
    employeeInfoData: localStorage.getItem('rekrootyLogInToken')? JSON.parse(localStorage.getItem('rekrootyLogInToken')):null
  },

};

// Fetch the token from local storage
// const storedToken = localStorage.getItem('rekrootyLogInToken');
// const intialState = {
//   employeeSignin: {
//     employeeInfoData: storedToken ? JSON.parse(storedToken) : null
//   },
//   // ... Other initial state properties
// };
// Retrieve data from localStorage

const storedEmployeeInfoData = localStorage.getItem('rekrootyLogInToken');

const store = configureStore(
  { reducer: rootReducer },
  initialState,
  applyMiddleware(...middleware)
);

if (storedEmployeeInfoData) {
  // Dispatch the action to set the data in the store
  store.dispatch({
    type: EMPLOYEE_SIGNIN_SUCCESS,
    payload: JSON.parse(storedEmployeeInfoData),
  });
}


// const storedEmployeeInfoData = localStorage.getItem('rekrootyLogInToken');
// if (storedEmployeeInfoData) {
//   try {
//     const parsedData = JSON.parse(storedEmployeeInfoData);
//     if (parsedData && isValidToken(parsedData.token)) {
//       store.dispatch({
//         type: EMPLOYEE_SIGNIN_SUCCESS,
//         payload: parsedData,
//       });
//     } else {
//       redirectToLoginPage();
//     }
//   } catch (error) {
//     console.error('Error parsing stored data:', error);
//     redirectToLoginPage();
//   }
// }

// function isValidToken(token) {
//   try {
//     const decodedToken = jwtDecode(token);
//     const currentTime = Date.now() / 1000;
//     return decodedToken.exp > currentTime;
//   } catch (error) {
//     return false;
//   }
// }

// function redirectToLoginPage() {
//   // Implement your redirection logic here
//   // For example, use React Router to navigate to the login page
//   // You can also use window.location to navigate
//   console.log('Redirecting to login page...');
// }

// console.log("Initial State:", store.getState());
// // Log the initial state once the stored token is processed
// store.subscribe(() => {
//   console.log("Initial State:22", store.getState());
// });

export default store;
