import React, { useState, useEffect } from 'react';

function AcademicDetails(props) {
  const { onPrevious, onNext, data, onChange } = props;
  const [highestQualification, setHighestQualification] = useState('');
  const [qualifications, setQualifications] = useState(data);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const isQualificationFormEmpty = qualifications.qualifications.some(
      (qualification) =>
        Object.values(qualification).some((value) => value === '')
    );
    if (isQualificationFormEmpty) {
      console.log("Empty AcademicDetails")
    }
    else {
      setTableData(qualifications.qualifications)
    }

  }, [])



  const handleHighestQualificationChange = (event) => {
    const selectedOption = event.target.value;
    setQualifications((prevQualifications) => ({
      ...prevQualifications,
      highestQualification: selectedOption,
    }));
    data.highestQualification = selectedOption;

  };

  const handleQualificationChange = (value, index) => {
    const updatedQualifications = [...qualifications.qualifications];
    updatedQualifications[index].qualification = value;
    setQualifications({
      ...qualifications,
      qualifications: updatedQualifications
    });
  };

  const handleInstituteChange = (value, index) => {
    const updatedQualifications = [...qualifications.qualifications];
    updatedQualifications[index].institute = value;
    setQualifications({
      ...qualifications,
      qualifications: updatedQualifications
    });
  };

  const handleResultUnitChange = (value, index) => {
    const updatedQualifications = [...qualifications.qualifications];
    updatedQualifications[index].resultUnit = value;
    setQualifications({
      ...qualifications,
      qualifications: updatedQualifications
    });
  };

  const handleTotalMarksChange = (value, index) => {
    const updatedQualifications = [...qualifications.qualifications];
    updatedQualifications[index].totalMarks = value;
    setQualifications({
      ...qualifications,
      qualifications: updatedQualifications
    });
  };

  const handleObtainedMarksChange = (value, index) => {
    const updatedQualifications = [...qualifications.qualifications];
    updatedQualifications[index].obtainedMarks = value;
    setQualifications({
      ...qualifications,
      qualifications: updatedQualifications
    });
  };


  const handleSave = () => {
    // const isQualificationFormEmpty = qualifications.qualifications.some(
    //   (qualification) =>
    //     Object.values(qualification).some((value) => value === '')
    // );

    const isQualificationFormEmpty = qualifications.qualifications.every(
      (qualification) =>
        qualification.qualification === '' && qualification.institute === ''
    );

    if (isQualificationFormEmpty) {
      alert('Please fill all fields');
    } else {
      const newdata = { ...qualifications };
      setQualifications(newdata);
      onChange(qualifications);
      setTableData(qualifications.qualifications);
    }
  };

  const handleAddQualification = () => {
    setQualifications((prevQualification) => ({
      ...prevQualification,
      qualifications: [
        ...prevQualification.qualifications,
        {
          qualification: '',
          institute: '',
          resultUnit: '',
          totalMarks: '',
          obtainedMarks: '',
        },
      ],
    }));
  };



  const handleRemove = (index) => {
    const updatedQualifications = qualifications.qualifications.filter((_, i) => i !== index);
    setQualifications((prevQualifications) => ({
      ...prevQualifications,
      qualifications: updatedQualifications,
    }));
    setTableData(updatedQualifications);
  };



  return (
    <div>
      {/* <h3>Academic Details</h3> */}
      <div style={{ margin: '30px' }}>
        {/* Highest Qualification */}
        <label className='box-shadow'>
          Select Highest Qualification<br />
          <select

            value={qualifications.highestQualification}
            onChange={handleHighestQualificationChange}
            required
          >
            <option value="">Select Highest Qualification</option>
            <option value="PhD">PhD</option>
            <option value="Master's Degree">Master's Degree</option>
            <option value="Bachelor's Degree">Bachelor's Degree</option>
            <option value="Diploma">Diploma</option>
            <option value="High School">High School</option>
            <option value="other">Other</option>
          </select>
        </label>

        {/* Qualifications */}
        {qualifications.qualifications.map((qualification, index) => (
          <div key={index} className='box-shadow' >
            {/* Qualification */}
            <div className="flex-row" style={{ alignItems: "center" }}>
              <label>
                Qualification
                <select
                  value={qualification.qualification}
                  onChange={(e) => handleQualificationChange(e.target.value, index)}
                  required
                >
                  <option value="">Select Qualification</option>
                  <option value="PhD">PhD</option>
                  <option value="Master's Degree">Master's Degree</option>
                  <option value="Bachelor's Degree">Bachelor's Degree</option>
                  <option value="Diploma">Diploma</option>
                  <option value="High School">High School</option>
                  <option value="other">Other</option>
                </select>
              </label>

              <label>
                University / School / College
                <input
                  type="text"
                  value={qualification.institute}
                  onChange={(e) => handleInstituteChange(e.target.value, index)}
                  placeholder="Institute"
                  required
                />
              </label>

              <button onClick={() => handleRemove(index)} className='remove-button'>Remove</button>

            </div>

            <div className="flex-row" style={{ alignItems: "center" }}>
              <label >
                Result Unit
                <select
                  value={qualification.resultUnit}
                  onChange={(e) => handleResultUnitChange(e.target.value, index)}

                >
                  <option value="">Select Unit</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Grade">Grade</option>
                </select>
              </label>

              <label>
                Total Marks
                <input
                  type="number"
                  value={qualification.totalMarks}
                  onChange={(e) => handleTotalMarksChange(e.target.value, index)}
                  placeholder="Total Marks"

                />
              </label>

              <label>
                Obtained Marks
                <input
                  type="number"
                  value={qualification.obtainedMarks}
                  onChange={(e) => handleObtainedMarksChange(e.target.value, index)}
                  placeholder="Obtained Marks"
                />
              </label>
            </div>

          </div>
        ))}

        <button onClick={handleAddQualification} className='add-State ' style={{ marginBottom: "30px" }}>+ Add More Qualification</button><br />

        {/* Save Button */}
        <button onClick={handleSave} className="save-button">
          Save
        </button>

        {/* Qualifications Table */}
        <table style={{ width: "100%", marginTop: "30px" }}>
          <thead>
            <tr>
              <th>Qualification</th>
              <th>Institute</th>
              <th>Result Unit</th>
              <th>Total Marks</th>
              <th>Obtained Marks</th>
            </tr>
          </thead>
          <tbody>
            {
              tableData.map((data, index) => (
                <tr key={index}>
                  <td>{data.qualification}</td>
                  <td>{data.institute}</td>
                  <td>{data.resultUnit}</td>
                  <td>{data.totalMarks}</td>
                  <td>{data.obtainedMarks}</td>
                </tr>

              ))}
          </tbody>
        </table>

        {/* Add More Qualification Button */}

        <div className="flex-row-between">
          <button onClick={onPrevious} className="next-button">
            <span className="material-symbols-outlined">
              keyboard_double_arrow_left
            </span>
            Previous
          </button>
          <button onClick={onNext} className="next-button">
            Next
            <span className="material-symbols-outlined">
              keyboard_double_arrow_right
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AcademicDetails