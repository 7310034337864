import React, { useState,useEffect } from 'react';
import { Country, State, City } from 'country-state-city';


function BasicDetails(props) {



    const{onNext1,data,onChange}=props;
    const[currentAddressEdited, setCurrentAddressEdited] = useState(false);
    const [formData, setFormData] = useState(data)
    const [uploadedImage, setUploadedImage] = useState(null);




    function convertToBase64(file){
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result)
        };
        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    }

     const handlePhotoUpload = async (event) => {
      //  const file = event.target.files[0];
     const file = event.target.files[0];
     const base64 = await convertToBase64(file);
        const updatedData= {
          ...formData,
           photo: base64
          // updatedData:file
        }
        
        setFormData(updatedData);
        setUploadedImage( URL.createObjectURL(file))
        onChange(updatedData);
        
    };
    
  
  
    
    const handleChange= async(event)=>{
      const { name, value, type, checked } = event.target;
  const newValue = type === 'checkbox' ? checked : value;
       // Disable the "Visa Validity" input field if "USC" is selected
       const visaSelect = document.querySelector('select[name="visa"]');
       const visaValidityInput = document.querySelector('input[name="visaValidity"]');
     
       if (value === 'USC') {
        visaValidityInput.disabled = true;
      } else {
        visaValidityInput.disabled = false;
      } 
  if ((name === 'currentAddressLine1' || name === 'currentAreaTown' || name === 'currentCityState' || name==='currentPincode' || ((name === 'sameAddress') && newValue===false))  ) {
    const updatedData = {
      ...formData,
      [name]: value,
      sameAddress: false,
      permanentAddressLine1: '',
      permanentAreaTown: '',
      permanentCityState: '',
      permanentPincode: ''
     
    };
    setFormData(updatedData);
    onChange(updatedData);
  } 
  
  else if((name === 'sameAddress') && newValue==true){
    const updatedData = {
      ...formData,
      [name]: value,
      sameAddress: true,
          permanentAddressLine1: formData.currentAddressLine1,
          permanentAreaTown: formData.currentAreaTown,
          permanentCityState: formData.currentCityState,
          permanentPincode: formData.currentPincode
     
    };
    setFormData(updatedData);
    onChange(updatedData);
  }
  
  else if (name === 'relocateAnywhere') {
    const updatedData = {
      ...formData,
      relocateAnywhere: checked,
      selectedCountry:formData.citizenship,
      selectedStates:"",
      selectedCities:""

    };
    setFormData(updatedData);
    onChange(updatedData);
  } 
  else if (name === 'remoteJob') {
    const updatedData = {
      ...formData,
      remoteJob: checked,

    };
    setFormData(updatedData);
    onChange(updatedData);
  } 
  else{
    const updatedData = {
      ...formData,
      [name]: value
    };
    setFormData(updatedData);
    onChange(updatedData);
  }
  
    }
    
    
    const handleAddStateCity = () => {
      
      // setFormData((prevData) => ({
      //   ...prevData,
      //   incrementValue: prevData.incrementValue + 1
      // }));
  
  
      const updatedData= {
        ...formData,
        incrementValue: formData.incrementValue + 1
      }
  
      setFormData(updatedData);
      onChange(updatedData);
    };
  
    const handleCitizenship = (countryCode) => {
  
  
      const updatedData = {
        ...formData,
          citizenship: countryCode,
          selectedCountry: countryCode,
          selectedStates: '',
          selectedCities: []
      };
      setFormData(updatedData);
      onChange(updatedData);
     
    };
  
    const handleCountryChange = (countryCode) => {
      const updatedData = {
        ...formData,
          selectedCountry: countryCode,
          selectedStates: '',
          selectedCities: []
      };
      setFormData(updatedData);
      onChange(updatedData);
  
    };
  
    useEffect(() => {
  
    }, [formData.selectedCountry, formData.selectedStates, formData.selectedCities]);
  
    const handleStateChange = (stateCode) => {
  
      const updatedData = {
        ...formData,
        selectedStates: stateCode
      };
      setFormData(updatedData);
      onChange(updatedData);
     
    };
  
    const handleCityChange = (cityName, index) => {
      if (formData.selectedCities.includes(cityName)) {
        // City already selected, show an error or handle the case as needed
        alert('City already selected');
        return;
      }
  
      const updatedData = {
        ...formData,
        selectedCities: [...formData.selectedCities]
      };
      updatedData.selectedCities[index] = cityName;
      setFormData(updatedData);
      onChange(updatedData)
     
    };
  
    const renderCountryOptions = () => {
      const countryList = Country.getAllCountries();
      return countryList.map((country) => (
        <option key={country.isoCode} value={`${country.name},${country.isoCode}`}>
          {country.name}
        </option>
      ));
    };
  
    
  
    const renderStateOptions = () => {
      const countryCode = formData.selectedCountry?.split(',')[1];
      
      const states = countryCode ? State.getStatesOfCountry(countryCode) : [];
      
      return states.map((state) => (
        <option key={state.isoCode} value={`${state.name},${state.isoCode}`}>
          {state.name}
        </option>
      ));
    };
  
    const renderCityOptions = () => {
      const countryCode = formData.selectedCountry?.split(',')[1];
      const stateCode = formData.selectedStates?.split(',')[1];
      const cities = City.getCitiesOfState(countryCode, stateCode);
      return cities.map((city,index) => (
        <option key={index} value={city.name}>
          {city.name}
        </option>
      ));
    };
  
   
    
  
  
  
    return (
      <div>
        <div style={{marginBottom:"80px"}}>
        {/* <h3>Basic Details</h3> */}
        <div className='basic-details box-shadow' style={{justifyContent:"unset"}}>
        <div>
          <div style={{ backgroundColor: "#D9D9D9", width: "118px", height: "125px" }}>
               <img src={uploadedImage} alt="" style={{ width: "100%", height: "100%" }} />
             </div><br />
            <input type="file" onChange={handlePhotoUpload} />
          </div>
          <div className='flex-column' style={{width:"100%"}}>
          <div className='flex-row-between'>
            <label>First Name
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
            /></label><br />

            <label>Last Name
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              // required
            /></label><br />
            
    
            <label>Visa
            <select
              name="visa"
              value={formData.visa}
              onChange={handleChange}
              defaultValue={""}
              required
            >
               <option value={""}>Select Visa</option>
              {/* <option value="H1B">H1B</option>
              <option value="B22">B22</option> */}
              <option value="HB1">HB1</option>
              <option value="USC">USC</option>
              <option value="GC">GC</option>
              <option value="H4EAD">H4EAD</option>
              <option value="GCEAD">GCEAD</option>
              <option value=" F1 OPT"> F1 OPT</option>
              <option value="F1 CPT">F1 CPT</option>
              <option value="TN">TN</option>
              <option value="Other">Other</option>
              {/* Add options for the industrial domain */}
            </select></label><br />

            <label>Visa Validity
            <input
              type="date"
              name="visaValidity"
              value={formData.visaValidity}
              onChange={handleChange}
              placeholder="Visa Validity Date"
              // required
            /></label><br />
          </div>
         
          <div className='flex-row-between'>
            <label>Date Of Birth
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              placeholder="Date of Birth"
              required
            /></label><br />

            <label>Industry Domain
            <select
              name="domain"
              value={formData.domain}
              onChange={handleChange}
              placeholder="Industrial Domain"
              required
            >
              <option value="">Select Domain</option>
              <option value="Automotive">Automotive</option>
              <option value="Technology">Technology</option>
              <option value="Finance">Finance</option>
              <option value="Engineering">Engineering</option>
              <option value="Other">Other</option>
              {/* Add options for the industrial domain */}
            </select></label><br />


            <label>Select Citizenship:
            <select
              name="citizenship"
              value={formData.citizenship}
              required
              // onChange={handleChange}
              defaultValue={""}
                           onChange={(e) => handleCitizenship(e.target.value)}
                         >
                           <option value={""}>Select Country</option>
              
                          {renderCountryOptions()}
                         </select></label><br />

                         <div>
                          {/* <input type="text" /> */}
                         </div>
           
          </div>
          </div>
         
    
        </div>
    
        <div style={{ margin: "30px" }} className='box-shadow'>
  
          <div className='flex-row'>
          <label style={{ flexDirection: "row" }} >
            <div className='text-yellow'> Open to relocate anywhere : &nbsp;&nbsp;</div>
           
            <input
              style={{height:"20px",width:"20px"}}
              name="relocateAnywhere"
              type="checkbox"
              checked={formData.relocateAnywhere}
              onChange={handleChange}
            />
              
            
          </label>
          <label style={{ flexDirection: "row" }}>
          <div className='text-yellow'>  Open to Remote Job : &nbsp;&nbsp;</div>
           
            <input
              style={{height:"20px",width:"20px"}}
              name="remoteJob"
              type="checkbox"
              checked={formData.remoteJob}
              onChange={handleChange}
            />
              
            
          </label>
          </div>

          <div className='basic-details' style={{
                justifyContent: "start", gap: "50px", margin: "0px",
                marginTop: "10px"
              }}>
          {formData.relocateAnywhere && (
            <>
              
                <div>
                  <label>Select Country:</label><br />
                  <select
                    value={formData.selectedCountry}
                    // defaultValue={options[100]}
                    defaultValue={""}
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    <option value={""}>Select Country</option>
  
                    {renderCountryOptions()}
                  </select>
                </div>
                
              </>)}
                <div>
                  <label>Select State:</label><br />
                  <select
                    value={formData.selectedStates}
                    defaultValue={""}
  
                    onChange={(e)=>handleStateChange(e.target.value)}
                  >
                    <option value={""}>Select State</option>
                    {renderStateOptions()}
                  </select>
                </div>
                </div>
  
              <label>Select up to three locations:</label>
              <div className='basic-details' style={{ flexWrap: "wrap", margin: "0px", marginTop: "10px", maxWidth: "100%", gap: "10px 30px", justifyContent: "flex-start" }}>
                {[...Array(formData.incrementValue)].map((_, index) => (<>
                  <div key={index}>
                    <label>Select City:</label>
                    <select
                      value={formData.selectedCities[index] || ''}
                      defaultValue={""}
                      onChange={(e) => handleCityChange(e.target.value, index)}
                    >
                      <option value={""}>Select City</option>
                      {renderCityOptions()}
                    </select>
                  </div>
                </>))}
              </div>
              <button onClick={handleAddStateCity} style={{ margin: "10px" }} className='add-State'>+ Add</button>
  
            
        </div>  
  
        {/* CONTACT DETAILS */}
  
  
        
        {/* <h3>Contact Details</h3> */}
        <div className='basic-details box-shadow'>
          <label>Email Address<br />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              required
            />
          </label>
  
          <label>Contact Number<br />
            <input
            name='contactNumber'
              type="tel"
              value={formData.contactNumber}
              onChange={handleChange}
              placeholder="Contact Number"
              required
            />
          </label>
  
          <label>Skype ID<br />
            <input
             name='skypeId'
              type="text"
              value={formData.skypeId}
              onChange={handleChange}
              placeholder="Skype ID"
  
            />
          </label>
  
        </div>
    
        <div className='flex-column box-shadow' style={{ justifyContent: "flex-start", gap: "20px", margin:"30px" }} >
          <div >
            <label >Current Address : </label><br />
            
            <div className='flex-row-between' >
            <label>Current Address Line 1:
            <input
              type="text"
              name='currentAddressLine1'
              value={formData.currentAddressLine1}
              onChange={handleChange}
  
              placeholder="Current Address Line 1"
              required
            /></label>
  
            <label>Area/Town:
            <input
              type="text"
              name='currentAreaTown'
              value={formData.currentAreaTown}
              onChange={handleChange}
              placeholder="Area/Town"
              required
            />
  </label>
            <label>City/State:
            <input
              type="text"
              name='currentCityState'
              value={formData.currentCityState}
              onChange={handleChange}
              placeholder="City/State"
              required
            /></label>
  
            <label>Zipcode:
            <input
              type="text"
              name='currentPincode'
              value={formData.currentPincode}
              onChange={handleChange
              }
              placeholder="Pincode"
              required
            /></label>
           </div>
          </div>

         
          <div>
            <label>Permanent Address:</label><br />
            <label style={{ flexDirection: "row", marginBottom: "20px", marginTop: "-10px" }}>
              <input
                type="checkbox"
                name='sameAddress'
                checked={formData.sameAddress}
                onChange={handleChange}
              />
              Same as Current Address
            </label>
            <div className='flex-row-between'>
           
  
            <label>Permanent Address Line 1:
            <input
              type="text"
              name='permanentAddressLine1'
              value={formData.permanentAddressLine1}
              onChange={handleChange}
              placeholder="Permanent Address Line 1"
              required
            />
  </label>
            <label>Area/Town:
            <input
              type="text"
              name='permanentAreaTown'
              value={formData.permanentAreaTown}
              onChange={handleChange}
              placeholder="Area/Town"
              required
            />
  </label>
            <label>City/State:
            <input
              type="text"
              name='permanentCityState'
              value={formData.permanentCityState}
              onChange={handleChange}
              placeholder="City/State"
              required
            /></label>
  
            <label>Pincode:
            <input
              type="text"
              name='permanentPincode'
              value={formData.permanentPincode}
              onChange={handleChange}
              placeholder="Pincode"
              required
            /></label>

          </div>
          </div>
  
        </div>
    
        
      </div>
  
      <button onClick={onNext1} className='next-button' style={{ marginTop: "-50px", float: "right" ,marginRight: "30px"}}>
      Next <span className="material-symbols-outlined">keyboard_double_arrow_right</span>
    </button></div>
      
    );
    
  }

export default BasicDetails