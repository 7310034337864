import logo from './logo.svg';
import './index.css';
import Login from './login/Login';
import Login1 from './login/Login1';
import SignUp from './login/SignUp';
import CreateSubmission from './pages/SubmissionPage/CreateSubmission';
import { BrowserRouter, Route, Routes,Redirect, useNavigate, Outlet, Navigate  } from 'react-router-dom';
import CandidateMain from './pages/CandidatePage/CandidateMain';
import HomePage from './pages/HomePage/HomePage';
import CandidateForm from './pages/CandidatePage/CandidateForm/CandidateForm';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import EmployeeMain from './pages/EmployeePage/EmployeeMain';
import AddNewEmployee from './pages/EmployeePage/AddNewEmployee';
import EditEmployee from './pages/EmployeePage/EditEmployee';
import Test from './pages/Test';
import EmployeeAccess from './pages/EmployeePage/EmployeeAccess';
import SubmissionPage from './pages/SubmissionPage/SubmissionPage';
import InterviewPage from './pages/InterviewPage/InterviewPage';
import InterviewForm from './pages/InterviewPage/InterviewForm';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyId } from './action.js/companyAction';
import ProtectedRoute from './ProtectedRoute';
import SupportPage from './SupportPage';
import PublicRoute from './PublicRoute';
import BulkEmailSender from './pages/sendEmailPage/BulkEmailSender';
import NotFound from './pages/NotFound';
import ResetPassword from './login/ResetPassword';
import ResumeParserApi from './resume-parser/ResumeParserApi';
import resumeParser from './resume-parser/resumeParser';


function App() {
  const [subDomainExists, setSubDomainExists] = useState(false);
  const dispatch = useDispatch();
  const navigateOne = useNavigate();

  useEffect(() => {
    const getSubdomain = () => {
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
       console.log("hostNam e",hostname, parts.length)
        if (parts.length >= 2) {
          const subdomain = parts[0];
          const mainDomain = parts[1];
          console.log("subdomain, mainDomain, process.env.REACT_APP_FRONTEND_URL",subdomain, mainDomain,process.env.REACT_APP_FRONTEND_URL)
          if (mainDomain === process.env.REACT_APP_FRONTEND_URL) {
            return subdomain;
          }
        }
      
        return null;
      };

    const fetchData = async () => {
      const subdomain = getSubdomain();
      if (subdomain !== null) {
        dispatch(getCompanyId(subdomain));
        setSubDomainExists(true);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* <BrowserRouter> */}
        <Routes>
          {subDomainExists ? (
            <Route element={<Layout />}>
              <Route path="/dashBoard" exact element={<ProtectedRoute Component={HomePage} />} />
              <Route path="/candidatePage" exact element={<ProtectedRoute Component={CandidateMain} />} />
              <Route path="/candidateForm" exact element={<ProtectedRoute Component={CandidateForm} />} />
              <Route path="/employeePage" exact element={<ProtectedRoute Component={EmployeeMain} />} />
              <Route path="/addNewEmployee" exact element={<ProtectedRoute Component={AddNewEmployee} />} />
              <Route path="/test" exact element={<Test />} />
              <Route path="/editEmployee/:employeeId" element={<ProtectedRoute Component={EditEmployee} />} />
              <Route path='/employeeAccess' exact element={<ProtectedRoute Component={EmployeeAccess} />} />
              <Route path='/submissionPage' exact element={<ProtectedRoute Component={SubmissionPage} />} />
              <Route path='/createSubmission' exact element={<ProtectedRoute Component={CreateSubmission} />} />
              <Route path='/interviewPage' exact element={<ProtectedRoute Component={InterviewPage} />} />
              <Route path='/editInterview/:Id' exact element={<ProtectedRoute Component={InterviewPage} />} />
              <Route path='/interviewForm' exact element={<ProtectedRoute Component={InterviewForm} />} />
              <Route path='/bulkEmail' exact element={<ProtectedRoute Component={BulkEmailSender} />} />
              <Route path='/resumeParser' exact element={<ProtectedRoute Component={resumeParser} />} /> 
              {/* <Route path="*" element={<NotFound />} /> */}
            </Route>

           
          ) : (
            navigateOne("/support")
          )}
          <Route path="/support" exact element={<SupportPage />} />
          <Route path="/resume" exact element={<ResumeParserApi/>} />
          <Route path="/resetPassword/:userId/:token/:companyId" exact element={<PublicRoute Component={ResetPassword} /> }  />
          {subDomainExists && <Route path="/" exact element={<PublicRoute Component={Login} />} />}
          <Route path="*" element={<SupportPage />} />
        </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

function Layout() {
  return (
    <div className='container'>
      <Sidebar />
      <div className='HeaderMainPage'>
        <Header />
        <div className='main'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
