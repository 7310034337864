import resume1 from './Resume1.pdf'
import docs1 from './Resume1.pdf'
// const product_02_image_01_default = await import('../images/products/product-02 (1).jpg')
//   .then(module => module.default);

// import img1 from '/image/person1.jpg'

// import img2 from '/image/person2.jpg'
// import img3 from '/image/person3.jpg'
// import img4 from '/image/person4.jpg'




export default {


  candidates: [
    {
      "assignto": "",
      "photo": "/image/person1.jpg",
      "fullName": "David",
      "dob": "1998-05-08",
      "currentAddressLine1": "jamus",
      "currentAreaTown": "ward3",
      "currentCityState": "Gorakhpur",
      "currentPincode": "123455",
      "permanentAddressLine1": "jamus",
      "permanentAreaTown": "ward3",
      "permanentCityState": "Gorakhpur",
      "permanentPincode": "123455",
      "email": "rfeferer@evvfe.cim",
      "sameAddress": true,
      "contactNumber": "212332323",
      "skypeId": "33e322dcdc",
      "domain": "Technology",
      "visa": "B22",
      "visaValidity": "2023-06-16",
      "citizenship": "India,IN",
      "relocateAnywhere": "on",
      subbmission: [
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Sub to vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh"
        },
      ],
      selectedLocations: [],
      "selectedCountry": "India,IN",
      "selectedStates": "Uttar Pradesh,UP",
      "jobStatus": "New Candidates",
      "selectedCities": [
        "Ambahta",
        "Airwa",
        "Gola Gokarannath"
      ],
      "incrementValue": 3,
      "EducationQualification": {
        "highestQualification": "Master's Degree",
        "qualifications": [
          {
            "qualification": "Master's Degree",
            "institute": "Univerty of Haryana",
            "resultUnit": "Percentage",
            "totalMarks": "12",
            "obtainedMarks": "2332"
          },
          {
            "qualification": "Bachelor's Degree",
            "institute": "University of gorakhpur",
            "resultUnit": "Percentage",
            "totalMarks": "cecc",
            "obtainedMarks": "2333"
          }
        ]
      },
      "Employeer": [
        {
          "company": "Networsys",
          "role": "33223",
          "startDate": "2023-06-02",
          "endDate": "2023-06-14",
          "description": "qweedd"
        },
        {
          "company": "dcdcdc",
          "role": "weerre",
          "startDate": "2023-06-27",
          "endDate": "2023-06-21",
          "description": "crrrrrrrr"
        }
      ],
      "Documents": [
        {
          "documentName": "efsdfeer",
          "selectedFile": {}
        },
        {
          "documentName": " fvvffv",
          "selectedFile": {}
        }
      ]
    },

    {
      "assignto": "",

      "photo": "/image/person2.jpg",
      "fullName": "Peter",
      "dob": "1998-05-08",
      "currentAddressLine1": "jamus",
      "currentAreaTown": "ward3",
      "currentCityState": "Gorakhpur",
      "currentPincode": "123455",
      "permanentAddressLine1": "jamus",
      "permanentAreaTown": "ward3",
      "permanentCityState": "Gorakhpur",
      "permanentPincode": "123455",
      "email": "rfeferer@evvfe.cim",
      "sameAddress": true,
      "contactNumber": "212332323",
      "skypeId": "33e322dcdc",
      "domain": "Technology",
      "visa": "B22",
      "visaValidity": "2023-06-16",
      "citizenship": "India,IN",
      "relocateAnywhere": "on",
      selectedLocations: [],
      submittedBy: "Jeff",
      status: "Interview Schedule",
      jobSourceType: "Online Job Portal",
      jobSourceName: "Indeed",
      endClient: "Coast pvt ltd", "selectedCountry": "India,IN",
      "selectedStates": "Uttar Pradesh,UP",
      "jobStatus": "New Candidates",
      "selectedCities": [
        "Ambahta",
        "Airwa",
        "Gola Gokarannath"
      ],
      "incrementValue": 3,
      "section2": {
        "highestQualification": "Master's Degree",
        "qualifications": [
          {
            "qualification": "Master's Degree",
            "institute": "Univerty of Haryana",
            "resultUnit": "Percentage",
            "totalMarks": "12",
            "obtainedMarks": "2332"
          },
          {
            "qualification": "Bachelor's Degree",
            "institute": "University of gorakhpur",
            "resultUnit": "Percentage",
            "totalMarks": "cecc",
            "obtainedMarks": "2333"
          }
        ]
      },
      "section3": [
        {
          "company": "Networsys",
          "role": "33223",
          "startDate": "2023-06-02",
          "endDate": "2023-06-14",
          "description": "qweedd"
        },
        {
          "company": "dcdcdc",
          "role": "weerre",
          "startDate": "2023-06-27",
          "endDate": "2023-06-21",
          "description": "crrrrrrrr"
        }
      ],
      "section4": [
        {
          "documentName": "efsdfeer",
          "selectedFile": {}
        },
        {
          "documentName": " fvvffv",
          "selectedFile": {}
        }
      ]
    },

    {
      "photo": "/image/person3.jpg",
      "fullName": "Jack",
      "dob": "1998-05-08",
      "currentAddressLine1": "jamus",
      "currentAreaTown": "ward3",
      "currentCityState": "Gorakhpur",
      "currentPincode": "123455",
      "permanentAddressLine1": "jamus",
      "permanentAreaTown": "ward3",
      "permanentCityState": "Gorakhpur",
      "permanentPincode": "123455",
      "email": "rfeferer@evvfe.cim",
      "sameAddress": true,
      "contactNumber": "212332323",
      "skypeId": "33e322dcdc",
      "domain": "Technology",
      "visa": "B22",
      "visaValidity": "2023-06-16",
      "citizenship": "India,IN",
      "relocateAnywhere": "on",
      selectedLocations: [],
      submittedBy: "charlie",
      status: "Sub to vendor",
      jobSourceType: "vendor",
      jobSourceName: "Rishabh Tyaagi",
      endClient: "Prime pvt ltd", "selectedCountry": "India,IN",
      "selectedStates": "Uttar Pradesh,UP",
      "jobStatus": "On Bench",
      "selectedCities": [
        "Ambahta",
        "Airwa",
        "Gola Gokarannath"
      ],
      "incrementValue": 3,
      "section2": {
        "highestQualification": "Master's Degree",
        "qualifications": [
          {
            "qualification": "Master's Degree",
            "institute": "Univerty of Haryana",
            "resultUnit": "Percentage",
            "totalMarks": "12",
            "obtainedMarks": "2332"
          },
          {
            "qualification": "Bachelor's Degree",
            "institute": "University of gorakhpur",
            "resultUnit": "Percentage",
            "totalMarks": "cecc",
            "obtainedMarks": "2333"
          }
        ]
      },
      "section3": [
        {
          "company": "Networsys",
          "role": "33223",
          "startDate": "2023-06-02",
          "endDate": "2023-06-14",
          "description": "qweedd"
        },
        {
          "company": "dcdcdc",
          "role": "weerre",
          "startDate": "2023-06-27",
          "endDate": "2023-06-21",
          "description": "crrrrrrrr"
        }
      ],
      "section4": [
        {
          "documentName": "efsdfeer",
          "selectedFile": {}
        },
        {
          "documentName": " fvvffv",
          "selectedFile": {}
        }
      ]
    },

    {
      "photo": "/image/person4.jpg",
      "fullName": "Kinda",
      "dob": "1998-05-08",
      "currentAddressLine1": "jamus",
      "currentAreaTown": "ward3",
      "currentCityState": "Gorakhpur",
      "currentPincode": "123455",
      "permanentAddressLine1": "jamus",
      "permanentAreaTown": "ward3",
      "permanentCityState": "Gorakhpur",
      "permanentPincode": "123455",
      "email": "rfeferer@evvfe.cim",
      "sameAddress": true,
      "contactNumber": "212332323",
      "skypeId": "33e322dcdc",
      "domain": "Technology",
      "visa": "B22",
      "visaValidity": "2023-06-16",
      "citizenship": "India,IN",
      "relocateAnywhere": "on",
      selectedLocations: [],
      submittedBy: "Jeff",
      status: "Interview Schedule",
      jobSourceType: "Online Job Portal",
      jobSourceName: "Indeed",
      endClient: "Coast pvt ltd", "selectedCountry": "India,IN",
      "selectedStates": "Uttar Pradesh,UP",
      "jobStatus": "On Bench",
      "selectedCities": [
        "Ambahta",
        "Airwa",
        "Gola Gokarannath"
      ],
      "incrementValue": 3,
      "section2": {
        "highestQualification": "Master's Degree",
        "qualifications": [
          {
            "qualification": "Master's Degree",
            "institute": "Univerty of Haryana",
            "resultUnit": "Percentage",
            "totalMarks": "12",
            "obtainedMarks": "2332"
          },
          {
            "qualification": "Bachelor's Degree",
            "institute": "University of gorakhpur",
            "resultUnit": "Percentage",
            "totalMarks": "cecc",
            "obtainedMarks": "2333"
          }
        ]
      },
      "section3": [
        {
          "company": "Networsys",
          "role": "33223",
          "startDate": "2023-06-02",
          "endDate": "2023-06-14",
          "description": "qweedd"
        },
        {
          "company": "dcdcdc",
          "role": "weerre",
          "startDate": "2023-06-27",
          "endDate": "2023-06-21",
          "description": "crrrrrrrr"
        }
      ],
      "section4": [
        {
          "documentName": "efsdfeer",
          "selectedFile": "/image/person1.jpg"
        },
        {
          "documentName": " fvvffv",
          "selectedFile": "/image/person2.jpg"
        }
      ]
    },

    {
      assignto: "John",
      photo: "/image/person2.jpg",
      fullName: "Sarah",
      dob: "1990-12-15",
      currentAddressLine1: "123 Main St",
      currentAreaTown: "Cityville",
      currentCityState: "Metropolis",
      currentPincode: "54321",
      permanentAddressLine1: "456 Elm St",
      permanentAreaTown: "Townsville",
      permanentCityState: "Villageland",
      permanentPincode: "98765",
      email: "sarah@example.com",
      sameAddress: false,
      contactNumber: "9876543210",
      skypeId: "sarah.skype",
      domain: "Finance",
      visa: "H1B",
      visaValidity: "2024-09-30",
      citizenship: "United States,US",
      relocateAnywhere: "no",
      selectedLocations: ["Cityville", "Townsville"],
      selectedCountry: "United States,US",
      selectedStates: "California,CA",
      jobStatus: "Interview Scheduled",
      selectedCities: ["City A", "City B", "City C"],
      incrementValue: 5,
      section2: {
        highestQualification: "Bachelor's Degree",
        qualifications: [
          {
            qualification: "Bachelor's Degree",
            institute: "University of XYZ",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.8"
          },
          {
            qualification: "High School Diploma",
            institute: "City High School",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.5"
          }
        ]
      },
      section3: [
        {
          company: "ABC Corporation",
          role: "Financial Analyst",
          startDate: "2022-03-01",
          endDate: "2023-05-31",
          description: "Performed financial analysis and budgeting tasks."
        },
        {
          company: "XYZ Corp",
          role: "Accountant",
          startDate: "2020-06-15",
          endDate: "2022-02-28",
          description: "Managed accounts receivable and prepared financial reports."
        }
      ],
      section4: [
        {
          documentName: "Resume",
          selectedFile: {}
        },
        {
          documentName: "Cover Letter",
          selectedFile: {}
        }
      ]
    },

    {
      assignto: "Emily",
      photo: "/image/person3.jpg",
      fullName: "Michael",
      dob: "1985-09-22",
      currentAddressLine1: "789 Oak Ave",
      currentAreaTown: "Villageville",
      currentCityState: "Townsville",
      currentPincode: "12345",
      permanentAddressLine1: "789 Oak Ave",
      permanentAreaTown: "Villageville",
      permanentCityState: "Townsville",
      permanentPincode: "12345",
      email: "michael@example.com",
      sameAddress: true,
      contactNumber: "555-1234",
      skypeId: "michael.skype",
      domain: "Marketing",
      visa: "Visitor",
      visaValidity: "2023-12-31",
      citizenship: "Canada,CA",
      relocateAnywhere: "yes",
      selectedLocations: [],
      submittedBy: "Diya",
      status: "Sub to End Client",
      jobSourceType: "Online Job Portal",
      jobSourceName: "Kibkdin",
      endClient: "HCL pvt ltd", selectedCountry: "",
      selectedStates: "",
      jobStatus: "Pending Review",
      selectedCities: [],
      incrementValue: 2,
      section2: {
        highestQualification: "Bachelor's Degree",
        qualifications: [
          {
            qualification: "Bachelor's Degree",
            institute: "University of ABC",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.6"
          },
          {
            qualification: "Diploma in Marketing",
            institute: "Marketing Institute",
            resultUnit: "Percentage",
            totalMarks: "100",
            obtainedMarks: "80"
          }
        ]
      },
      section3: [
        {
          company: "XYZ Marketing Agency",
          role: "Marketing Manager",
          startDate: "2020-01-01",
          endDate: "2022-12-31",
          description: "Led marketing campaigns and managed client accounts."
        },
        {
          company: "ABC Corporation",
          role: "Marketing Coordinator",
          startDate: "2018-06-01",
          endDate: "2019-12-31",
          description: "Assisted in creating marketing materials and organizing events."
        }
      ],
      section4: [
        {
          documentName: "Reference Letter",
          selectedFile: {}
        },
        {
          documentName: "Portfolio Samples",
          selectedFile: {}
        }
      ]
    },


    {
      assignto: "Naina",
      photo: "/image/person4.jpg",
      fullName: "Jessica",
      dob: "1992-07-10",
      currentAddressLine1: "456 Elm St",
      currentAreaTown: "Cityville",
      currentCityState: "Metropolis",
      currentPincode: "54321",
      permanentAddressLine1: "456 Elm St",
      permanentAreaTown: "Cityville",
      permanentCityState: "Metropolis",
      permanentPincode: "54321",
      email: "jessica@example.com",
      sameAddress: true,
      contactNumber: "9876543210",
      skypeId: "jessica.skype",
      domain: "Human Resources",
      visa: "Citizen",
      visaValidity: "",
      citizenship: "United States,US",
      relocateAnywhere: "no",
      selectedLocations: [],
      submittedBy: "Diya",
      status: "Sub to End Client",
      jobSourceType: "Online Job Portal",
      jobSourceName: "Kibkdin",
      endClient: "HCL pvt ltd", selectedCountry: "",
      selectedStates: "",
      jobStatus: "In Progress",
      selectedCities: [],
      incrementValue: 4,
      section2: {
        highestQualification: "Master's Degree",
        qualifications: [
          {
            qualification: "Master's Degree",
            institute: "University of XYZ",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.9"
          },
          {
            qualification: "Bachelor's Degree",
            institute: "University of ABC",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.6"
          }
        ]
      },
      section3: [
        {
          company: "ABC Corporation",
          role: "HR Manager",
          startDate: "2020-01-01",
          endDate: "2023-06-30",
          description: "Managed HR operations and implemented employee engagement programs."
        },
        {
          company: "XYZ Consulting",
          role: "HR Consultant",
          startDate: "2018-06-01",
          endDate: "2019-12-31",
          description: "Provided HR advisory services and conducted talent acquisition activities."
        }
      ],
      section4: [
        {
          documentName: "Resume",
          selectedFile: {}
        },
        {
          documentName: "Certificates",
          selectedFile: {}
        }
      ]
    },



    {
      assignto: "Vipul",
      photo: "/image/person5.jpg",
      fullName: "Andrew",
      dob: "1987-03-18",
      currentAddressLine1: "789 Pine St",
      currentAreaTown: "Villageville",
      currentCityState: "Townsville",
      currentPincode: "12345",
      permanentAddressLine1: "789 Pine St",
      permanentAreaTown: "Villageville",
      permanentCityState: "Townsville",
      permanentPincode: "12345",
      email: "andrew@example.com",
      sameAddress: true,
      contactNumber: "555-5678",
      skypeId: "andrew.skype",
      domain: "Sales",
      visa: "Work Permit",
      visaValidity: "2024-06-30",
      citizenship: "United Kingdom,GB",
      relocateAnywhere: "yes",
      selectedLocations: [],
      submittedBy: "Vivek",
      status: "Sub to Vendor",
      jobSourceType: "Online Job Portal",
      jobSourceName: "End Client",
      endClient: "HCL pvt ltd", selectedCountry: "",
      selectedStates: "",
      jobStatus: "Shortlisted",
      selectedCities: [],
      incrementValue: 3,
      section2: {
        highestQualification: "Bachelor's Degree",
        qualifications: [
          {
            qualification: "Bachelor's Degree",
            institute: "University of ABC",
            resultUnit: "Percentage",
            totalMarks: "100",
            obtainedMarks: "85"
          },
          {
            qualification: "Diploma in Sales",
            institute: "Sales Institute",
            resultUnit: "Percentage",
            totalMarks: "100",
            obtainedMarks: "90"
          }
        ]
      },
      section3: [
        {
          company: "XYZ Sales Agency",
          role: "Sales Manager",
          startDate: "2019-01-01",
          endDate: "2023-06-30",
          description: "Led sales team and achieved revenue targets."
        },
        {
          company: "ABC Corporation",
          role: "Sales Representative",
          startDate: "2017-06-01",
          endDate: "2018-12-31",
          description: "Developed new client relationships and conducted product presentations."
        }
      ],
      section4: [
        {
          documentName: "CV",
          selectedFile: {}
        },
        {
          documentName: "Recommendation Letter",
          selectedFile: {}
        }
      ]
    },



    {
      assignto: "John",
      photo: "/image/person6.jpg",
      fullName: "Sophie",
      dob: "1994-11-05",
      currentAddressLine1: "123 Maple Ave",
      currentAreaTown: "Cityville",
      currentCityState: "Metropolis",
      currentPincode: "54321",
      permanentAddressLine1: "123 Maple Ave",
      permanentAreaTown: "Cityville",
      permanentCityState: "Metropolis",
      permanentPincode: "54321",
      email: "sophie@example.com",
      sameAddress: true,
      contactNumber: "9876543210",
      skypeId: "sophie.skype",
      domain: "Design",
      visa: "Student",
      visaValidity: "2023-12-31",
      citizenship: "Canada,CA",
      relocateAnywhere: "no",
      selectedLocations: [],
      submittedBy: "Vivek",
      status: "Sub to Vendor",
      jobSourceType: "Online Job Portal",
      jobSourceName: "End Client",
      endClient: "HCL pvt ltd", selectedCountry: "",
      selectedStates: "",
      jobStatus: "On Hold",
      selectedCities: [],
      incrementValue: 2,
      section2: {
        highestQualification: "Bachelor's Degree",
        qualifications: [
          {
            qualification: "Bachelor's Degree",
            institute: "University of XYZ",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.7"
          },
          {
            qualification: "Diploma in Design",
            institute: "Design Institute",
            resultUnit: "GPA",
            totalMarks: "4",
            obtainedMarks: "3.9"
          }
        ]
      },
      section3: [
        {
          company: "ABC Design Studio",
          role: "Graphic Designer",
          startDate: "2020-01-01",
          endDate: "2023-06-30",
          description: "Created visually appealing designs for various clients."
        },
        {
          company: "XYZ Agency",
          role: "UI/UX Designer",
          startDate: "2018-06-01",
          endDate: "2019-12-31",
          description: "Designed user interfaces and conducted usability testing."
        }
      ],
      section4: [
        {
          documentName: "Portfolio",
          selectedFile: {}
        },
        {
          documentName: "Certificates",
          selectedFile: {}
        }
      ]
    },

    {
      assignto: "Emily",
      photo: "/image/person7.jpg",
      fullName: "Ethan",
      dob: "1990-02-15",
      currentAddressLine1: "456 Oak St",
      currentAreaTown: "Villageville",
      currentCityState: "Townsville",
      currentPincode: "12345",
      permanentAddressLine1: "456 Oak St",
      permanentAreaTown: "Villageville",
      permanentCityState: "Townsville",
      permanentPincode: "12345",
      email: "ethan@example.com",
      sameAddress: true,
      contactNumber: "555-7890",
      skypeId: "ethan.skype",
      domain: "Finance",
      visa: "Work Permit",
      visaValidity: "2024-06-30",
      citizenship: "United States,US",
      relocateAnywhere: "yes",
      selectedLocations: [],
      submittedBy: "Vivek",
      status: "Sub to Vendor",
      jobSourceType: "Online Job Portal",
      jobSourceName: "End Client",
      endClient: "HCL pvt ltd", selectedCountry: "",
      selectedStates: "",
      jobStatus: "Interview Scheduled",
      selectedCities: [],
      incrementValue: 3,
      section2: {
        highestQualification: "Master's Degree",
        qualifications: [
          {
            qualification: "Master's Degree",
            institute: "University of ABC",
            resultUnit: "Percentage",
            totalMarks: "100",
            obtainedMarks: "85"
          },
          {
            qualification: "Bachelor's Degree",
            institute: "University of XYZ",
            resultUnit: "Percentage",
            totalMarks: "100",
            obtainedMarks: "90"
          }
        ]
      },
      section3: [
        {
          company: "ABC Financial Services",
          role: "Financial Analyst",
          startDate: "2019-01-01",
          endDate: "2023-06-30",
          description: "Performed financial analysis and prepared reports for clients."
        },
        {
          company: "XYZ Bank",
          role: "Investment Advisor",
          startDate: "2017-06-01",
          endDate: "2018-12-31",
          description: "Provided investment advice to clients and managed portfolios."
        }
      ],
      section4: [
        {
          documentName: "Resume",
          selectedFile: {}
        },
        {
          documentName: "Reference Letters",
          selectedFile: {}
        }
      ]
    },

  ],

  employees: [
    {
      "id": "1",
      "fullName": "John Doe",
      "userId": "johndoe123",
      "dob": "1990-05-15",
      "reportingTo": "Jane Smith",
      "designation": "Software Engineer",
      "emailAddress": "johndoe@example.com",
      "contactNumber": "1234567890",
      "password": "secretpassword",
      "confirmPassword": "secretpassword",
      "profilePicture": "/image/person1.jpg",
      // "groupAccess":[
      //     allCandidates
      // ]
    }
    ,
    {
      "id": "2",
      "fullName": "Alice Johnson",
      "userId": "alicejohn",
      "dob": "1988-12-10",
      "reportingTo": "Mark Thompson",
      "designation": "Web Developer",
      "emailAddress": "alicejohn@example.com",
      "contactNumber": "9876543210",
      "password": "password123",
      "confirmPassword": "password123",
      "profilePicture": "/image/person2.jpg"
    }
    ,
    {
      "id": "3",
      "fullName": "Robert Smith",
      "userId": "robertsmith",
      "dob": "1975-07-25",
      "reportingTo": "Emily Davis",
      "designation": "Data Analyst",
      "emailAddress": "robertsmith@example.com",
      "contactNumber": "5555555555",
      "password": "mysecretpass",
      "confirmPassword": "mysecretpass",
      "profilePicture": "/image/person3.jpg"
    }
    ,
    {
      "id": "4",
      "fullName": "Emily Wilson",
      "userId": "emilywil",
      "dob": "1993-03-18",
      "reportingTo": "Michael Brown",
      "designation": "UI/UX Designer",
      "emailAddress": "emilywil@example.com",
      "contactNumber": "9999999999",
      "password": "p@ssw0rd",
      "confirmPassword": "p@ssw0rd",
      "profilePicture": "/image/person4.jpg"
    }
    ,
    {
      "id": "5",
      "fullName": "David Johnson",
      "userId": "davidjohn",
      "dob": "1982-09-07",
      "reportingTo": "Karen Thompson",
      "designation": "Project Manager",
      "emailAddress": "davidjohn@example.com",
      "contactNumber": "7777777777",
      "password": "mysecret123",
      "confirmPassword": "mysecret123",
      "profilePicture": "/path/to/profile_picture5.jpg"
    }
    ,
    {
      "id": "6",
      "fullName": "Sarah Anderson",
      "userId": "sarahand",
      "dob": "1995-11-30",
      "reportingTo": "John Davis",
      "designation": "Business Analyst",
      "emailAddress": "sarahand@example.com",
      "contactNumber": "8888888888",
      "password": "securepass",
      "confirmPassword": "securepass",
      "profilePicture": "/path/to/profile_picture6.jpg"
    }
    ,
    {
      "id": "7",
      "fullName": "Michael Wilson",
      "userId": "michaelwil",
      "dob": "1978-06-12",
      "reportingTo": "Laura Brown",
      "designation": "Software Developer",
      "emailAddress": "michaelwil@example.com",
      "contactNumber": "1111111111",
      "password": "password1234",
      "confirmPassword": "password1234",
      "profilePicture": "/path/to/profile_picture7.jpg"
    }
    ,
    {
      "id": "9",
      "fullName": "Emma Davis",
      "userId": "emmadav",
      "dob": "1991-02-28",
      "reportingTo": "Robert Smith",
      "designation": "Quality Assurance Analyst",
      "emailAddress": "emmadav@example.com",
      "contactNumber": "2222222222",
      "password": "secretpass123",
      "confirmPassword": "secretpass123",
      "profilePicture": "/path/to/profile_picture8.jpg"
    },
    {
      "id": "10",
      "fullName": "Daniel Thompson",
      "userId": "danielthomp",
      "dob": "1987-08-05",
      "reportingTo": "Sarah Anderson",
      "designation": "System Administrator",
      "emailAddress": "danielthomp@example.com",
      "contactNumber": "3333333333",
      "password": "mysecurepassword",
      "confirmPassword": "mysecurepassword",
      "profilePicture": "/path/to/profile_picture9.jpg"
    },
    {
      "id": "11",
      "fullName": "Olivia Johnson",
      "userId": "oliviajohn",
      "dob": "1996-04-22",
      "reportingTo": "David Johnson",
      "designation": "Marketing Specialist",
      "emailAddress": "oliviajohn@example.com",
      "contactNumber": "4444444444",
      "password": "pass1234",
      "confirmPassword": "pass1234",
      "profilePicture": "/path/to/profile_picture10.jpg"
    },
    {
      "id": "12",
      "fullName": "Matthew Wilson",
      "userId": "matthewwil",
      "dob": "1984-01-09",
      "reportingTo": "Emma Davis",
      "designation": "Frontend Developer",
      "emailAddress": "matthewwil@example.com",
      "contactNumber": "5555555555",
      "password": "securepass12",
      "confirmPassword": "securepass12",
      "profilePicture": "/path/to/profile_picture11.jpg"
    },
    {
      "id": "13",
      "fullName": "Sophia Anderson",
      "userId": "sophiaand",
      "dob": "1994-07-16",
      "reportingTo": "Michael Wilson",
      "designation": "Database Administrator",
      "emailAddress": "sophiaand@example.com",
      "contactNumber": "6666666666",
      "password": "mypassword",
      "confirmPassword": "mypassword",
      "profilePicture": "/path/to/profile_picture12.jpg"
    }
    ,
    {
      "id": "14",
      "fullName": "James Davis",
      "userId": "jamesdav",
      "dob": "1989-03-26",
      "reportingTo": "Laura Brown",
      "designation": "Network Engineer",
      "emailAddress": "jamesdav@example.com",
      "contactNumber": "7777777777",
      "password": "password@123",
      "confirmPassword": "password@123",
      "profilePicture": "/path/to/profile_picture13.jpg"
    }






  ],

  submissionData: [
    {
      id: "1",
      name: "Wade Warner",
      "photo": "/image/person1.jpg",
      submission: [
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Submitted to Prime Vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh",
          interview: {

            scheduled: false,
          }

        },
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Submitted to Prime Vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh",
          interview: {
            interviewTitle: "title",
            interviewType: "Written",
            timeStart: "08:00pm",
            timeEnd: "09:00pm",
            scheduled: true,
            dateOfSubbmision: "2023-06-16",
            timezone: "IST",
            "timeStart": "08:00:00",
            "timeEnd": "09:00:00",
          }
        },

      ]
    }
    ,
    {
      id: "2",
      name: "Tushar Sharama",
      "photo": "/image/person2.jpg",
      submission: [
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Submitted to Prime Vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh",
          interview: {
            interviewTitle: "title",
            interviewType: "Written",
            timeStart: "08:00pm",
            timeEnd: "09:00pm",
            scheduled: true,
            dateOfSubbmision: "2023-06-16",
            timezone: "IST",
            "timeStart": "08:00:00",
            "timeEnd": "09:00:00",
          }
        },
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Submitted to Prime Vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh",

          interview: {
            scheduled: false,

          }
        },

      ]
    },
    {
      id: "3",
      name: "Satakshi Sahu",
      "photo": "/image/person3.jpg",
      submission: [
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Submitted to Prime Vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh",
          interview: {
            interviewTitle: "title",
            interviewType: "Written",
            "timeStart": "08:00:00",
            "timeEnd": "09:00:00",
            scheduled: true,
            dateOfSubbmision: "2023-06-16",
            timezone: "IST"
          }
        },
        {
          submittedBy: "charlie",
          dateOfSubbmision: "2023-06-16",
          position: "Web Developer",
          status: "Submitted to Prime Vendor",
          jobSourceType: "Vendor",
          jobSourceName: "Rishabh Tyaagi",
          endClient: "Prime pvt ltd",
          jobDescription: "uuehuxehuxheuhuehueuiehiehieicheie",
          comments: "hydcdh",
          interview: {
            interviewTitle: "title",
            interviewType: "Written",
            "timeStart": "08:00:00",
            "timeEnd": "09:00:00",
            scheduled: true,
            dateOfSubbmision: "2023-06-16",
            timezone: "IST"
          }
        },

      ]
    }
  ]
}

