export const UserData = [
    {
        id: 1,
        year: 2016,
        userGain: 10000,
        userLost: 8023,
    },
    {
        id: 2,
        year: 2017,
        userGain: 4677,
        userLost: 2345,
    },
    {
        id: 3,
        year: 2018,
        userGain: 7888,
        userLost: 5555,
    },
    {
        id: 4,
        year: 2019,
        userGain: 6000,
        userLost: 4055,
    },
    {
        id: 5,
        year: 2020,
        userGain: 6040,
        userLost: 4034,
    },
    {
        id: 6,
        year: 2020,
        userGain: 4300,
        userLost: 2234,
    },
    {
        id: 7,
        year: 2020,
        userGain: 9300,
        userLost: 6834,
    }
]