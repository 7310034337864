import React, { useState } from 'react';
import ButtonWithBG from '../../components/Buttons/ButtonWithBG';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createEmployee } from '../../action.js/employeeAction';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';

const AddNewEmployee = () => {
    const navigatePage = useNavigate()
    const [formData, setFormData] = useState({
        firstName: '',
        lastName:'',
        // userId: '',
        dob: '',
        // reportingTo: '',
        designation: '',
        email: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
        profilePicture: null,
      });





      const [uploadedImage, setUploadedImage] = useState(null);
      const employeeCreateSelector = useSelector((state)=>state.createEmployee);
      const {employeeInfo , loading , error} = employeeCreateSelector
      const createEmployeeDispatch = useDispatch()
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    

      function convertToBase64(file){
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result)
          };
          fileReader.onerror = (error) => {
            reject(error)
          }
        })
      }


      const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertToBase64(file);
        console.log("Employe profile image base 64",base64)
        setFormData((prevData) => ({
          ...prevData,
          profilePicture: base64,
        }));
        setUploadedImage(URL.createObjectURL(file));
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        // Perform form submission logic or API call here
        console.log(formData);
        createEmployeeDispatch(createEmployee(formData))
        // alert("Form Submitted")
        // navigatePage("/employeePage")

      };
    
      const passwordMatch = formData.password === formData.confirmPassword;
      const isFormValid =
        formData.firstName &&
        formData.lastName &&
        formData.dob &&
        // formData.reportingTo &&
        formData.designation &&
        formData.email &&
        formData.contactNumber &&
        formData.password &&
        formData.confirmPassword &&
        passwordMatch;
    


  return (
    // <div className='container' >
    // <Sidebar />
    // <div className='HeaderMainPage'>
    //   <Header />
    //   <div className='main'>

    <div className="employees" >
    {/* <div className='breadcrumb'>
        <div>
            <h1>EMPLOYEES</h1>
            // <p>Employees{employees ? "" : " / Candidates' Distribution"}</p> 
            <p>Employees / Add New Employees</p>
        </div>
        <div>


        </div>
        <div>
            <ButtonWithBG
                buttonName={"Add New Employee"}
                active={"buttonWithBgActive"}
             onClick={()=>navigatePage('/addNewEmployee')}
            />

            <ButtonWithBG
                buttonName={"Schedule New Interview"}
                boxBottomColor={"#FBBC05"}
                active={""}
            />
        </div> 
    </div> */}
      
      <form onSubmit={handleSubmit} className='flex-col-between' autoComplete="off" >
      {error ? <p style={{color:"#b31409"}}>{error}</p>:""}

          <div className='flex-row-between' style={{marginBottom:"30px"}} >
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />

        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            
          />
        </label>
        <br />
        {/* <label>
          User ID:
          <input
            type="text"
            name="userId"
            value={formData.userId}
            onChange={handleInputChange}
          />
        </label>
        <br /> */}
        <label>
          Date of Birth:
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        {/* <label>
          Reporting To:
          <input
            type="text"
            name="reportingTo"
            value={formData.reportingTo}
            onChange={handleInputChange}
          />
        </label>
        <br /> */}
        <label>
          Designation:
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        </div>


         <div className='flex-row-between' style={{marginBottom:"30px"}}>
        <label>
          Email Address (Official):
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Contact Number:
          <input
            type="tel"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Create Password:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          /> <br/>

{!passwordMatch && (
          <p style={{ color: 'red' }}>Passwords do not match.</p>
        )}
        </label>
       
        <br />
       
        </div>
       

        <div className='flex-column' style={{marginBottom:"30px"}}>
        {uploadedImage ? (
          <img src={uploadedImage} alt="Uploaded Profile" width="118px"
          height="125px" />
        ): <img src="/image/blank-profile.png" alt="Uploaded Profile" width="118px"
        height="125px" />}
        <input type="file" accept="image/*" onChange={handleFileUpload} style={{borderStyle:"none"}}/>
        
        </div>
        

        <button type="submit" 
         disabled={!isFormValid}
         className='save-button ' >
          Submit
        </button>
      </form>
    </div>
    // </div>
    // </div>
    // </div>
  );
};

export default AddNewEmployee;
