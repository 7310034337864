import React, { useEffect, useState } from 'react';
import ButtonWithBG from '../../components/Buttons/ButtonWithBG';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG';
import data from '../../data/data'
import { getAllEmployee } from '../../action.js/employeeAction';
import { useDispatch, useSelector } from 'react-redux';
import { createPermission, getOneEmployee, getOnePermission } from '../../action.js/employeePermissionAction';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';


const EmployeeAccess = () => {
    const [employee, setEmployee] = useState(true)
  const [formData, setFormData] = useState([]);
  const [access , setAccess] = useState({
    employeeId:"",
    allCandidateProfile:{
        editPermission:false,
        viewPermission:false,
        createPermission:false
    },
    givenCandidateProfile:{
        editPermission:false,
        viewPermission:false,
        createPermission:false
    },
    distributedCandidateProfile:{
        editPermission:false,
        viewPermission:false,
        createPermission:false
    },
    submissionOfGivenCandidates:{
        editPermission:false,
        viewPermission:false,
        createPermission:false
    },
    interviewSchedule:{
        editPermission:false,
        viewPermission:false,
        createPermission:false
    }
  })
const [isEditMode, setIsEditMode] = useState(false)
 
const  getAllEmployeeDispatch = useDispatch()
const  submitFormDispatch = useDispatch()
const getAllEmployeeSelector = useSelector((state)=>state.getAllEmployee)
const {employeesInfo , loading , error} = getAllEmployeeSelector

useEffect(()=>{
  console.log("hit firsttime")
  getAllEmployeeDispatch(getAllEmployee())
  console.log("employeesInfo................",employeesInfo)
},[])

useEffect(()=>{
  console.log("employeesInfo................",employeesInfo)
  setFormData(employeesInfo)
  console.log("employess................",formData)
},[employeesInfo])

  const handleCheckboxChange = (category, permission, isChecked) => {
    setAccess(prevData => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [permission]: isChecked
      }
    }));
  };

  const getOneDispatch = useDispatch();
const getOneEmployeeSelector = useSelector((state)=>state.getOneEmployeePermision)
const { oneEmployeeInfo, loading: oneEmployeeLoading, error: oneEmployeeError } = getOneEmployeeSelector;
  const handleNameChange = e => {
    setIsEditMode(false);
    const selectedEmployeeId = e.target.value;
    const selectedEmployee = formData?.find(employee => employee.id === selectedEmployeeId);
    console.log("selectedEmployeeId",selectedEmployeeId)

 // Dispatch an action to fetch details of the selected employee
 getOneDispatch(getOneEmployee(selectedEmployeeId));
 console.log("oneEmployeeInfo",oneEmployeeInfo)
    setAccess(prevData => ({
        ...prevData,
        employeeId: selectedEmployeeId,
      }));
    // setIsEditMode(selectedEmployeeId !== "");
  };

  // Assuming this function gets triggered after the Redux action is dispatched and oneEmployeeInfo is updated
const updateAccessState = () => {
  if (oneEmployeeInfo && oneEmployeeInfo.employeeData) {
    const {
      allCandidateProfile,
      givenCandidateProfile,
      distributedCandidateProfile,
      submissionOfGivenCandidates,
      interviewSchedule,
    } = oneEmployeeInfo.employeeData;

    // Update access state with received permissions data
    setAccess((prevAccess) => ({
      ...prevAccess,
      allCandidateProfile,
      givenCandidateProfile,
      distributedCandidateProfile,
      submissionOfGivenCandidates,
      interviewSchedule,
    }));
    setIsEditMode(true);
  }

  else{
    setIsEditMode(false);
  }
};

useEffect(() => {
  // This useEffect will trigger whenever oneEmployeeInfo changes
  updateAccessState();
}, [oneEmployeeInfo]); // Run this effect whenever oneEmployeeInfo changes


  const savePermissions = () => {
    // Perform any necessary actions to save the formData and access to the data.js file or API
    console.log('Form data:', formData);
    console.log('Access:', access);
    const response = submitFormDispatch(createPermission(access))
    console.log("response",response.success)
    // Example: Save formData and access to data.js file
    const updatedData = { ...access };
    // Assuming you are using Node.js, you can write the updated data to the data.js file
    // fs.writeFileSync('./data.js', `export default ${JSON.stringify(updatedData, null, 2)};`);
  };

  return (
    //  <div className='container' >
    // <Sidebar />
    // <div className='HeaderMainPage'>
    //   <Header />
    //   <div className='main'>

        <div className="employees" >
            {/* <div className='breadcrumb'>
                <div>
                    <h1>EMPLOYEES</h1>
                    // <p>Employees{employees ? "" : " / Candidates' Distribution"}</p> 
                    <p>Employee / Add New Employee / Access </p>
                </div>
                <div>


                </div>
                <div>
                    <ButtonWithBG
                        buttonName={"Add New Employee"}
                        active={" "}
                        // onClick={() => navigatePage('/addNewEmployee')}
                    />

                    <ButtonWithBG
                        buttonName={"Schedule New Interview"}
                        boxBottomColor={"#FBBC05"}
                        active={""}
                    />
                </div>
            </div> */}

           
            {/* Add select option for selecting name */}
      {/* Add select option for selecting employee */}
      <div className='employee-access'>
<div >
  <h3>Select the Employees </h3>
  <select value={access.employeeId} onChange={handleNameChange}>
  <option value={""} >Select Employee</option>
    {formData?.map(employee => (
        <>
      <option key={employee._id} value={employee._id}>{employee?.fullName || `${employee.firstName} ${employee?.lastName}`}</option>
      </>
    ))}
  </select>
</div>

<div style={{border:"1px solid #000000", width:"100%"}}></div>

      <div className="div-container">
        <h3>All Candidates Profile</h3>
        <label>
        <div class="switch_box box_1">
          <input         
          class="switch_1"
            type="checkbox"
            name="edit"
            checked={access.allCandidateProfile?.editPermission}
            disabled={!isEditMode}
            onChange={e =>
              handleCheckboxChange('allCandidateProfile', 'editPermission', e.target.checked)
            }
          />{' '}

          		</div>
          <div>
          <p>Can Edit</p>
<p>Edit the given Candidates Profile</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="view"
            disabled={!isEditMode}
            checked={access.allCandidateProfile?.viewPermission}
            onChange={e =>
              handleCheckboxChange('allCandidateProfile', 'viewPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can See Only</p>
<p>Only see the Candidates Profile</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="create"
            disabled={!isEditMode}
            checked={access.allCandidateProfile?.createPermission}
            onChange={e =>
              handleCheckboxChange('allCandidateProfile', 'createPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
<p>Can Create</p>
<p>Can create new Candidate Profile</p>
</div>
        </label>
      </div>
      
      <div className="div-container">
        <h3>Given Candidates Profile</h3>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="edit"
            disabled={!isEditMode}
            checked={access.givenCandidateProfile?.editPermission}
            onChange={e =>
              handleCheckboxChange('givenCandidateProfile', 'editPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can Shuffle</p>
<p>Shuffle given Candidates among users</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="view"
            disabled={!isEditMode}
            checked={access.givenCandidateProfile?.viewPermission}
            onChange={e =>
              handleCheckboxChange('givenCandidateProfile', 'viewPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can See Only</p>
<p>Only see the given Candidate Profile</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="create"
            disabled={!isEditMode}
            checked={access.givenCandidateProfile?.createPermission}
            onChange={e =>
              handleCheckboxChange('givenCandidateProfile', 'createPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
<p>Can Create</p>
<p>Can create new Candidate Profile</p>
</div>
        </label>
      </div>
      
      <div className="div-container">
        <h3>Candidate Distribution</h3>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="edit"
            disabled={!isEditMode}
            checked={access.distributedCandidateProfile?.editPermission}
            onChange={e =>
              handleCheckboxChange('distributedCandidateProfile', 'editPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can Apply</p>
<p>Can Apply candidates Submission</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="view"
            disabled={!isEditMode}
            checked={access.distributedCandidateProfile?.viewPermission}
            onChange={e =>
              handleCheckboxChange('distributedCandidateProfile', 'viewPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can See Only</p>
<p>Only see the given Candidates </p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="create"
            disabled={!isEditMode}
            checked={access.distributedCandidateProfile?.createPermission}
            onChange={e =>
              handleCheckboxChange('distributedCandidateProfile', 'createPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
<p>Can Create</p>
<p>Distribute New Candidates</p>
</div>
        </label>
      </div>
      

      <div className="div-container">
        <h3>Submission of Given Candidates</h3>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="edit"
            disabled={!isEditMode}
            checked={access.submissionOfGivenCandidates?.editPermission}
            onChange={e =>
              handleCheckboxChange('submissionOfGivenCandidates', 'editPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can Apply</p>
<p>Can Apply candidates’ Submission</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="view"
            disabled={!isEditMode}
            checked={access.submissionOfGivenCandidates?.viewPermission}
            onChange={e =>
              handleCheckboxChange('submissionOfGivenCandidates', 'viewPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can See Only</p>
<p>Only see the given Candidates Submission</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="create"
            disabled={!isEditMode}
            checked={access.submissionOfGivenCandidates?.createPermission}
            onChange={(e) =>
              handleCheckboxChange('submissionOfGivenCandidates', 'createPermission', e.target.checked)
            }
          />{' '}
        </div>
        <div>
          <p>Can Create Only </p>
          <p>Only create the interview</p>
          </div>
        </label>
      </div>
      
      <div className="div-container">
        <h3>Interview Schedule</h3>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="edit"
            disabled={!isEditMode}
            checked={access.interviewSchedule?.editPermission}
            onChange={e =>
              handleCheckboxChange('interviewSchedule', 'editPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can Create Interview</p>
<p>Can Schedule Interviewa</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="view"
            disabled={!isEditMode}
            checked={access.interviewSchedule?.viewPermission}
            onChange={e =>
              handleCheckboxChange('interviewSchedule', 'viewPermission', e.target.checked)
            }
          />{' '}
</div>
          <div>
          <p>Can See Only</p>
<p>Only see the Interview</p>
</div>
        </label>
        <label>
         <div class="switch_box box_1">
		

          <input
          class="switch_1"
            type="checkbox"
            name="create"
            disabled={!isEditMode}
            checked={access.interviewSchedule?.createPermission}
            onChange={e =>
              handleCheckboxChange('interviewSchedule', 'createPermission', e.target.checked)
            }
          />{' '}
        </div>
        <div>
          <p>Create</p>
          <p>Able to create Interview</p>
          </div>
        </label>
      </div>
      
      <button onClick={savePermissions} className='save-button' style={{marginLeft:"auto"}}>Save</button>
      </div>
      </div>
      
    // </div>
    // </div>
    // </div>
  );
};

export default EmployeeAccess;
