import React, { useState } from 'react'
import { useEffect } from 'react'
import data from '../../../data/data'
import { getSubmissionsById, updateSubmission, updateSubmissionAction } from '../../../action.js/submissionAction'
import { useDispatch, useSelector } from 'react-redux'
const  SubmissionDetails = (props) => {
  const { submissionIndex, id, onClose } = props
  const [candidateData, setCandidateData] = useState([])
  const [candidateProfile, setCandidateProfile] = useState()
  const [isEditMode, setIsEditMode] = useState(false)

  const [submissionId, setSubmissionId] = useState()
  const getSubmissionsByIdDispatch = useDispatch()
  const getSubmissionsByIdSelector = useSelector((state) => state.getSubmissionById)
  const { submissionById, loading, error } = getSubmissionsByIdSelector
  const updateSubmission = useSelector((state) => state.updateSubmission)
  const { employeeInfo, loading: employeeLoading, error: employeeError } = updateSubmission;
  useEffect(() => {
    getSubmissionsByIdDispatch(getSubmissionsById(id))
  }, [])


  useEffect(() => {
    console.log("candidateData", candidateData)
  }, [candidateData])


  useEffect(() => {
    console.log("submissionIndex",submissionIndex)
    console.log("submissionById",submissionById?.submission.length)
    setCandidateData(submissionById?.submission[submissionIndex])
    console.log("candidateData", candidateData)
    // setCandidate((prevCandidate) => {
    // candidate?.submission.map((submission, index) => {
    //   if (index === submissionIndex) {
    //       setSubmissionId(submission._id)
    //   }})

  }, [submissionById])

    const  dispatch = useDispatch()

  //  useEffect(()=>{

  //     if(candidateData?.length == 0)
  //     {
  //     console.log("data.submissionData",data.submissionData)
  //     console.log("candidateIndex",submissionIndex)
  //     // console.log("candidateIndex",candidateIndex)

  //     const newData = data.submissionData.find((item) => item.id === id);

  //     console.log("newdtaa",newData)
  //      setCandidateData(newData.submission[submissionIndex])
  //      setCandidateProfile(newData)
  //      console.log("CandidateData",candidateData,candidateProfile)
  //     }
  //  })

  const handleSave = () => {
    const newData = candidateData;
    newData?.submission?.forEach((item, index) => {
      
      if (index === submissionIndex) {
        item.submittedBy = candidateData.submittedBy;
        item.dateOfSubbmision = candidateData.dateOfSubbmision;
        item.position = candidateData.position;
        item.status = candidateData.status;
        item.jobSourceType = candidateData.jobSourceType;
        item.jobSourceName = candidateData.jobSourceName;
        item.endClient = candidateData.endClient;
        item.jobDescription = candidateData.jobDescription;
        item.comments = candidateData.comments;
      }
    });


    console.log("submissionIndex, submissionID", submissionIndex, id)
    console.log("handlesave submission details", newData);

    dispatch(updateSubmissionAction(newData,id,onClose))
  };

  const handleEdit = () => {
    setIsEditMode(true)

  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;


    // Assign the input value directly to the corresponding property of candidateData
    setCandidateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //   useEffect(() => {
  //     const newData = data.submissionData.find((item) => item.id === id);
  //     if (newData && newData.subbmission && newData.subbmission.length > candidateIndex) {
  //       setCandidateData(newData.subbmission[candidateIndex]);
  //     }
  //   }, [id, candidateIndex]);
  return (
    <div>


      <div className='flex-row-jBetween-aCenter'>
        <h2>Basic Details</h2>
        {isEditMode ? (
          <button type="button" onClick={handleSave} className='edit-save-button'>
            Save
          </button>
        ) : (
          <button type="button" onClick={handleEdit} className='edit-save-button'>
            <span class="material-symbols-outlined">
              edit
            </span> Edit Information
          </button>
        )}
      </div>


       {/* {candidate?.submission?.map((item, index) => (
            (index == submissionIndex ? */}
      <div className='flex-row form'>
        <div className='flex-column '>
          <label>
            Submitted By
            <input type="text" 
            value={candidateData?.submittedBy?.fullName || `${candidateData?.submittedBy?.firstName} ${candidateData?.submittedBy?.lastName}`}  
            disabled={true} 
            name="submittedBy" 
            onChange={handleInputChange} />
          </label>
          <label>
            Date Of Submission
            <input type="date" name="dateOfSubbmision" value={candidateData?.dateOfSubmission?.slice(0, 10)} required disabled={!isEditMode} onChange={handleInputChange} />
          </label>
          <label>
            Position
            <input type="text" name="position" value={candidateData?.position} required disabled={!isEditMode} onChange={handleInputChange} />
          </label>
          <label>
            Job Source Type
            <input type="text" name="jobSourceType" value={candidateData?.jobSourceType} required disabled={!isEditMode} onChange={handleInputChange} />
          </label>
          <label>
            Job Source Name
            <input type="text" name="jobSourceName" value={candidateData?.jobSourceName} required disabled={!isEditMode} onChange={handleInputChange} />
          </label>
          <label>
            End Client
            <input type="text" name="endClient" value={candidateData?.endClient} required disabled={!isEditMode} onChange={handleInputChange} />
          </label>
          <label>
            Job Description
            <input type="textarea" name="jobDescription" value={candidateData?.jobDescription} required disabled={!isEditMode} onChange={handleInputChange} />
          </label>
        </div>
        <div className='flex-column'>
          <label>Submission Status
            <select
              disabled={!isEditMode}
              name="status"
              value={candidateData?.status}
              onChange={handleInputChange}
              //   onChange={(e) => handleQualificationChange(e.target.value, index)}
              //   className={isEditMode ? "" : "input-wout-border"}
              //   onChange={handleHighestQualificationChange}
              required
            >
              <option value="">Select Submission Status</option>
              <option value="Submitted to Prime Vendor">Submitted to Prime Vendor</option>
              <option value="Submitted to end Client">Submitted to end Client</option>
              <option value="Interview Requested">Interview Requested</option>
              <option value="Interview Scheduled">Interview Scheduled</option>
              <option value="Interview Completed">Interview Completed</option>
              <option value="Rejected">Rejected</option>
              <option value="No Updates">No Updates</option>
              <option value="Offer Expanded">Offer Expanded</option>

            </select>
          </label>
          <label>
            Comments
            <input
              type="textarea"
              name="comments"
              value={candidateData?.comments}
              disabled={!isEditMode}
              onChange={handleInputChange}
            />
          </label>
        </div>

      </div>
    </div>

  )
}

export default SubmissionDetails;