import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './reset.css';
const ResetPassword = ({ match }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { userId, token , companyId} = useParams();
//   const userId = match.params.userId;
//   const token = match.params.token;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
   
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/password/reset/${userId}/${token}/${companyId}`, {
        userId: userId,
        token: token,
        password: password,
        companyId:companyId
      });

      if (response.status === 200) {
        setSuccessMessage('Password reset successfully.');
      } else {
        console.log(response)
        setErrorMessage(response);
      }
    } catch (error) {
      console.log('Error:', error.response.data);
      setErrorMessage(error.response.data);
    }
  };

  return (
    <div className='reset-container'>
      <img src="https://static.stayjapan.com/assets/top/icon/values-7dd5c8966d7a6bf57dc4bcd11b2156e82a4fd0da94a26aecb560b6949efad2be.svg" />
      <h2>Reset Password</h2>
      {successMessage && <p className="success">{successMessage}</p>}
      {errorMessage && <p className="error">{errorMessage}</p>}
      <form onSubmit={handleResetPassword}>
        <div>
          <label>New Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
