import React, { useEffect } from 'react'
import data from '../../data/data'
import { useState } from 'react'
import './interviewPage.scss'
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG'
import ButtonWithBG from '../../components/Buttons/ButtonWithBG'
import InterviewPopUp from './InterviewPopUp/InterviewPopUp'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSubmissions, getSubmissionsById } from '../../action.js/submissionAction'
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import SearchInput from '../../components/SearchInput/SearchInput'
import InterviewForm from './InterviewForm'
import { createInterview, updateInterview } from '../../action.js/interviewAction'
const InterviewPage = () => {
    const [candidate, setCandidate] = useState(true)
    const [candidateData, setCandidateData] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const [scheduledCount, setscheduledCount] = useState()
    const [showCandidatePopUp, setShowCandidatePopUp] = useState(false)
    const [selectedSubmissionIndex, setSelectedSubmissionIndex] = useState(null);
    const [id , setId ] = useState()

    const navigatePage = useNavigate()


    const  getAllSubmissionDispatch = useDispatch()
    const getAllSubmissionSelector = useSelector((state)=>state.getAllSubmission)
    const {submissionList , loading: submissionListLoading , error: submissionListError} = getAllSubmissionSelector
    
    useEffect(()=>{
     
      getAllSubmissionDispatch(getAllSubmissions())
       
    },[])

    useEffect(()=>{
      if(submissionList)
      {
        setCandidateData(submissionList)
      }
    },[submissionList])



    const [nameFilter, setNameFilter] = useState('');
    const [interviewTypeFilter, setInterviewTypeFilter] = useState('');
    const [submittedByFilter, setSubmittedByFilter] = useState('');
    const [timeFilter, setTimeFilter] = useState('');
    const [endClientFilter, setEndClientFilter] = useState('');

    const filteredSubmissionList = submissionList?.filter((candidates) => {
      const matchingSubmissions = candidates?.submission?.filter((candidate) => {
        return (
          // (!statusFilter || candidate?.status?.toLowerCase().includes(statusFilter.toLowerCase())) &&
          (!timeFilter || candidate?.interview?.timeStart?.toLowerCase().includes(timeFilter.toLowerCase())) &&
          (!interviewTypeFilter || candidate?.interview?.interviewType?.toLowerCase().includes(interviewTypeFilter.toLowerCase())) &&
          (!endClientFilter || candidate?.endClient?.toLowerCase().includes(endClientFilter.toLowerCase())) &&
          (!nameFilter || (candidates?.candidateId?.fullName || candidates?.candidateId?.firstName).toLowerCase().includes(nameFilter.toLowerCase())) &&
          (!submittedByFilter || (candidate?.submittedBy?.fullName || candidate?.submittedBy?.firstName).toLowerCase().includes(submittedByFilter.toLowerCase()))
        );
      });
    
      // If there are matching submissions, add them to the candidate
      if (matchingSubmissions && matchingSubmissions.length > 0) {
        return {
          ...candidates,
          submission: matchingSubmissions,
        };
      } else {
        // If no matching submissions for this candidate, don't include the candidate
        return null;
      }
    });

    useEffect(()=>{
      console.log("nameFilter",nameFilter)
      console.log("interviewTypeFilter",interviewTypeFilter)
      console.log("submittedByFilter",submittedByFilter)

      console.log("endClientFilter",endClientFilter)

      
      console.log("filteredSubmissionList",filteredSubmissionList)
    },[filteredSubmissionList])




    // Delete functionality for interview
    const createInterviewDispatch = useDispatch()
    const updateInterviewDispatch = useDispatch()
    const  getSubmissionByIdDispatch = useDispatch()
    const getSubmissionByIdSelector = useSelector((state)=>state.getSubmissionById)
    const {submissionById , loading: submissionByIdLoading , error: submissionByIdError} = getSubmissionByIdSelector

    
    const getSubmissionById=async(id)=>{
        getSubmissionByIdDispatch(getSubmissionsById(id));
    }
    const handleDelete = async (interview_index, id, submission_id) => {
      console.log("interview_index", interview_index, "id", id);
      try {
        // Fetch the submission data by id
        await getSubmissionById(id);
        console.log("selectedOption===========================:", selectedOption);
    
        // Assuming selectedOption is the submission data fetched by id
        if (selectedOption !== null && interview_index !== "") {
          // Filter out the selected submission based on the index
          const updatedSubmissions = selectedOption.submission.map((submission, index) => {
            if (index === parseInt(interview_index)) {
              // Remove the interview for the selected submission
              return {
                ...submission,
                interview: null // Set interview to null to remove it
              };
            }
            return submission;
          });
    
          // Create a new object with the updated submissions
          const updatedSelectedOption = {
            ...selectedOption,
            submission: updatedSubmissions
          };
    
          console.log("updatedSelectedOption::::::::::::::::::::::::::::::", updatedSelectedOption);
          // Dispatch action or perform the necessary operation with updatedSelectedOption
          updateInterviewDispatch(updateInterview(updatedSelectedOption, submission_id))
          
        } else {
          alert("Please provide valid input");
        }
      } catch (error) {
        console.error("Error fetching submission data:", error);
      }
    };
    
   
    useEffect(()=>{
      setSelectedOption(submissionById);
      console.log("selecsubmissionByIdtedOption==========", submissionById)
  
    },[submissionById])
    

    const handleEdit = (index,id) => {
        console.log("idhnnjjjk",id)
        setSelectedSubmissionIndex(index)
        setId(id)
        setShowCandidatePopUp(true);
        // navigatePage(`/editCandidate/${id}`);
    };

    const closeCandidatePopUp = () => {
        setShowCandidatePopUp(false);
      };

      useEffect(()=>{
        console.log("submissionList",submissionList)
      },[submissionList])
       

    //   const sendMail = (fullName, firstName, lastName, timeStart, timeEnd, email, candidates, subIndex) => {
    //     console.log("candidates======>>>>>>>>>>>",candidates)
    //     candidates.submission[subIndex].interview.sendMail = true; 
    //     console.log("candidates======>>>>>>>>>>>",candidates)
    //     // Prepare email data
    //     const mailData = {
    //         recipientEmails: email,
    //         subject: `Interview Scheduled for ${fullName ||( firstName || lastName)}`,
    //         body: `<h2>Dear ${fullName ||( firstName || lastName)} ,</h2>
    //                <p>Your interview has been scheduled for ${timeStart} to ${timeEnd}.<p>
    //                <br/>
    //                <p>Best regards,</p>
    //                <p>Your Company Name</p>`
    //     };

    //     console.log("maiklData",mailData)
    //     // Redirect to bulk mail component with mail data
    //     navigatePage('/bulkEmail', { state: { mailData } });
    // };

    const sendMail = (fullName, firstName, lastName, timeStart, timeEnd, email, candidates, subIndex, subId) => {
      console.log("candidates======>>>>>>>>>>>",candidates)
      let formData = candidates
      // formData.submission[subIndex].interview.sendMail = true; 
      console.log("candidates======>>>>>>>>>>>",formData)
      // Prepare email data
      // const mailData = {
      //     recipientEmails: email,
      //     subject: `Interview Scheduled for ${fullName ||( firstName || lastName)}`,
      //     body: `<h2>Dear ${fullName ||( firstName || lastName)} ,</h2>
      //            <p>Your interview has been scheduled for ${timeStart} to ${timeEnd}.<p>
      //            <br/>
      //            <p>Best regards,</p>
      //            <p>Your Company Name</p>`
      // };

      // console.log("maiklData",mailData)
      // Redirect to bulk mail component with mail data
      navigatePage('/bulkEmail', { state: { formData,subIndex, subId } });
  };
  return (
    // <div className='container' >
    // <Sidebar />
    // <div className='HeaderMainPage'>
    //   <Header />
    //   <div className='main'>

    <div className='candidates'>
             <div className='breadcrumb'>
                <div>
                    <h1>Interviews</h1>
                    {/* <p>candidates{candidates ? "" : " / Candidates' Distribution"}</p> */}
                    <p>Interviews</p>
                </div>
                <div>


                </div>
                <div>
                    {/* <ButtonWithBG
                        buttonName={"Create Candidate"}
                        active={" "}
                        // onClick={() => navigatePage('/addNewCandidate')}
                    /> */}
{/* 
                    <ButtonWithBG
                        buttonName={"Schedule New Interview"}
                        boxBottomColor={"#FBBC05"}
                        active={""}
                        onClick={() => navigatePage('/interviewForm')}
                    /> */}
                </div>
            </div>



            <ButtonWithoutBG
                buttonName={"Interviews"}
                boxBottomColor={"#FBBC05"}
                active={candidate ? "" : "btnWoutBgActive"}
                onClick={() => setCandidate(true)}
            />
            <ButtonWithoutBG
                buttonName={"Schedule New Interview"}
                boxBottomColor={"#FBBC05"}
                active={candidate ? "btnWoutBgActive" : ""}
               
                onClick={() => setCandidate(false)}
            />
            {/* <hr style={{ marginLeft: "-40px", width: "calc(100% + 87px)" }}></hr> */}
            {/* <h2 className='totalPerson'>Total Candidate : <span>{scheduledCount}</span></h2> */}
            {candidate ?<>
        <table className="candidate-table">
  <thead>
    <tr>
      <th>Candidate's Name<br/>
      {/* <input
            type="text"
            placeholder="Filter by Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          /> */}
           <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Search"
        value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
      />
      </th>
      <th>Interview <br/>
      <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by InterviewTitle"
            value={interviewTypeFilter}
            onChange={(e) => setInterviewTypeFilter(e.target.value)}
      />
      {/* <input
            type="text"
            placeholder="Filter by InterviewTitle"
            value={interviewTypeFilter}
            onChange={(e) => setInterviewTypeFilter(e.target.value)}
          /> */}
      </th>
      <th>Submitted By<br/>
      <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Submitted By"
        value={submittedByFilter}
        onChange={(e) => setSubmittedByFilter(e.target.value)}
      />
      {/* <input
            type="text"
            placeholder="Filter by Submitted By"
            value={submittedByFilter}
            onChange={(e) => setSubmittedByFilter(e.target.value)}
          /> */}
      </th>
      <th>Time <br/>
      <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Time Start"
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
      />
      {/* <input
            type="text"
            placeholder="Filter by Time Start"
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
          /> */}
      </th>
      <th>End Clients<br/>
      
      <SearchInput
        style={{
          marginBottom: '10px', // Additional styles for the container div
        }}
        placeholder="Filter by Time Start"
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
      />
      {/* <input
            type="text"
            placeholder="Filter by End Clients"
            value={endClientFilter}
            onChange={(e) => setEndClientFilter(e.target.value)}
          /> */}
      </th>
      <th>Mail</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredSubmissionList?.map((candidates, index) => (
      <React.Fragment key={index}>
       
        {/* {candidates?.submission.map((candidate, subIndex) => (
             {candidate.interview.schedelu?
          <tr key={subIndex} style={{ marginBottom: "300px" }}>
            <td>
              <div className="flex-row" style={{ gap: "10px", alignItems: "center" }}>
                <div>
                  <img
                    src={candidates?.photo}
                    height="40px"
                    width="40px"
                    style={{ borderRadius: '50%' }}
                    alt=""
                  />
                </div>
                <div>{candidates?.name}</div>
              </div>
            </td>
            <td>{candidate?.interview?.interviewType}</td>
            <td>{candidate?.submittedBy}</td>
            
            <td>{candidate?.interview?.timeStart} : {candidate?.interview?.timeStart}</td>
            
            <td>{candidate?.endClient}</td>
            <td className="flex-column" style={{ alignItems: "center", gap: "10px" }}>
              <span
                className="material-symbols-outlined"
                style={{ cursor: "pointer" }}
                // onClick={() => handleEdit(index,candidates.id)}

              >
                edit_note
              </span>
              <div>
                <span
                  className="material-symbols-outlined"
                //   onClick={() => handleDelete(index)}
                  style={{ cursor: "pointer" }}
                >
                  delete
                </span>
              </div>
            </td> 
          </tr>:""}
        ))} */}
        {candidates?.submission.map((candidate, subIndex) => (
        ((candidate?.interview?.scheduled) ? (
          <tr key={subIndex} style={{ marginBottom: "300px" }}>
            <td>
              <div className="flex-row" style={{ gap: "10px", alignItems: "center" }}>
                <div>
                  <img
                    src={candidates?.photo || "/image/blank-profile.png"}
                    height="40px"
                    width="40px"
                    style={{ borderRadius: '50%' }}
                    alt=""
                  />
                </div>
                <div>{candidates?.candidateId?.fullName ||`${candidates?.candidateId?.firstName} ${candidates?.candidateId?.lastName}`}</div>
              </div>
            </td>
            <td>{candidate?.interview?.interviewType}</td>
            <td>{candidate?.submittedBy?.fullName || `${candidate?.submittedBy?.firstName} ${candidate?.submittedBy?.lastName}`}</td>
          <td>{candidate?.interview?.timeStart} to  {candidate?.interview?.timeEnd}</td>
          <td>{candidate?.endClient}</td>
          <td>{candidate?.interview?.sendMail ? "Sent" : "Not Send"}</td>
              <td className="flex-row" style={{ alignItems: "center", gap: "10px" }}>
              <span
                className="material-symbols-outlined"
                style={{ cursor: "pointer" }}
                onClick={() => handleEdit(subIndex, candidates.candidateId._id)}
              >
                edit_note
              </span>
              <div>
                <span
                  className="material-symbols-outlined"
                  onClick={() => handleDelete(subIndex, candidates.candidateId._id, candidate._id)}
                  style={{ cursor: "pointer" }}
                >
                  delete
                </span>
              </div>

              <span>
              <span class="material-symbols-outlined"
              style={{ cursor: "pointer" }}
              onClick={() => sendMail(candidates?.candidateId?.fullName, candidates?.candidateId?.firstName,candidates?.candidateId?.lastName, candidate?.interview?.timeStart, candidate?.interview?.timeEnd, candidates?.candidateId?.email, candidates, subIndex, candidate._id)}
              >
              forward_to_inbox
              </span>
              </span>
            </td>
          </tr>
        ) : null)
        ))}

      </React.Fragment>
    ))}
  </tbody>
</table>
</>:<InterviewForm/>}
{showCandidatePopUp && < InterviewPopUp submissionIndex={selectedSubmissionIndex}  id={id} onClose={closeCandidatePopUp} />}

    </div>
// </div>
// </div>
// </div>
  )
}

export default InterviewPage