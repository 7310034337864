import React from 'react'


const candidateHeader =  {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  gap: "40px",
  alignItems: "center",
  padding: "10px 5px",
  // background: "#FFFFFF",
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  // borderRadius: "4px",
  marginBottom: "20px"
}
const CandidatesHeader = (props) => {
  return (
    <div>
        <div className='candidateHeader' style={{borderBottom: `3px solid ${props.color}`,...candidateHeader}}>
            <p>{props.name} &nbsp; : &nbsp; {props.count}</p>
            {/* <p>{props.count}</p> */}
        </div>
        
    </div>
  )
}

export default CandidatesHeader