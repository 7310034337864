import {
    BULK_EMAIL_REQUEST,
    BULK_EMAIL_SUCCESS,
    BULK_EMAIL_FAIL,
  } from '../constants/constants';


export const bulkEmailReducers = (state = {}, action) => {
    switch (action.type) {
      case BULK_EMAIL_REQUEST:
        return { loading: true };
      case BULK_EMAIL_SUCCESS:
        return { loading: false, SuccessMessage: action.payload };
      case  BULK_EMAIL_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };