import React, { useEffect, useState } from 'react';
import data from '../../data/data';
import Select from "react-select";
import ButtonWithBG from '../../components/Buttons/ButtonWithBG';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubmissions, getSubmissionsById } from '../../action.js/submissionAction';
import { createInterview } from '../../action.js/interviewAction';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';

const InterviewForm = () => {
  const [interviewData, setInterviewData] = useState(
    {
      index:"",
      interviewTitle: "",
      interviewType: "",
      dateOfSubbmision: "",
      timezone: "",
      "timeStart": "",
      "timeEnd": "",
    }
  );

  const[dateError, setDateError] = useState(false)
  const  [StartTimeError, setStartTimeError] = useState(false)
  const  [endTimeError, setEndTimeError] = useState(false)


  const [selectCandidate, setSelectCandidate] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [candidateId,setCandidateId] = useState()
  const [candidateSelected, setCandidateSelected] = useState("")
  // useEffect(() => {
  //   if (selectCandidate.length == 0) {
  //     setSelectCandidate(data.submissionData)
  //     console.log("sinhnjsjn selectCandidate", selectCandidate)
  //   }
  // }, [])

const createInterviewDispatch = useDispatch()
  const  getAllSubmissionDispatch = useDispatch()
  const getAllSubmissionSelector = useSelector((state)=>state.getAllSubmission)
  const {submissionList , loading: submissionListLoading , error: submissionListError} = getAllSubmissionSelector
  
  useEffect(()=>{
   
    getAllSubmissionDispatch(getAllSubmissions())
    setSelectCandidate({...submissionList})
    // if(submissionList.length > 0) 
    // {
       console.log("hit firsttime")
    setSelectCandidate(submissionList?.candidateId)
    // }
    console.log("submissionlist++++++++++++++++++++++++++",submissionList)
  },[])


  const  getSubmissionByIdDispatch = useDispatch()
  const getSubmissionByIdSelector = useSelector((state)=>state.getSubmissionById)
  const {submissionById , loading: submissionByIdLoading , error: submissionByIdError} = getSubmissionByIdSelector


 
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    
    setInterviewData((prevInterviewData) => {
      const updatedInterviewData = {
        ...prevInterviewData,
        [field]: value,
        // index: index // Store the index when radio button is selected
      };

  
      return updatedInterviewData;
    });
  };

 
  const handleIndexChange = (e, field,index) => {
    const { value } = e.target;
    setInterviewData((prevInterviewData) => {
      const updatedInterviewData = {
        ...prevInterviewData,
        [field]: value,
        index: index // Store the index when radio button is selected
      };
      return updatedInterviewData;
    });
  };

  const handleSelect = (option) => {
    
    const newValue = option;
    console.log("option", option)
    getSubmissionByIdDispatch(getSubmissionsById(option.value))
    setCandidateSelected(option.label)
     setCandidateId(option.value)
    console.log("selectedOption", selectedOption)
  };

  useEffect(()=>{
    setSelectedOption(submissionById);
    console.log("selecsubmissionByIdtedOption", submissionById)

  },[submissionById])

  useEffect(() => {
    console.log("selectCandidatexxxxxxxxxxxxxxxxxxxxxxxxx", selectCandidate)
  }, [selectCandidate])

  useEffect(()=>{
    console.log("gooooo+++",candidateId)
  },[candidateId])

  useEffect(()=>{
    console.log("gooooo+++0000000000000000",selectedOption)
  },[selectedOption])
  useEffect(()=>{
    console.log("interviewData=====================",interviewData)
  },[interviewData])

  // const handleSave = () => {
  //   // Retrieve the selected index from the input field directly
  //   const selectedIndex = document.querySelector('input[name="index"]:checked');
  //   console.log("selectedIndex----------",selectedIndex)
  //   // Check if an index is selected via radio button
  //   if (!selectedIndex) {
  //     alert("Please select a submission to schedule an interview.");
  //     return;
  //   }
  
  //   // Find the selected submission by index
  //   const selectedSubmission = selectedOption.submission.find((submission, index) => index.toString() === selectedIndex.value);
  
  //   if (!selectedSubmission) {
  //     alert("Invalid submission index.");
  //     return;
  //   }
  
  //   // Construct the interview object
  //   const interview = {
  //     interviewTitle: interviewData.interviewTitle,
  //     interviewType: interviewData.interviewType,
  //     timeStart: interviewData.timeStart,
  //     timeEnd: interviewData.timeEnd,
  //     scheduled: true,
  //     dateOfSubmission: interviewData.dateOfSubbmision,
  //     timezone: interviewData.timezone,
  //   };
  
  //   // Update the selected submission with the interview details
  //   const updatedSubmission = {
  //     ...selectedSubmission,
  //     interview: interview,
  //   };
  
  //   // Construct the updated submission list
  //   const updatedSubmissionList = selectedOption.submission.map((submission, index) =>
  //     index.toString() === selectedIndex.value ? updatedSubmission : submission
  //   );
  
  //   // Dispatch action to update the submission
  //   const updatedSelectedOption = {
  //     ...selectedOption,
  //     submission: updatedSubmissionList,
  //   };
  
  //   // Dispatch action to update the submission in the Redux store
  //   // Replace this with your actual dispatch logic
  //   console.log("Updated Submission:", updatedSelectedOption);
  //   // createInterviewDispatch(createInterview(updatedSelectedOption));
  
  //   alert("Interview scheduled successfully.");
  // };

//   const handleSave = () => {
// // Construct the desired format
// var desired_format = {
//   "candidateId": candidateId,
//   "submission": []
// };

// // Loop through each submission in the first JSON
// for (var i = 0; i < selectedOption.submission.length; i++) {
//   var submission = selectedOption.submission[i];
  
//   // Create a new submission object in the desired format
//   var submission_data = {
//       "submittedBy": submission.submittedBy,
//       "dateOfSubmission": submission.dateOfSubmission,
//       "position": submission.position,
//       "status": submission.status,
//       "jobSourceType": submission.jobSourceType,
//       "jobSourceName": submission.jobSourceName,
//       "endClient": submission.endClient,
//       "jobDescription": submission.jobDescription,
//       "comments": submission.comments,
//       "city": submission.city,
//       "state": submission.state,
//       "country": submission.country,
//       "interview": {
//           "interviewTitle": interviewData.interviewTitle,
//           "interviewType": interviewData.interviewType,
//           "timeStart": interviewData.timeStart,
//           "timeEnd": interviewData.timeEnd,
//           "scheduled": true,
//           "dateOfSubmission": interviewData.dateOfSubbmision,
//           "timezone": interviewData.timezone,
//       }
//   };
  
//   // Add the submission_data to the submission array in desired_format
//   desired_format.submission.push(submission_data);
// }
    
//     if(selectedOption!==null &&  (interviewData.index != ""||null) )
//     {
//       console.log("desired_format",desired_format)
//       createInterviewDispatch(createInterview(desired_format))
//     alert("submitted form")
//     }
//     else{
//       alert("fill all field")
//     }
//     console.log('Interview data saved:', interviewData);
//   };
function convertToMinutes(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
}
const handleSave = () => {
  // const today = new Date();
  // const selectedDate = new Date(interviewData.dateOfSubbmision);
  
  // console.log("today", today)
  // console.log("selectedDate", selectedDate)
  // // Check if the selected date is less than today's date
  // if (selectedDate < today) {
  //   setDateError(true)
  //   return

  // }
  // else{
  //   setDateError(false)
  // }

/*
  const today = new Date();
  const selectedDate = new Date(interviewData.dateOfSubbmision);

  // Extract date part without time information
  const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const selectedDateOnly = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

  // Check if dateOfSubmission is not set or invalid
  if (!interviewData.dateOfSubbmision || isNaN(selectedDate)) {
    setDateError(true);
    return;
  }

  // Check if the selected date is less than today's date
  if (selectedDateOnly < todayDateOnly) {
    setDateError(true);
    return;
  }
  else{
    setDateError(false);
  }
*/
    // Convert time strings to minutes since midnight
    const startTime = convertToMinutes(interviewData.timeStart);
    const endTime = convertToMinutes(interviewData.timeEnd);

    // Check if start time is greater than end time
    if (interviewData.timeEnd && startTime > endTime) {
      setStartTimeError(true)
      return
      // Optionally, you can reset the start time to its previous value or empty string
      // updatedInterviewData.timeStart = prevInterviewData.timeStart || '';
    }
    // Check if end time is less than start time
    else if (interviewData.timeStart && endTime < startTime) {
     setEndTimeError(true)
     return
      // Optionally, you can reset the end time to its previous value or empty string
      // updatedInterviewData.timeEnd = prevInterviewData.timeEnd || '';
    }
    else{
      setStartTimeError(false)
      setEndTimeError(false)
    }
    


console.log("interviewData.timeStart",interviewData.timeStart)
console.log("interviewData.timeEnd",interviewData.timeEnd)

  if (selectedOption !== null && interviewData.index !== "") {
    // Filter out the selected submission based on the index
    const updatedSubmissions = selectedOption.submission.map((submission, index) => {
      if (index === parseInt(interviewData.index)) {
        return {
          ...submission,
          interview: {
            interviewTitle: interviewData.interviewTitle,
            interviewType: interviewData.interviewType,
            timeStart: interviewData.timeStart,
            timeEnd: interviewData.timeEnd,
            scheduled: true,
            dateOfSubmission: interviewData.dateOfSubbmision,
            timezone: interviewData.timezone,
          }
        };
      }
      return submission;
    });

    // Create a new object with the updated submissions
    const updatedSelectedOption = {
      ...selectedOption,
      submission: updatedSubmissions
    };

    // console.log("updatedSelectedOption",updatedSelectedOption)
    // Dispatch action to update the selected option
    createInterviewDispatch(createInterview(updatedSelectedOption));
    getAllSubmissionDispatch(getAllSubmissions())
    // alert("Form submitted");
  } else {
    alert("Please fill all fields");
  }
};

  return (
    // <div className='container' >
    // <Sidebar />
    // <div className='HeaderMainPage'>
    //   <Header />
    //   <div className='main'>

    <div className='candidates'>

      {/* <div className='breadcrumb'>
        <div>
          <h1>Interview</h1>
        //  <p>candidates{candidates ? "" : " / Candidates' Distribution"}</p> 
          <p>Interview/Schedule New Interview</p>
        </div>
        <div>


        </div>
        //  <div>
        //   <ButtonWithBG
        //     buttonName={"Create Candidate"}
        //     active={" "}
        //   // onClick={() => navigatePage('/addNewCandidate')}
        //   />

        //   <ButtonWithBG
        //     buttonName={"Schedule New Interview"}
        //     boxBottomColor={"#FBBC05"}
        //     active={""}
        //   />
        </div> 
      </div> */}

      <div className="flex-row-jAround form" style={{ padding: "50px", gap: "10px" }}>

        <div>
          <label className='width-auto-fit'>Select Candidate
            {/* <Select

              options={submissionList}
              placeholder="Select Candidates"
              value={selectCandidate ? selectCandidate.fullName : ""}
              getOptionLabel={(option) => option.map(candidate => candidate.candidateId.fullName)}
              // getOptionValue={(option) => option._id}
              onChange={handleSelect}
              isSearchable={true}
            // isMulti
            /> */}

<Select
  options={submissionList?.map(candidate => ({
    // label: `${(candidate?.candidateId?.firstName &&  candidate?.candidateId?.lastName) || candidate?.candidateId?.fullName  }`,
    label: candidate?.candidateId?.fullName ? candidate.candidateId.fullName : `${candidate?.candidateId?.firstName || ''} ${candidate?.candidateId?.lastName || ''}`,
    value: candidate?.candidateId?._id
  }))}
  placeholder={  candidateSelected || "Select Candidate" }
   value={candidateId} // Assuming you have a state variable to store the selected candidate
  onChange={handleSelect}
  isSearchable={true}
  required
/>
            {/* <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
      /> */}
            {/* <ul>
        {filteredOptions.map((option) => (
          <li
            key={option.value}
            onClick={() => handleSelect(option)}
            className={selectedOption === option ? 'selected' : ''}
           
          >
            {option.name}
          </li>
        ))}
      </ul> */}
          </label>
          <label>
            InterView Title
            <input value={interviewData?.interviewTitle}
              onChange={(e) => handleInputChange(e, 'interviewTitle')}
              required
            />
          </label>
          <label>
            Mode of Interview
            <input value={interviewData?.interviewType}
              onChange={(e) => handleInputChange(e, 'interviewType')}
              required
            />
          </label>

          <label>
            Interview Date <span style={{color:"red", fontSize:"12px"}}>{dateError ? "Interview date cannot be in the past." :""} </span>
            <input type="date" value={interviewData?.dateOfSubbmision}
              onChange={(e) => handleInputChange(e, 'dateOfSubbmision')}
              required
            />
          </label>

          <label>
            Time Zone
            <select
              value={interviewData?.timeZone}
              onChange={(e) => handleInputChange(e, 'timeZone')}
              id='option'
              required
            >
              <option value="IST" className='options'>IST (Indian Standard Time)</option>
              <option value="UTC" className='options'>UTC (Coordinated Universal Time)</option>
              <option value="PST" className='options'>PST (Pacific Standard Time)</option>
              <option value="EST" className='options'>EST (Eastern Standard Time)</option>
              <option value="CST" className='options'>CST (Central Standard Time)</option>
              <option value="MST" className='options'>MST (Mountain Standard Time)</option>
              <option value="GMT" className='options'>GMT (Greenwich Mean Time)</option>
              <option value="CET" className='options'>CET (Central European Time)</option>
              <option value="AEDT" className='options'>AEDT (Australian Eastern Daylight Time)</option>
              <option value="JST"  className='options'>JST (Japan Standard Time)</option>

            </select>

          </label>
          <div>
            <label>
              Start Time
              <input type="time" value={interviewData?.timeStart}
                onChange={(e) => handleInputChange(e, 'timeStart')}
                required
              />
              <p className='error'>{StartTimeError ? "Start time cannot be greater than end time ( Use 24 hr format )" : ""}</p>
            </label>
            <label>
              End Time
              <input type="time" value={interviewData?.timeEnd}
                onChange={(e) => handleInputChange(e, 'timeEnd')}
                required
              />
            <p className='error'>{endTimeError ? "End time cannot be less than start time ( Use 24 hr format )" : ""}</p>
            </label>
          </div>

          <div className='save-button' onClick={handleSave}>Save</div>
        </div>
      

      <div >
        <h3>
          Select the Submission to  schedule <br/> Interview for:
        </h3>
        <h3>Recent Submission</h3>
        <p>Hi! {selectedOption?.candidateId?.fullName || `${selectedOption?.candidateId?.firstName} ${selectedOption?.candidateId?.lastName}`}</p>
        {
          selectedOption?.submission?.every(item => item.interview) ? (
            <p style={{color:"#C70039"}}>Need at least one submission for schedule an Interview . <br/> (You scheduled all interview for your previous submission)</p>
          ) : null
        }

     {   selectedOption?.submission?.map((item, index)=>(
      <>
       {item?.interview?.scheduled  ? ""
        : 
        <div className='flex-row-aCenter pform' style={{gap:"20px", marginBottom:"20px"}}>
          
        <input type="radio" name="index" style={{width: "auto"}} required onChange={(e) => handleIndexChange(e, 'index', index)}/>
        <img src={selectedOption?.photo  || "/image/blank-profile.png"} alt="" style={{ height:"50px", width:"50px", borderRadius:"50%",}} />
        <p>{selectedOption?.candidateId?.fullName || `${selectedOption?.candidateId?.firstName} ${selectedOption?.candidateId?.lastName}`}</p>
        <div style={{background:"#ffffff", borderLeft:"6px solid #4267b2", padding:"10px"}}>
          <p>Submission Date:{item.dateOfSubbmision} </p>
          <p> Vendor: {item.jobSourceType}</p>
          <p> End Client: {item.endClient} </p>
        </div>
      </div>
       
      }  
      </>))
     
      }
      </div>
      </div>
    </div>
    // </div>
    // </div>
    // </div>
  );
};

export default InterviewForm;
