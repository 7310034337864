import React, { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CandidatesHeader from "./CandidatePage/CandidatesHeader";
// import CandidatesHeader from "./CandidatesHeader";

const candidateData = [
  // Candidate objects
  {
    name: "David",
    assignTo: "",
    jobStatus: "New Candidates"
  },
  {
    name: "Jane Smith",
    assignTo: "John Doe",
    jobStatus: "onBench"
  },
  {
    name: "John Doe",
    assignTo: "",
    jobStatus: "New Candidates"
  },
  {
    name: "Sarah Johnson",
    assignTo: "Mark Smith",
    jobStatus: "Completing Projects"
  },
  {
    name: "Mark Smith",
    assignTo: "John Doe",
    jobStatus: "onBench"
  },
  {
    name: "Emily Brown",
    assignTo: "",
    jobStatus: "New Candidates"
  },
  {
    name: "Michael Davis",
    assignTo: "Sarah Johnson",
    jobStatus: "Completing Projects"
  },
  {
    name: "Linda Wilson",
    assignTo: "",
    jobStatus: "New Candidates"
  },
  {
    name: "Ryan Thompson",
    assignTo: "",
    jobStatus: "New Candidates"
  },
  {
    name: "Samantha Turner",
    assignTo: "Mark Smith",
    jobStatus: "Completing Projects"
  }
];

// const Candidate = ({ candidate, onCandidateDrop }) => {
//   const [{ isDragging }, drag] = useDrag({
//     item: { type: "CANDIDATE", candidate },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging()
//     })
//   });

//   const [{ isOver }, drop] = useDrop({
//     accept: "CANDIDATE",
//     drop: (item) => {
//       const droppedCandidate = item.candidate;
//       const updatedCandidate = {
//         ...droppedCandidate,
//         assignTo: candidate.assignTo
//       };
//       onCandidateDrop(updatedCandidate);
//     },
//     canDrop: () => candidate.jobStatus !== "New Candidates",
//     collect: (monitor) => ({
//       isOver: monitor.isOver()
//     })
//   });

//   const opacity = isDragging ? 0.5 : 1;
//   const backgroundColor =
//     isOver && candidate.jobStatus !== "New Candidates"
//       ? "#e0e0e0"
//       : "transparent";
//   const listStyleType = "none";
//   return (
//     <li ref={drop} style={{ opacity, backgroundColor, listStyleType }}>
//       <div ref={drag}>{candidate.name}</div>
//     </li>
//   );
// };


const Candidate = ({ candidate, onCandidateDrop }) => {
  const jobStatus = candidate.jobStatus;
  
  const [{ isDragging }, drag] = useDrag({
    item: { type: "CANDIDATE", candidate },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: jobStatus === "New Candidates" // Only allow dragging if jobStatus is "New Candidates"
  });

  const [{ isOver }, drop] = useDrop({
    accept: "CANDIDATE",
    drop: (item) => {
      const droppedCandidate = item.candidate;
      const updatedCandidate = {
        ...droppedCandidate,
        assignTo: candidate.assignTo
      };
      onCandidateDrop(updatedCandidate);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const opacity = isDragging ? 0.5 : 1;
  const backgroundColor = isOver ? "#e0e0e0" : "transparent";
  const listStyleType = "none";

  return (
    <li ref={drop} style={{ opacity, backgroundColor, listStyleType }}>
      <div ref={drag}>{candidate.name}</div>
    </li>
  );
};

const CandidateGroup = ({ assignTo, candidates, onCandidateDrop }) => {
  return (
    <div>
      <div style={{ minWidth: "240px" }}>
        <CandidatesHeader
          count={candidates.length}
          name={assignTo}
          color={"#00609A"}
        />
        <ul>
          {candidates.map((candidate) => (
            <Candidate
              key={candidate.name}
              candidate={candidate}
              onCandidateDrop={onCandidateDrop}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

// const CandidateList = ({ candidateData }) => {
//   const [updatedCandidateData, setUpdatedCandidateData] = useState(
//     candidateData
//   );
//   const [candidateGroups, setCandidateGroups] = useState([]);

//   const handleCandidateDrop = (updatedCandidate) => {
//     const updatedData = updatedCandidateData.map((candidate) => {
//       if (candidate.name === updatedCandidate.name) {
//         return { ...candidate, assignTo: updatedCandidate.assignTo };
//       }
//       return candidate;
//     });
//     setUpdatedCandidateData(updatedData);
//   };

//   useEffect(() => {
//     const updatedData = candidateData.map((candidate) => {
//       const foundCandidate = updatedCandidateData.find(
//         (updatedCandidate) => updatedCandidate.name === candidate.name
//       );
//       return foundCandidate || candidate;
//     });
//     setUpdatedCandidateData(updatedData);
//   }, [candidateData]);

//   useEffect(() => {
//     const updatedGroups = {};

//     for (const candidate of updatedCandidateData) {
//       const assignTo = candidate.assignTo;

//       if (!updatedGroups.hasOwnProperty(assignTo)) {
//         updatedGroups[assignTo] = [];
//       }

//       updatedGroups[assignTo].push(candidate);
//     }

//     setCandidateGroups(updatedGroups);
//   }, [updatedCandidateData, candidateData]);

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         width: "70vw",
//         overflow: "scroll"
//       }}
//     >
//       {Object.keys(candidateGroups).map((assignTo) => (
//         <CandidateGroup
//           key={assignTo}
//           assignTo={assignTo}
//           candidates={candidateGroups[assignTo]}
//           onCandidateDrop={handleCandidateDrop}
//         />
//       ))}
//     </div>
//   );
// };


const CandidateList = ({ candidateData }) => {
  const [updatedCandidateData, setUpdatedCandidateData] = useState(
    candidateData
  );
  const [candidateGroups, setCandidateGroups] = useState([]);

  const handleCandidateDrop = (updatedCandidate) => {
    const updatedData = updatedCandidateData.map((candidate) => {
      if (candidate.name === updatedCandidate.name) {
        return { ...candidate, assignTo: updatedCandidate.assignTo };
      }
      return candidate;
    });
    setUpdatedCandidateData(updatedData);
  };

  useEffect(() => {
    const updatedData = candidateData.map((candidate) => {
      const foundCandidate = updatedCandidateData.find(
        (updatedCandidate) => updatedCandidate.name === candidate.name
      );
      return foundCandidate || candidate;
    });
    setUpdatedCandidateData(updatedData);
  }, [candidateData]);

  useEffect(() => {
    const updatedGroups = {};

    for (const candidate of updatedCandidateData) {
      const assignTo = candidate.assignTo;

      if (!updatedGroups.hasOwnProperty(assignTo)) {
        updatedGroups[assignTo] = [];
      }

      updatedGroups[assignTo].push(candidate);
    }

    const sortedGroups = Object.entries(updatedGroups).sort((a, b) =>
      a[0].localeCompare(b[0])
    );

    setCandidateGroups(sortedGroups);
  }, [updatedCandidateData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "70vw",
        overflow: "scroll"
      }}
    >
      {candidateGroups.map(([assignTo, candidates]) => (
        <CandidateGroup
          key={assignTo}
          assignTo={assignTo}
          candidates={candidates}
          onCandidateDrop={handleCandidateDrop}
        />
      ))}
    </div>
  );
};

const   Test = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <CandidateList candidateData={candidateData} />
    </DndProvider>
  );
};

export default Test;
