import React from 'react';
import ButtonWithBG from '../../components/Buttons/ButtonWithBG';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG';
import data from '../../data/data';
import { useState } from 'react';
import './employees.css';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployeeById, getAllEmployee } from '../../action.js/employeeAction';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import SearchInput from '../../components/SearchInput/SearchInput';
import EmployeeAccess from './EmployeeAccess';
import AddNewEmployee from './AddNewEmployee'

const EmployeeMain = () => {
  const [employee, setEmployee] = useState(true);
  const [employeeData, setEmployeeData] = useState([]);
  const [access,setAccess] = useState(false);
  const [addNew,setAddNew] = useState(false)

  const effect = useRef(true);
  const navigatePage = useNavigate();

  const getAllEmployeeDispatch = useDispatch();
  const getAllEmployeeSelector = useSelector((state) => state.getAllEmployee);
  const { employeesInfo, loading, error } = getAllEmployeeSelector;
  useEffect(() => {
    console.log('hit firsttime');
    getAllEmployeeDispatch(getAllEmployee());
    console.log('employeesInfo................', employeesInfo);
  }, []);

  useEffect(() => {
    // if (effect.current == true) {
    //     effect.current = false
    // console.log(data.employees)
    setEmployeeData(employeesInfo);

    // }
  }, [employeesInfo]);

  useEffect(() => {
    console.log('employeeData', employeeData);
  }, [employeeData]);
  // const [employeeData, setEmployee] = useState()

  // Search Functionality
  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [designationSearch, setDesignationSearch] = useState('');
  const [contactSearch, setContactSearch] = useState('');

  const filteredList = employeeData?.filter((candidates) => {
    // const matchingSubmissions = candidates?.submission?.filter((candidate) => {
    return (
      // (!statusFilter || candidate?.status?.toLowerCase().includes(statusFilter.toLowerCase())) &&
      // (!jobSourceTypeFilter || candidate?.jobSourceType?.toLowerCase().includes(jobSourceTypeFilter.toLowerCase())) &&
      (!nameSearch ||
        candidates?.firstName
          ?.toLowerCase()
          .includes(nameSearch.toLowerCase())) &&
      (!emailSearch ||
        candidates?.email?.toLowerCase().includes(emailSearch.toLowerCase())) &&
      (!designationSearch ||
        candidates?.designation
          ?.toLowerCase()
          .includes(designationSearch.toLowerCase())) &&
      (!contactSearch ||
        candidates?.contactNumber
          ?.toLowerCase()
          .includes(contactSearch.toLowerCase()))
    );
    // });

    // // If there are matching submissions, add them to the candidate
    // if (matchingSubmissions && matchingSubmissions.length > 0) {
    //   return {
    //     ...candidates,
    //     submission: matchingSubmissions,
    //   };
    // } else {
    //   // If no matching submissions for this candidate, don't include the candidate
    //   return null;
    // }
  });

  const deleteEmployeeDispatch = useDispatch()
  const handleDelete = (id) => {
    deleteEmployeeDispatch(deleteEmployeeById(id))
  };

  const handleEdit = (employeeId) => {
    navigatePage(`/editEmployee/${employeeId}`);
  };

  return (
    // <div className='container' >
    // <Sidebar />
    // <div className='HeaderMainPage'>
    //   <Header />
    //   <div className='main'>

    <div className="employees">
      <div className="breadcrumb">
        <div>
          <h1>EMPLOYEES</h1>
          {/* <p>Employees{employees ? "" : " / Candidates' Distribution"}</p> */}
          <p>Employees</p>
        </div>
        <div></div>
        {/* <div>
          <ButtonWithBG
            buttonName={'Add New Employee'}
            active={' '}
            onClick={() => navigatePage('/addNewEmployee')}
          />

          <ButtonWithBG
            buttonName={'PERMISSION SETTING'}
            boxBottomColor={'#FBBC05'}
            active={''}
            onClick={() => navigatePage('/employeeAccess')}
          />
        </div> */}
      </div>

      <ButtonWithoutBG
        buttonName={'Employees'}
        boxBottomColor={'#FBBC05'}
        active={employee ? '' : 'btnWoutBgActive'}
        onClick={() => {setEmployee(true);setAccess(false);setAddNew(false)}}
      />

      <ButtonWithoutBG
        buttonName={'Add New Employee'}
        active={addNew ? '' : 'btnWoutBgActive'}
        onClick={() => {setEmployee(false);setAccess(false);setAddNew(true)}}
        // onClick={() => navigatePage('/addNewEmployee')}
      />

      <ButtonWithoutBG
        buttonName={'PERMISSION SETTING'}
        boxBottomColor={'#FBBC05'}
        active={access ? '' : 'btnWoutBgActive'}
        onClick={() => {setEmployee(false) ;setAccess(true);setAddNew(false)}}
        // onClick={() => navigatePage('/employeeAccess')}
      />
      {/* <ButtonWithoutBG
                buttonName={"Schedule Interview"}
                boxBottomColor={"#FBBC05"}
                active={employee ? "" : "btnWoutBgActive"}
                onClick={() => setEmployee(false)}
            /> */}
      {/* <hr style={{ marginLeft: "-40px", width: "calc(100% + 87px)" }}></hr> */}
      {employee ?<>
      <h2 className="totalPerson">
        Total Employee : <span>{employeeData?.length}</span>
      </h2>
      <table className="employee-table">
        <thead>
          <tr>
            <th>
              Employee Name <br />
              <SearchInput
                style={{
                  marginBottom: '10px', // Additional styles for the container div
                }}
                placeholder="Search by Employee Name"
                value={nameSearch}
                onChange={(e) => setNameSearch(e.target.value)}
              />
              {/* <input
                        type="text"
                        placeholder="Search by Employee Name"
                        value={nameSearch}
                        onChange={(e) => setNameSearch(e.target.value)}
                        /> */}
            </th>
            <th>
              Email Address
              <br />
              <SearchInput
                style={{
                  marginBottom: '10px', // Additional styles for the container div
                }}
                placeholder="Search by Email Address"
                value={emailSearch}
                onChange={(e) => setEmailSearch(e.target.value)}
              />
              {/* <input
                            type="text"
                            placeholder="Search by Email Address"
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            /> */}
            </th>
            <th>
              Designation <br />
              <SearchInput
                style={{
                  marginBottom: '10px', // Additional styles for the container div
                }}
                placeholder="Search by Designation"
                value={designationSearch}
                onChange={(e) => setDesignationSearch(e.target.value)}
              />
              {/* <input
                            type="text"
                            placeholder="Search by Designation"
                            value={designationSearch}
                            onChange={(e) => setDesignationSearch(e.target.value)}
                            /> */}
            </th>
            {/* <th>Reporting To</th> */}
            <th>
              Contact No
              <br />
              <SearchInput
                style={{
                  marginBottom: '10px', // Additional styles for the container div
                }}
                placeholder="Search by Contact No"
                value={contactSearch}
                onChange={(e) => setContactSearch(e.target.value)}
              />
              {/* <input
                            type="text"
                            placeholder="Search by Contact No"
                            value={contactSearch}
                            onChange={(e) => setContactSearch(e.target.value)}
                            /> */}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredList?.map((employee, index) => (
            <tr key={index} style={{ marginBottom: '300px' }}>
              <td>
                <div
                  className="flex-row"
                  style={{ gap: '10px', alignItems: 'center' }}
                >
                  <div>
                    <img
                      src={
                        employee.profilePicture || '/image/blank-profile.png'
                      }
                      height="40px"
                      width="40px"
                      style={{ borderRadius: '50%' }}
                      alt=""
                    />
                  </div>
                  <div>{employee?.fullName || `${employee.firstName} ${employee?.lastName}`}</div>
                </div>
              </td>
              <td>{employee?.email}</td>
              <td>{employee?.designation}</td>
              {/* <td>{employee.reportingTo}</td> */}
              <td>{employee?.contactNumber}</td>
              <td
                className="flex-row"
                style={{ alignItems: 'center', gap: '10px' }}
              >
                <span
                  class="material-symbols-outlined"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleEdit(employee?._id)}
                >
                  edit_note
                </span>
                <div>
                  <span class="material-symbols-outlined" onClick={() => handleDelete(employee?._id)} style={{ cursor: "pointer" }}>
                                        delete
                                    </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </>:""}
      {access ? <EmployeeAccess/> :"" }
      {addNew ? <AddNewEmployee/> :"" }
    </div>
    // </div>
    // </div>
    // </div>
  );
};

export default EmployeeMain;
