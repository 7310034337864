import React, { useState,useEffect } from 'react';

function ProfessionalExperience(props) {
    const  { onPrevious, onNext ,data,onChange} = props;
     const [experience, setExperience] = useState(data)
   
     const [experienceData, setExperienceData] = useState([]);
   
     const handleExperienceSave = () => {
      //  const isExperienceEmpty = experience.some(
      //    (exp) => Object.values(exp).some((value) => value === '')
      //  );
       const isExperienceEmpty = experience.some((exp) =>
       ['company', 'role', 'startDate'].every(
         (key) => exp[key] === ''
       )
     );
     
       if (isExperienceEmpty) {
         alert('Please fill all fields');
       } else {
   
         
         const newdata =[...experience]
         
         setExperience(newdata)
         onChange(experience)
         setExperienceData(experience)  
       }
     };
     useEffect(()=>{
       const isEmpty = experience.some(
         (experience) =>
           Object.values(experience).some((value) => value === '')
       );
       if(isEmpty)
       {
         console.log("Empty Experience")
       }
       else{
         setExperienceData(experience)  
       }
       
      },[])
     const handleAddCompany = () => {
       setExperience((prevExperience) => [
         ...prevExperience,
         {
           company: '',
           role: '',
           startDate: '',
           endDate: '',
           description: '',
         },
       ]);
     };
   
     const handleRemoveCompany = (index) => {
       setExperience((prevExperience) => {
         const updatedExperience = [...prevExperience];
         updatedExperience.splice(index, 1);
         return updatedExperience;
       });
     };
   
     const handleChange = (e, index) => {
       const { name, value } = e.target;
       setExperience((prevExperience) => {
         const updatedExperience = [...prevExperience];
         updatedExperience[index] = {
           ...updatedExperience[index],
           [name]: value,
         };
         return updatedExperience;
       });
     };
   
     return (
       <div>
         {/* <h3>Professional Experience</h3> */}
         <div style={{ margin: "30px" }}>
           {experience.map((exp, index) => (
             <div key={index} className='box-shadow' >
               <div className='flex-row' >
                 <div>
                   <label> Company Name
                     <input
                       type="text"
                       name="company"
                       value={exp.company}
                       onChange={(e) => handleChange(e, index)}
                       placeholder="Company Name"
                       required
                     />
                   </label>
   
                   <label>Role
                     <input
                       type="text"
                       name="role"
                       value={exp.role}
                       onChange={(e) => handleChange(e, index)}
                       placeholder="Role"
                       required
                     />
                   </label>
                 </div>
                 <div>
                   <label>Start Date
                     <input
                       type="date"
                       name="startDate"
                       value={exp.startDate}
                       onChange={(e) => handleChange(e, index)}
                       placeholder="Start Date"
                       required
                     /></label>
   
                   <label>End Date
                     <input
                       type="date"
                       name="endDate"
                       value={exp.endDate}
                       onChange={(e) => handleChange(e, index)}
                       placeholder="End Date"
                      //  required
                     />
                   </label>
                 </div>
                 <div> <button onClick={() => handleRemoveCompany(index)} className="remove-button" style={{marginTop:"20px"}}>Remove</button></div>
               </div>
               <label> Description
                 <textarea
                   name="description"
                   style={{ height: "100px", marginBottom:"30px" }}
                   value={exp.description}
                   onChange={(e) => handleChange(e, index)}
                   placeholder="Description"
                 />
               </label>
              
   
             </div>
           ))}
   
   <button onClick={handleAddCompany} className='add' style={{ marginTop: "30px" }}>+ Add Company</button><br/>
           <button onClick={handleExperienceSave} className='save-button' style={{ margin: "30px auto" }}>Save</button>
           <table style={{ width: "100%" }}>
             <thead>
               <tr>
                 <th>Company</th>
                 <th>Role</th>
                 <th>Start Date</th>
                 <th>End Date</th>
                 <th>Description</th>
               </tr>
             </thead>
             <tbody>
               {experienceData.map((data, index) => (
                 <tr key={index}>
                   <td>{data.company}</td>
                   <td>{data.role}</td>
                   <td>{data.startDate}</td>
                   <td>{data.endDate}</td>
                   <td>{data.description}</td>
                 </tr>
               ))}
             </tbody>
           </table>
           <div className='flex-row-between'>
             <button onClick={onPrevious} className='next-button'><span className="material-symbols-outlined">
               keyboard_double_arrow_left
             </span>Previous</button>
             <button onClick={onNext} className='next-button'>Next<span className="material-symbols-outlined">
               keyboard_double_arrow_right
             </span></button>
           </div>
         </div>
       </div>
     );
   }

export default ProfessionalExperience