import React from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import MainPage from './MainPage'
import CandidatePopUP from './CandidatesPopUP/CandidatePopUP'
import Test from '../Test'

const CandidateMain = () => {

  return (
    <>
      {/* <div className='container' >
        <Sidebar />
        <div className='HeaderMainPage'>
          <Header />
          <div className='main'> */}

            <MainPage />
         

            {/* </div>
        </div>
      </div> */}
    </>
  )
}

export default CandidateMain