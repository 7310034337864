import React from 'react'

const SupportPage = () => {
  return (
    <div>
        <h1>
            Please contact our customer support for your subdomain
        </h1>
    </div>
  )
}

export default SupportPage