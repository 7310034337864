import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import './homePage.css'
import { UserData } from './UserData.js';
import { Bar, Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { getAllCandidates } from '../../action.js/candidateAction.js';
import { getAllSubmissions } from '../../action.js/submissionAction.js';
const HomePage = () => {

 
  useEffect(()=>{
    console.log("homepage")
  })

  

  const candidateListSate = useSelector((state) => state.candidateList);
  const { candidateList, loading, error } = candidateListSate;
  const [totalRow, setTotalRow] = useState(0);  // const [candidateData, setCandidateData] = useState([])
  const [totalInterview,setTotalInterview] = useState(0)
  const [newCandidates, setNewCandidates] = useState(0);
  const [placedCandidates,setPlacedCandidates] = useState(0);
  const [onBench , setOnBench] = useState(0)

  const candidateListDispatch = useDispatch();
  
  


  useEffect(() => {
    candidateListDispatch(getAllCandidates());
    
  }, []);
 
  useEffect(()=>{
    let newCandidatesList = candidateList?.filter(candidate => candidate.jobStatus === 'New Candidates');
    console.log("new candidates ",newCandidatesList)
    setNewCandidates(newCandidatesList)

    let placedCandidatesList = candidateList?.filter(candidate => candidate.jobStatus === 'Placed Candidates');
    console.log("new candidates ",placedCandidatesList)
    setPlacedCandidates(placedCandidatesList)

    let onBenchList = candidateList?.filter(candidate => candidate.jobStatus === 'On Bench');
    console.log("new candidates ",onBenchList)
    setOnBench(onBenchList)

    
  },[candidateList])
  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
  
    if (parts.length >= 2) {
      const subdomain = parts[0];
      const mainDomain = parts[1];
      if (mainDomain === 'localhost') {
        return subdomain;
      }
    }
  
    return null;
  };


  const getAllSubmissionDispatch = useDispatch()
  const getAllSubmissionSelector = useSelector((state) => state.getAllSubmission)
  const { submissionList, loading: employeeLoading, error: employeeError } = getAllSubmissionSelector
  useEffect(() => {
    console.log("hit firsttime")
    getAllSubmissionDispatch(getAllSubmissions())
    console.log("employeesInfo................", submissionList)
//     let count = 0;

//     submissionList?.forEach((candidates) => {
//       count += candidates?.submission.length;
//     });
    

//     setTotalRow(count);
// // Since fetching data is asynchronous, the submissionList might not be populated immediately
  // To ensure it's populated before calculating the total count, you can use a conditional statement
  
   
  }, [])

  useEffect(()=>{
    
      // Count of submissions with interviews scheduled
      let countInterview = 0;
      let count = 0;
  
      submissionList?.forEach((candidates) => {
        count += candidates?.submission.length;
      });
      
      // Iterate through submissionList and check if interview is available and not null
      submissionList?.forEach(candidate => {
        candidate?.submission?.forEach(submission => {
          if (submission?.interview && submission?.interview !== null) {
            ++countInterview;
          }
        });
      });
  
      console.log("countInterview++++",countInterview,"++++",totalInterview)
      // Set the total count as state
      setTotalInterview(countInterview);

      console.log("countInterview++++",countInterview,"++++",totalInterview)
      setTotalRow(count);
    // }
  },[submissionList])

  ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend);

  const years = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


  const data = {
    labels: years.map(o => o),
    datasets: [
      {
        label: 'Scheduled Interview',
        backgroundColor: 'rgb(45,106,172)',
        borderColor: 'rgba(204, 204, 204, 1)',
        borderWidth: 1,
        data: UserData.map(o => o.userGain)
        // data: UserData.map(o => o.userGain)
      },
      {
        label: 'Placed Candidates',
        backgroundColor: ' rgb(0,123,255)',
        borderColor: 'rgba(204, 204, 204, 1)',
        borderWidth: 1,
        data: UserData.map(o => o.userLost)
      }
    ]
  }



  const pieData = {
    labels: ['Placed Candidates', 'Total Candidates', 'Submission','On Bench'],
    datasets: [
      {
        label: '# of Votes',
        data: [placedCandidates, candidateList?.length, totalRow,  onBench],
        backgroundColor: [
          ' #08a21c',
          '#1c088d',
          '#e6ef12',
          '#12efd2',
          '#7912ef',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          '#08a21c',
          '#1c088d',
          '#e6ef12',
          '#12efd2',
          '#7912ef',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',

      },
    },
  };
  return (
   
        
    <div className="containerD" style={{ gap: "50px",  }}>
      <div className="card-container">
        <div className="card  flex-row-jAround-aCenter"  style={{borderBottom:"5px solid #00D9C0"}}>
          <div>
          <p style={{color:"#00D9C0"}}>{candidateList?.length || 0}</p>
          <span>Total Candidates</span>
          </div>
          <div  style={{background:"#E9FFEF", borderRadius:"20px"}}>
            <span class="material-symbols-outlined" style={{fontSize:"40px", color:"#00D9C0", padding:"10px 15px"}}>
              groups
            </span>
            
          </div>
          {/* <p>Content for Card 1</p> */}

        </div>
        <div className="card flex-row-jAround-aCenter" style={{borderBottom:"5px solid #3F9AE0"}}>
          <div className=''>
          <p style={{color:"#3F9AE0"}}>{totalInterview || 0}</p>
          <span style={{whiteSpace:"nowrap"}}>Scheduled Interview </span>
          </div>
          <div style={{background:"#E0F2FF", borderRadius:"20px"}}>
            <span class="material-symbols-outlined" style={{fontSize:"40px", color:"#3F9AE0", padding:"10px 15px"}}>
              badge
            </span>
            
          </div>
          {/* <p>Content for Card 1</p> */}

        </div>
        <div className="card flex-row-jAround-aCenter" style={{borderBottom:"5px solid #FF9B52"}}>
          <div>
        <p style={{color:"#FF9B52"}}>{totalRow}</p>
          <span>Total Submission</span>
          </div>
          <div style={{background:"#FFF0EB", borderRadius:"20px"}}>
            <span class="material-symbols-outlined" style={{fontSize:"40px", color:"#FF9B52", padding:"10px 15px"}}>
              notification_multiple
            </span>
           
          </div>
          {/* <p>Content for Card 1</p> */}

        </div>

        <div className="card flex-row-jAround-aCenter" style={{borderBottom:"5px solid #D80032"}}>
          <div>
        <p style={{color:"#D80032"}}>{newCandidates?.length || 0}</p>
          <span>New Candidates</span>
          </div>
          <div style={{background:"#FFF0EB", borderRadius:"20px"}}>
            <span class="material-symbols-outlined" style={{fontSize:"40px", color:"#D80032", padding:"10px 15px"}}>
              fiber_new
            </span>
           
          </div>
          {/* <p>Content for Card 1</p> */}

        </div>
      </div>

      <div className='flex-row-jBetween-aCenter' style={{ gap: "50px", flexDirection: "row-reverse" }}>
        <div className='pie' >
          {/* <p>Sales Order</p> */}
          <Doughnut data={pieData} options={options} />
        </div>
        <div className='bar'>
          <Bar width={700} height={300} data={data} />
        </div>
      </div>
      {/* <div className="data-chart">
        <h3>Data Chart</h3>
        <canvas ref={chartRef}></canvas>
      </div> */}
    </div>

  );
};

export default HomePage;
