import React, { useState } from 'react';

const ResumeParserApi = () => {
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('http://localhost:5000/parse_resume', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      setParsedData(data);
    } catch (error) {
      console.error('Error parsing resume:', error);
    }
  };

  return (
    <div>
      <input type="file" accept="pdf" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload and Parse</button>

      {parsedData && (
        <div>
          <h2>Parsed Data:</h2>
          <pre>{JSON.stringify(parsedData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ResumeParserApi;
