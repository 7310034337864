import {
 
    EMPLOYEE_CREATE_PERMISSION_FAIL,
    EMPLOYEE_CREATE_PERMISSION_REQUEST,
    EMPLOYEE_CREATE_PERMISSION_SUCCESS,
  
    EMPLOYEE_GETONE_PERMISSION_FAIL,
  EMPLOYEE_GETONE_PERMISSION_REQUEST,
  EMPLOYEE_GETONE_PERMISSION_SUCCESS,
  } from '../constants/constants';


  export const createEmployeePermisionReducers = (state = {}, action) => {
    switch (action.type) {
      case EMPLOYEE_CREATE_PERMISSION_REQUEST:
        return { loading: true };
      case EMPLOYEE_CREATE_PERMISSION_SUCCESS:
        return { loading: false, employeeInfo: action.payload };
      case EMPLOYEE_CREATE_PERMISSION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const getOneEmployeePermisionReducers = (state = {}, action) => {
    switch (action.type) {
      case EMPLOYEE_GETONE_PERMISSION_REQUEST:
        return { loading: true };
      case EMPLOYEE_GETONE_PERMISSION_SUCCESS:
        return { loading: false, oneEmployeeInfo: action.payload };
      case EMPLOYEE_GETONE_PERMISSION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };