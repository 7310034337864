import {
  EMPLOYEE_SIGNIN_FAIL,
  EMPLOYEE_SIGNIN_REQUEST,
  EMPLOYEE_SIGNIN_SUCCESS,
  EMPLOYEE_SIGNOUT,

  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,

  EMPLOYEE_DELETE_FAIL,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,

  EMPLOYEE_GET_ALL_REQUEST,
  EMPLOYEE_GET_ALL_SUCCESS,
  EMPLOYEE_GET_ALL_FAIL,

  EMPLOYEE_GET_BY_ID_REQUEST,
  EMPLOYEE_GET_BY_ID_SUCCESS,
  EMPLOYEE_GET_BY_ID_FAIL,

  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAIL

} from '../constants/constants';


export const employeeSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_SIGNIN_REQUEST:
      return { loading: true };
    case EMPLOYEE_SIGNIN_SUCCESS:
      return { loading: false, employeeInfoData: action.payload };
    case EMPLOYEE_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_SIGNOUT:
      return {};
    default:
      return state;
  }
};


export const createEmployeeReducers = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CREATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_CREATE_SUCCESS:
      return { loading: false, employeeInfo: action.payload };
    case EMPLOYEE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const getAllEmployeeReducers = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_GET_ALL_REQUEST:
      return { loading: true };
    case EMPLOYEE_GET_ALL_SUCCESS:
      return { loading: false, employeesInfo: action.payload };
    case EMPLOYEE_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const getEmployeeByIdReducers = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_GET_BY_ID_REQUEST:
      return { loading: true };
    case EMPLOYEE_GET_BY_ID_SUCCESS:
      return { loading: false, employeeInfo: action.payload };
    case EMPLOYEE_GET_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteEmployeeByIdReducers = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_DELETE_REQUEST:
      return { loading: true };
    case EMPLOYEE_DELETE_SUCCESS:
      return { loading: false, employeeInfo: action.payload };
    case EMPLOYEE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCurrentUserReducers = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_USER_REQUEST:
      return { loading: true };
    case CURRENT_USER_SUCCESS:
      return { loading: false, currentUserInfo: action.payload };
    case CURRENT_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

