import Axios from 'axios';
import {

  SUBMISSION_CREATE_REQUEST,
  SUBMISSION_CREATE_SUCCESS,
  SUBMISSION_CREATE_FAIL,
  SUBMISSION_UPDATE_REQUEST,
  SUBMISSION_UPDATE_SUCCESS,
  SUBMISSION_UPDATE_FAIL,
  SUBIMISSION_GET_ALL_REQUEST,
  SUBIMISSION_GET_ALL_SUCCESS,
  SUBIMISSION_GET_ALL_FAIL,
  SUBIMISSION_GET_ID_REQUEST,
  SUBIMISSION_GET_ID_SUCCESS,
  SUBIMISSION_GET_ID_FAIL,
  SUBMISSION_DELETE_REQUEST,
  SUBMISSION_DELETE_SUCCESS,
  SUBMISSION_DELETE_FAIL
} from '../constants/constants';
import { unstable_HistoryRouter, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const { REACT_APP_BASE_URL } = process.env



export const createSubmission = (formData) => async (dispatch, getState) => {
    const {
      employeeSignin: { employeeInfoData },
    } = getState();



    dispatch({ type: SUBMISSION_CREATE_REQUEST });
    try {

        const convertedData = {
            "candidateId": formData.candidateId,
            "submission": [
                {
                    "dateOfSubmission": formData.dateOfSubbmision + "T00:00:00.000Z", // Adjust the time if needed
                    "position": formData.position,
                    "status": formData.status,
                    "jobSourceType": formData.jobSourceType,
                    "jobSourceName": formData.jobSourceName,
                    "endClient": formData.endClient,
                    "jobDescription": formData.jobDescription,
                    "comments": formData.comments,
                    "city": formData.city,
                    "state": formData.state,
                    "country": formData.country
                }
            ]
        };



      const { data } = await Axios.post(`${REACT_APP_BASE_URL}/api/submissionInterview/createSubmission`, convertedData,
        {
          headers: { Authorization: `Bearer ${employeeInfoData.token}` }
        }
      );
      dispatch({ type:  SUBMISSION_CREATE_SUCCESS, payload: data });
      dispatch(getAllSubmissions())
      alert("Create Submission Successfully")
      // useNavigate('/submissionPage')
    } catch (error) {
      dispatch({
        type:  SUBMISSION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      // alert(" Submission not created Successfully")
    }
  };

  
export const updateSubmissionAction = (formData,candidateId,onClose) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();



  dispatch({ type: SUBMISSION_UPDATE_REQUEST });
  try {

      const convertedData = {
          "candidateId": candidateId,
          "submission":formData
      };


    const { data } = await Axios.post(`${REACT_APP_BASE_URL}/api/submissionInterview/updateSubmission`, convertedData,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type:  SUBMISSION_UPDATE_SUCCESS, payload: data });
    alert("Update Submission Successfully")
    dispatch(getAllSubmissions())
    onClose()
  } catch (error) {
    dispatch({
      type:  SUBMISSION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    alert(" Submission not created Successfully")
  }
};
  
export const getAllSubmissions = () => async (dispatch,getState) => {
    const {
      employeeSignin: { employeeInfoData },
    } = getState();
    dispatch({ type: SUBIMISSION_GET_ALL_REQUEST});
    try {
        const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/submissionInterview/getAll/Submission`,
        {
          headers :{ Authorization: `Bearer ${employeeInfoData.token}`}
        }
        );
        dispatch({ type: SUBIMISSION_GET_ALL_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SUBIMISSION_GET_ALL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
  };


    
export const getSubmissionsById = (Id) => async (dispatch,getState) => {
    const {
      employeeSignin: { employeeInfoData },
    } = getState();
    dispatch({ type: SUBIMISSION_GET_ID_REQUEST});
    try {
        const { data } = await Axios.get(`${REACT_APP_BASE_URL}/api/submissionInterview/${Id}`,
        {
          headers :{ Authorization: `Bearer ${employeeInfoData.token}`}
        }
        );
        dispatch({ type: SUBIMISSION_GET_ID_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SUBIMISSION_GET_ID_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
  };

    
export const deleteSpecificSubmissionFromSubmissionArray = (formData,submissionId) => async (dispatch, getState) => {
  const {
    employeeSignin: { employeeInfoData },
  } = getState();
  

  
  dispatch({ type: SUBMISSION_DELETE_REQUEST });
  try {
  console.log("submissionId",submissionId)
    const { data } = await Axios.put(`${REACT_APP_BASE_URL}/api/submissionInterview/submissionDelete/${submissionId}`, formData,
      {
        headers: { Authorization: `Bearer ${employeeInfoData.token}` }
      }
    );
    dispatch({ type:  SUBMISSION_DELETE_SUCCESS, payload: data });
    alert("Submission Updated Successfully")
      dispatch(getAllSubmissions())
    // useNavigate('/interviewPage')
  } catch (error) {
    dispatch({
      type:  SUBMISSION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};